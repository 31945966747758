<div class="nav-bar-class bg-white position-fixed w-100 top-0 left-0" >
    <div class="row flex-nowrap d-flex justify-content-between flex-row  pr-2" [ngStyle]="{'padding-left': (collapse) ? '100px' : '238px'}" style="transition: padding-left 1s ease-in-out;">
        <div  class="col-4 p-2">
            <div class="row vous-etes-dans text-nowrap" >
                <span>{{"navbar.label.path" | translate}}</span>
            </div>
            <div class="row mt-1 text-left text-nowrap Tableau_de_Bord">
                <fa-icon class="mr-1 colorIcone " [icon]="fa[currentIcon]" ></fa-icon>
                <span>{{position}}</span>
            </div>
        </div>
        <div  class="col-4 p-2">
          <div class="row" *ngIf="prepay">
              <p class="currentSold mt-2">

                  {{"navbar.label.solde" | translate}} {{expandNameEnp(user?.user?.nom_organisme)}}&nbsp; <span style="font-weight: bold;">
                  {{solde}}</span>&nbsp;
                  <span>
                  <fa-icon class="fa-1x" [icon]="fa['faEuroSign']"></fa-icon>
                </span>&nbsp;
                  <fa-icon data-target="#paymenModal" data-toggle="modal"
                    [icon]="fa['faPlusCircle']" class="float-right" style="font-size: medium;"></fa-icon>

              </p>
          </div>
        </div>
        <div class="col-4 p-2">
            <div class="row mt-1">
                <div class="col-2 mt-1 pr-0 h-100" >
                    <div id="dropdownMenuNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="mt-1 float-right icon">
                        <div class="icon-badge text-center">
                            {{notification?.length ? notification?.length : 0 }}
                        </div>
                        <fa-icon [icon]="fa['faBell']"></fa-icon>
                    </div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuNotification">
                        <p class="p-notif" *ngIf="notification?.length<1">Pas de Notifications</p>
                        <a class="dropdown-item notification-item" *ngFor="let notif of notification | slice:0:5" style="width: 370px">
                            <div class="row">
                                <div class="col-8">
                                    <p style="font-size: 13px">{{notif.date_notification}}</p>
                                </div>
                                <div class="col-4">
                                    <p style="font-size: 13px">{{expandNameNofification(notif.heure_notification)}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="d-inline-block " style="width: 50px;">
                                        <img style="width: 40px;margin-right: 10px;position: absolute; top: 5px" src="../../../assets//img//ID53506340_618147591965833_474.png">
                                    </div>
                                    <div class="d-inline-block " style="width: 254px;margin-right: 10px">
                                        <p style="font-weight: bold; margin-bottom: 3px">{{expandNameNofification(notif.titre)}}</p>
                                        <p style="font-size: 13px">{{expandNameNofification(notif.msg)}}</p>
                                    </div>
                                    <div class="d-inline-block " style="width: 50px;">
                                        <fa-icon style="color: #eaebec; font-size: 14px; position: absolute; top: 13px;" [icon]="fa['faBell']">
                                        </fa-icon>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-2 mt-1 d-flex justify-content-end pr-0 h-100" >
                    <div class="icon mt-1" id="dropdownMailNotification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div *ngIf="configue" class="icon-badge text-center">
                            {{mesg.length}}
                        </div>
                        <div *ngIf="!configue" class="icon-badgeError position-relative text-center">
                            <fa-icon class="fa" [icon]="fa['faTimesCircle']"></fa-icon>
                        </div>
                        <fa-icon [icon]="fa['faEnvelope']"></fa-icon>
                    </div>
                    <div id="dropdownMailNotification1" class="dropdown-menu drop-div" aria-labelledby="dropdownMailNotification">
                        <p class="p-notif-rcpt">Boite de Réception</p>
                        <div class="ml-2">
                            <div class="row">
                                <p (click)='readen(false)' class="p-notif-false">Tout</p>
                                <p (click)='readen(true)' class="p-notif-true">Non lu</p>
                          </div>
                        </div>
                        <ng-container *ngIf="lu">
                            <a class="dropdown-item notification-item" *ngFor="let notif of mesg">
                                <div class="row">
                                    <div class="col-12" (click)="redirectioWeB(notif.id)">
                                        <div class="d-inline-block position-relative Pas-mess" >
                                            <div class="avatar"  *ngIf="notif?.from_[0].name === null || notif?.from_[0].name === ''">
                                                {{notif.from_[0]?.email[0]}}
                                            </div>
                                            <div class="avatar"  *ngIf="notif?.from_[0].name">
                                                {{notif.from_[0]?.email[0]}}
                                            </div>
                                        </div>
                                        <div class="d-inline-block position-relative col-12 mr-1 notif-p" >
                                            <p *ngIf="notif.from_[0].name === null || notif.from_[0].name === ''" >
                                                {{expandName(notif.from_[0].name)}}</p>
                                            <p *ngIf="notif.from_[0].name" >
                                                {{expandName(notif.from_[0].email)}}</p>
                                            <p style="font-family: 'Nunito Sans'; color: #418eff;" *ngIf='notif.subject !== null'>{{expandName(notif.subject)}}</p>
                                            <p  *ngIf='notif.subject === null'>Aucun sujet</p>

                                            <p style="color: #418eff; font-size: 10px; position: absolute; top: 2px ;margin-left:160px;">
                                                {{notif.time}}</p>
                                            <fa-icon style="color: #418eff; font-size: 14px; position: absolute; top: 18px ;margin-left:180px;" [icon]="fa['faBell']">
                                            </fa-icon>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item notification-item" *ngIf="mesg.length==0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block position-relative Pas-mess" >
                                            <span> Pas de message </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="!lu">
                            <a class="dropdown-item notification-item" *ngFor="let notif2 of AllMesg">
                                <div class="row">
                                    <div class="col-12" (click)="redirectioWeB(notif2.id)">
                                        <div class="d-inline-block position-relative Pas-mess" >
                                            <div class="avatar"  *ngIf="notif2?.from_[0]?.name === '' || notif2?.from_[0]?.name=== null">
                                                {{notif2.from_[0]?.email[0]}}
                                            </div>
                                            <div class="avatar "  *ngIf="notif2?.from_[0]?.name !== ''">
                                                {{notif2.from_[0]?.name[0]}}
                                            </div>
                                        </div>
                                        <div class="d-inline-block position-relative col-12 mr-1" >
                                            <p [ngClass]="{'read': !notif2.read, 'notread' :notif2.read }" *ngIf='notif2?.from_[0]?.email!== null || notif2?.from_[0]?.email !== ""'>{{expandName(notif2?.from_[0]?.email)}}</p>
                                            <p style="font-size: 13px;font-family: 'Nunito Sans'; color: #418eff;" *ngIf='notif2?.subject !== null || notif2?.subject !== ""'>{{expandName(notif2.subject)}}</p>
                                            <p style="font-size: 13px" *ngIf='notif2.subject=== null'>Aucun sujet</p>

                                            <p style="color: #418eff; font-size: 10px; position: absolute; top: 2px ;margin-left:160px;">
                                                {{notif2.time}}</p>
                                            <fa-icon *ngIf="!notif2.read" style="color: #418eff; font-size: 14px; position: absolute; top: 18px ;margin-left:180px;" [icon]="fa['faBell']">
                                            </fa-icon>
                                        </div>
                                    </div>
                                </div>

                            </a>
                            <a class="dropdown-item notification-item" *ngIf="AllMesg?.length==0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block position-relative Pas-mess" >
                                            <span> Pas de message </span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <button *ngIf="AllMesg?.length !==0" class="btn_show">Voir Tout</button>
                        </ng-container>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row flex-nowrap d-flex justify-content-center" >
                        <div class="d-flex  flex-row user-info-style p-1 mw-50" [routerLink]="'/profil'">
                            <div class="pl-0 mt-0 pr-1">
                                <img *ngIf="user?.user?.photo ===null ||  user?.user?.photo ==='' " src="../../../assets/img/user.png" class="user-photo">
                                <img *ngIf="user?.user?.photo !== null &&  user?.user?.photo !== '' " src="{{user?.user?.photo}}" class="user-photo">
                            </div>
                            <div class="pl-2 user-info d-inline-block text-left pt-1 text-nowrap" id="ifoname" >
                                <p class="user-nom">{{ expandNameUser(user.user.nom , user.user.prenom )}} 
                                </p>
                                <p class="user-role">{{expandName(user?.user?.nom_role)}}</p>
                            </div>
                        </div>
                        <div class="col  user-info d-inline-block pt-2 mr-1 profile-slide-btn mt-2 float-left pl-3 " id="dropdownMenuSetting" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <fa-icon [icon]="fa['faAngleDoubleDown']"></fa-icon>
                        </div>
                        <div class="dropdown-menu  dropdown-menu-right" aria-labelledby="dropdownMenuSetting" style="width:180px; margin-top:-20px; border-radius: 10px; ">
                            <div class="col-7 user-info d-inline-block text-center pt-1 text-nowrap">
                                <p class="user-nom">{{user?.user?.nom}} {{user?.user?.prenom}}</p>
                                <p class="user-role">{{user?.user?.nom_role}}</p>
                            </div>
                            <hr>
                            <a class="dropdown-item notification-item1" [routerLink]="'/profil'">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block position-relative" style="width: 50px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                              <g id="Groupe_3056" data-name="Groupe 3056" transform="translate(-1354.904 -290.701)">
                                                <circle id="Ellipse_110" data-name="Ellipse 110" cx="14" cy="14" r="14"
                                                  transform="translate(1354.904 290.701)" fill="#e4e6eb" />
                                                <g id="Groupe_3052" data-name="Groupe 3052" transform="translate(1362.108 293.042)">
                                                  <g id="Groupe_3055" data-name="Groupe 3055">
                                                    <path id="Tracé_1002" data-name="Tracé 1002"
                                                      d="M9.8,7.9a3.876,3.876,0,0,0,3.8-3.951A3.876,3.876,0,0,0,9.8,0,3.876,3.876,0,0,0,6,3.951,3.876,3.876,0,0,0,9.8,7.9Zm0-6.585a2.584,2.584,0,0,1,2.531,2.634A2.584,2.584,0,0,1,9.8,6.585,2.584,2.584,0,0,1,7.265,3.951,2.584,2.584,0,0,1,9.8,1.317Z"
                                                      transform="translate(-3 2.098)" fill="#959595" />
                                                    <g id="Groupe_3054" data-name="Groupe 3054">
                                                      <path id="Tracé_1003" data-name="Tracé 1003"
                                                        d="M9.8,14C6.044,14.006,3,18.032,3,23a.9.9,0,0,0,.755,1,.9.9,0,0,0,.755-1c0-3.866,2.367-7,5.286-7s5.286,3.134,5.286,7a.9.9,0,0,0,.755,1,.9.9,0,0,0,.755-1C16.588,18.032,13.548,14.006,9.8,14Z"
                                                        transform="translate(-3 -3.318)" fill="#959595" />
                                                    </g>
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>


                                        </div>
                                        <div class="d-inline-block position-relative profil-p" >
                                            <p >Profil</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item notification-item1" (click)="logOut()">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block position-relative" style="width: 50px;">

                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                                              <g id="Groupe_3057" data-name="Groupe 3057" transform="translate(-1350 -349)">
                                                <circle id="Ellipse_111" data-name="Ellipse 111" cx="14" cy="14" r="14"
                                                  transform="translate(1350 349)" fill="#e4e6eb" />
                                                <path id="Tracé_975" data-name="Tracé 975"
                                                  d="M632,511.211a.775.775,0,0,0,.716.821h5.432l-1.647,1.88a.918.918,0,0,0,0,1.168.658.658,0,0,0,1.018,0l2.863-3.285a.928.928,0,0,0,.151-.273.947.947,0,0,0,0-.622.927.927,0,0,0-.151-.273l-2.863-3.285a.658.658,0,0,0-1.018,0,.917.917,0,0,0,0,1.168l1.647,1.88h-5.432a.775.775,0,0,0-.716.821ZM641.3,503h-7.156A2.322,2.322,0,0,0,632,505.463v2.464a.722.722,0,1,0,1.431,0v-2.464a.775.775,0,0,1,.716-.821H641.3a.775.775,0,0,1,.716.821v11.5a.775.775,0,0,1-.716.821h-7.156a.775.775,0,0,1-.716-.821V514.5a.722.722,0,1,0-1.431,0v2.464a2.323,2.323,0,0,0,2.147,2.464H641.3a2.323,2.323,0,0,0,2.147-2.464v-11.5A2.323,2.323,0,0,0,641.3,503Zm0,0"
                                                  transform="translate(726.275 -147.423)" fill="#6d6d6e" stroke="#e4e6eb"
                                                  stroke-width="0.5" />
                                              </g>
                                            </svg>



                                        </div>
                                        <div class="d-inline-block position-relative profil-p">
                                            <p style="font-size: 14px; font-weight: bold; margin-bottom: 3px">Déconnexion</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a class="dropdown-item notification-item1" [routerLink]="'/historique-transactions'">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-inline-block position-relative" style="width: 50px;">

                                            <img src="/assets/icons/transaction-icon.svg">



                                        </div>
                                        <div class="d-inline-block position-relative" style="width: 100px;">
                                            <p style="font-size: 14px; font-weight: bold; margin-bottom: 3px">Transactions</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="ml-1 text-right mw-25">
                            <mat-form-field class="pt-0" style="width: 40px;">
                                <mat-select (valueChange)="setLanguage($event)" [(ngModel)]="selected_lang">
                                    <mat-option [value]="lan.toLowerCase()" *ngFor="let lan of languages">{{ lan }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Payment Modal -->
<div class="modal fade" id="paymenModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">{{"navbar.label.payment.modal.title" | translate}}
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            </div>
            <div class="modal-body">
                <div class="">
                    <ng-container *ngTemplateOutlet="payment_facture_url ? payment_frame : create_facture ">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #payment_frame>
    <div class="frame-payement">
        <iframe [src]="payment_facture_url" width="100%" height="100%"></iframe>
    </div>
    <div class="row" style="margin-top: 25px">
        <div class="col text-right">
            <button class="btn btn-danger p-0" (click)="clearPaymentFrame()">{{"navbar.label.payment.modal.button.reset" |
          translate}}</button>
        </div>
    </div>
</ng-template>

<ng-template #create_facture>
    <div class="row">
        <div class="col text-center" style="margin-bottom: 105px">
            <h1 class="deposit-label">{{"navbar.label.payment.modal.body.deposit" | translate}}</h1>
            <p class="deposit-value">€ {{deposit_value}}</p>
            <p class="deposit-text">{{"navbar.label.payment.modal.body.text" | translate}}</p>

        </div>
    </div>

    <div class="row">
        <div class="col-4 text-center p-2" *ngFor="let bundle of bundleList">
            <button type="button" class="btn btn-secondary price-btn p-0" (click)="setDepositValue(bundle)">
          {{bundle}} €
        </button>
        </div>
    </div>
    <div class="row text-center">
        <div class="col-12">
            <button type="button" class="btn btn-success other-bundle-btn p-0" (click)="other_bundle=!other_bundle">
          {{"navbar.label.payment.modal.button.other" | translate}}
        </button>
        </div>
        <div class="col-12" *ngIf="other_bundle">
            <label for="other_bundle_number" style="margin-right: 35px">{{"navbar.label.payment.modal.montant" |
          translate}}</label>
            <input class="form-control" id="other_bundle_number" type="number" [(ngModel)]="deposit_value">
        </div>
    </div>
    <div class="row" style="margin-top: 25px">
        <div class="col text-right">
            <button type="button" class="btn btn-danger p-0" [disabled]="!(deposit_value>0)" (click)="createFacture()">
          {{"navbar.label.payment.modal.button.proceed_to_payment" | translate}}
        </button>
        </div>
    </div>
</ng-template>
