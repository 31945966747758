<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
          <fa-icon [icon]="faFilter"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="btn-dt-ajouter mb-2 w-100">
        <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp;
        Filtrer la recherche
      </button>
    </div>
    <!-- <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
      <div class="close-element">
        <fa-icon [icon]="faPlus"></fa-icon>
      </div>
    </div> -->
    <div class="ml-1">

      <div class="row">
        <div class="col-6 align-self-center">
          <div class="row">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
              <fa-icon [icon]="fa['faRedoAlt']"></fa-icon>
            </button>
             <app-list-filter-saved [entity]="'O'" (filterOpportunite)="getFilterFromCollection($event)" (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
            <!-- <div class="FILTRES__RECHERCHES" >
              <fa-icon class="fa-2x mr-2" (click)="toggleFilterCorp()" [icon]="faSearch"></fa-icon>
                <span>{{'opportunities.label.morefilters' | translate}}</span>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filterOpportunite" [entity]="'O'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save" (click)="submitFilter()">
              <fa-icon class="fa-lg" [icon]="fa['faSearch']"></fa-icon>&nbsp;
              {{'opportunities.label.startasearch' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>


      <div class="scrolling" id="style-1">
        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
                        <fa-icon class="fa-lg mr-2" [icon]="fa['faBullhorn']"></fa-icon>
                        <span> Opportunités</span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-opportunites #FiltresOpportunitesComponent class="w-100" (returnToparent)="getResetOpp($event)" [Reset]="onResetOpp"
                    (onPushFilterOpportunite)="EmitFilterOpportunite($event)"
                    [onRefreshFilterOpportunite]="filterOpportunite.filtre_opp">
                  </app-filtres-opportunites>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
                        <fa-icon class="fa-lg mr-2" [icon]="fa['faUserTie']"></fa-icon>
                        <span> Prospects</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-prospects #FiltresProspectsComponent class="w-100" (returnToparent)="getResetPros($event)" [Reset]="onResetPros"
                    (onPushFilterProspec)="EmitFilterProspec($event)"
                    [onRefreshFilterProspec]="filterOpportunite.prospect"></app-filtres-prospects>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
                        <fa-icon class="fa-lg mr-2" [icon]="fa['faUser']"></fa-icon>
                        <span>Utilisateurs</span>
                      </div>

                    </div>


                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-utilisateurs #FiltresUtilisateursComponent class="w-100" (returnToparent)="getResetUtil($event)" [Reset]="onResetUtil"
                    (onPushFilterUtilisateur)="EmitFilterUtilisateur($event)">
                  </app-filtres-utilisateurs>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>


        <!-- <div class="row mb-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                            <fa-icon class="fa-lg mr-2" [icon]="fa['faFileContract']"></fa-icon>
                            <span>Ancien Contrat</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filtres-info-ancien-contrat class="w-100" (returnToparent)="getResetAncienContrat($event)"
                    [Reset]="onResetAncienContrat" (onPushFilterAncienContart)="EmitFilterAncienContart($event)">
                  </app-filtres-info-ancien-contrat>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div> -->


        <!-- <div class="row mb-2" style="display:none ;">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                            <fa-icon class="fa-lg mr-2" [icon]="fa['faPhone']"></fa-icon>
                            <span>Téléphonie</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filtres-telephonie class="w-100" (returnToparent)="getResetTelephonie($event)"
                    [Reset]="onResetTelephonie" (onPushFilterTelephonie)="EmitFilterTelephonie($event)">
                  </app-filtres-telephonie>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div> -->


        <div class="row mb-md-2">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center">
                      <div class="row filtre-spn">
                        <fa-icon class="fa-lg mr-2" [icon]="fa['faLightbulb']"></fa-icon>
                        <span>Statut Actuel</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filtres-status-actuel #FiltresStatusActuelComponent (returnToparent)="getResetStatut($event)" [Reset]="onResetStatut"
                    (onPushFilterStatusActuel)="EmitFilterStatusActuel($event)">
                  </app-filtres-status-actuel>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>


        <!-- <div class="row mb-2" style="display:none ;">
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                            <fa-icon class="fa-lg mr-2" [icon]="fa['faUserCheck']"></fa-icon>
                            <span>Actions Utilisateurs</span>
                      </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filtres-actions-utilisateurs class="w-100" (returnToparent)="getResetActionsUtil($event)"
                    [Reset]="onResetActionsUtil"
                    (onPushFilterActionsUtilisateurs)="EmitFilterActionsUtilisateurs($event)">
                  </app-filtres-actions-utilisateurs>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div> -->

      </div>

    </div>


  </div>
</form>
