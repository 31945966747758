<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
          <fa-icon [icon]="faFilter"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="btn-dt-ajouter mb-2 w-100">
        <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp;
        Filtrer la recherche
      </button>
    </div>
    <!-- <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
      <div class="close-element">
        <fa-icon [icon]="faPlus"></fa-icon>
      </div>
    </div> -->
    <div class="ml-1">

      <div class="row">
        <div class="col-6 align-self-center">
          <div class="row">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
              <fa-icon [icon]="fa['faRedoAlt']"></fa-icon>
            </button>
             <app-list-filter-saved [entity]="'E'" (filterOpportunite)="getFilterFromCollection($event)" (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
            <!-- <div class="FILTRES__RECHERCHES" >
              <fa-icon class="fa-2x mr-2" (click)="toggleFilterCorp()" [icon]="faSearch"></fa-icon>
                <span>{{'opportunities.label.morefilters' | translate}}</span>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="Allfilter" [entity]="'E'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save" (click)="submitFilter()">
              <fa-icon class="fa-lg" [icon]="fa['faSearch']"></fa-icon>&nbsp;
              {{'opportunities.label.startasearch' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>
        <div class="scrolling"  id="style-1" >
          <div class="row mb-1"  >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr"></div>
                        <div class="col-4 pl">
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg mr-2" [icon]="fa['faBriefcase']" width="28px"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Affaire</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>

                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="mt-2" >
                 <app-filtre-affaires (returnToparent)="getResetAffaire($event)" (onPushFilterAffaire)="EmitFilterAffaire($event)" [resetAffaire]="resetAffaire"></app-filtre-affaires>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

          <div class="row mb-1 w-100" >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr" ></div>
                        <div class="col-4 pl" >
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg mr-2" [icon]="fa['faMoneyCheckAlt']" width="28px"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Echéance</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class=" mt-2 w-100">
                 <app-filter-echeance-echeance (returnToparent)="getResetEcheance($event)" [resetEcheance]="resetEcheance" (onPushFilterEcheance)="EmitFilterEcheance($event)"></app-filter-echeance-echeance>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>

          <div class="row mb-1" >
            <div class="col-12">
              <mat-accordion>
                <mat-expansion-panel hideToggle>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="row w-100" >
                        <div class="col-5 pr" ></div>
                        <div class="col-4 pl" >
                          <div class="Group_2264">
                            <span class="Rectangle_110">
                              <fa-icon class="fa-lg" [icon]="fa['faMoneyCheck']"></fa-icon>

                            </span>
                            <div class="Opportunits">
                              <span>Echéancier</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-3"></div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class=" mt-2 w-100" >

                    <app-filtre-echeancier (returnToparent)="getResetEcheancier($event)" [resetEcheancier]="resetEcheancier"  (onPushFilterEcheancier)="EmitFilterEcheancier($event)"></app-filtre-echeancier>
                 </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>


        </div>

      </div>
    </div>
  </form>
