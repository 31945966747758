<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <!-- routerLink="fiche/Nouveau"  -->
    <button type="button" class="btn-dt-add my-4 ml-4" data-title="Ajouter une garantie" (click)=" goTofiche()">
      <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon>
    </button>
  </div>
  <div class="p-3 mb-3">
    <table id="Table" class="table" style="table-layout: fixed;
    width: 100%;">
      <thead class="head-table">
        <tr>
        
          <th> Icône</th>
          <th> Nom garantie</th>
          <th> Code garantie</th>
          <th> Unité</th>
          <th> Cotisation </th>
          <th> Description</th>
          <th> Action</th>

        </tr>
      </thead>
      <tbody class="body-table">
        <tr *ngFor="let item of garantie; let index = index"> 
          <td *ngIf="item.icon !== null && item.icon !== '' && item.icon !== 'None' && item.icon !== 'fa'">
             <fa-icon *ngIf="item.icon !== null && !item.icon !== ''  " [icon]="fa[item?.icon]"></fa-icon>
            </td>
          <td *ngIf="item.icon === null || item.icon === ''||  item.icon === 'None' || item.icon === 'fa' "> -</td>
          <td>{{ item.nom }}</td>
          <td>{{ item.code }} </td>
          <td>{{ item.unite ? item.unite : "-" }}</td>
          <td *ngIf="item.cotisation === 1">
            <span>
              <img src="assets/icons/check-circle.svg">
            </span>
          </td>
          <td *ngIf="item.cotisation === 0">
            <span>
              <img src="assets/icons/times-icon.svg">
            </span>
          </td>
          <td *ngIf="item.description !==''"> {{expandFileName(item.description) }}</td>
          <td *ngIf="item.description ==''"> -</td>
          <td>
            <button type="button" class="btn-dt-list" (click)="goToDetails(item) ">
              <img src="/assets/icons/edit-icon.svg">
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>