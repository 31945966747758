<div class="mb-5">
    <div class="card-head text-center">
        <div class="row details-header">
            <button class="btn-dt-retour" (click)="retourToList()" type="button">
        <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        Retour à la liste
      </button>
            <div class=" row details-spinner">
                <div class="col">
                    <ng-container *ngIf="show_loading_add">
                        <div class="col-12">
                            <div class="spinner-style">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <button class="btn-dt-save" type="submit" (click)="updateInformationsUser()">
          <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Enregistrer
        </button>
            </div>
        </div>
    </div>

    <div class="row ">
        <div class="col-8">
            <div class="card p-4">
                <mat-tab-group mat-align-tabs="center" [selectedIndex]="tab.value" (selectedIndexChange)="tab.setValue($event)">
                    <mat-tab label="Informations personnelles">
                        <div class="row">
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="fill">
                                        <mat-label>Civilité </mat-label>
                                        <mat-select name="civilite" [(ngModel)]="utilisateur.user.civilite" placeholder="Civilité">
                                            <mat-option value="M.">
                                                M.
                                            </mat-option>
                                            <mat-option value="Mme">
                                                Mme
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Nom</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.nom" name="nom" required #nom="ngModel">
                                    </mat-form-field>
                                    <div *ngIf="nom.invalid && (nom.dirty || nom.touched)" class="message-erreur">
                                        <div *ngIf="nom.errors.required">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            nom est obligatoire!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Prénom</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.prenom" name="prenom" required #prenom="ngModel">
                                    </mat-form-field>
                                    <div *ngIf="prenom.invalid && (prenom.dirty || prenom.touched)" class="message-erreur">
                                        <div *ngIf="prenom.errors.required">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            prenom est obligatoire!
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Adresse email </mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.mail" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="mail" required #mail="ngModel">
                                    </mat-form-field>
                                    <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">
                                        <div *ngIf="mail.errors.required">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            email est obligatoire!
                                        </div>
                                        <div *ngIf="mail.errors.pattern">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            Format email est invalide! ( Exemple: exemple@exemple.com)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Date de naissance</mat-label>
                                        <input type="date" matInput [(ngModel)]="utilisateur.user.date_naiss_user" name="date_naiss_user">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Clé API</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.api_key" name="api_key">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Matricule RH</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.matricule_rh" name="matricule_rh">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Identité réelle</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.identite_reelle" name="identite_reelle">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Skype</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.skype" name="skype">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard" floatLabel="always">
                                        <mat-label style="margin-left: 50px;">Numéro de téléphone</mat-label>
                                        <input type="text" maxlength="9" matInput [(ngModel)]="utilisateur.user.telephone" name="num_tel" ng2TelInput [ng2TelInputOptions]="{initialCountry: telCountry}" numbersOnly>
                                    </mat-form-field>

                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard" floatLabel="always">
                                        <mat-label style="margin-left: 50px;">Numéro de téléphone 2</mat-label>
                                        <input type="text" maxlength="9" matInput [(ngModel)]="utilisateur.user.tel2" name="tel2" ng2TelInput [ng2TelInputOptions]="{initialCountry: telCountry}" numbersOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard" floatLabel="always">
                                        <mat-label style="margin-left: 50px;">Numéro d'urgence</mat-label>
                                        <input type="text" maxlength="9" matInput [(ngModel)]="utilisateur.user.tel_urgence" name="tel_urgence" ng2TelInput [ng2TelInputOptions]="{initialCountry: telCountry}" numbersOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Numéro interne VoIP</mat-label>
                                        <input type="text" maxlength="9" matInput [(ngModel)]="utilisateur.user.num_interne" name="num_inter" numbersOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <app-auto-complete-adresses  [isReadOnlyAdresse]="allowed_to_update" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                                    </app-auto-complete-adresses>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Numéro de rue</mat-label>
                                        <input type="number" matInput [(ngModel)]="utilisateur.adresse.streetNumber" name="num" numbersOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Rue</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.adresse.streetName" name="rue">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Code postal</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.adresse.CP" (change)="getListeVille($event.target.value)" name="cp" numbersOnly>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Pays</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.ville.id_pays" name="pays">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-4 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Ville</mat-label>
                                        <input name="ville" [(ngModel)]="utilisateur.ville.nom_comm" matInput placeholder="Ville" type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Paramètre du compte">
                        <div class="row">
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <input name="heure_debut" type="text" class="style-timePicker" [(ngModel)]="utilisateur.user.heure_debut" placeholder="Heure début d'activité" aria-label="24hr format" [ngxTimepicker]="Debut" [format]="24" readonly>
                                    <ngx-material-timepicker #Debut></ngx-material-timepicker>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <input name="heure_fin" type="text" class="style-timePicker" [(ngModel)]="utilisateur.user.heure_fin" placeholder="Heure fin d'activité" aria-label="24hr format" [ngxTimepicker]="Fin" [format]="24" readonly>
                                    <ngx-material-timepicker #Fin></ngx-material-timepicker>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group d-flex flex-row mt-4">
                                    <p>Samedi repos:</p>
                                    <div class='checkbox-container'>
                                        <input class="checkbox" type="checkbox" id="toggle2" [(ngModel)]="utilisateur.user.samedi_off">
                                        <div class="checkbox-visual">
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Ancien mot de passe</mat-label>
                                        <input name="ancien_password" matInput type="password" [(ngModel)]="utilisateur.user.old_password">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Nouveau mot de passe</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.password" name="new_password" (ngModelChange)="checkPassword()"  pattern="^(?=.*[0-9])(?=.*[A-Z]).{12,30}$" #newPasswordNgModel="ngModel">
                                    </mat-form-field>
                                 <mat-error *ngIf="newPasswordNgModel.invalid && newPasswordNgModel.errors?.pattern">
                                   
                                            Le mot de passe doit contenir entre 12 et 30 caractères et inclure au moins un chiffre et une lettre majuscule.

                                    
                                      
                                    </mat-error>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Confirmation du nouveau mot de passe</mat-label>
                                        <input matInput name="c_password" name="c_password" type="password" [(ngModel)]="confirmPassword" (change)="checkConfirmPassword($event)" name="confirmPassword">
                                    </mat-form-field>
                                    <ng-container *ngIf="validatePassword">
                                        <p class="error-alert">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            Vérifiez votre mot de passe
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Paramètre webmail">
                        <div class="card-body row">
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Identifiant</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.user_mail_data" name="webmail_mail">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Mot de passe webmail</mat-label>
                                        <input type="password" matInput [(ngModel)]="utilisateur.user.user_pwd_data" name="webmail_pwd">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Serveur de courrier entrant</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.server_in_mail_data" name="webmail_server_in">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Port entrant</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.port_in_mail_data" name="webmail_server_in">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Serveur de courrier sortant</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.server_out_mail_data" name="webmail_server_out">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group">
                                    <mat-form-field class="w-100" appearance="standard">
                                        <mat-label>Port sortant</mat-label>
                                        <input type="text" matInput [(ngModel)]="utilisateur.user.port_out_mail_data" name="webmail_port_out">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group d-flex flex-row">
                                    <p>SSL :</p>
                                    <div class='checkbox-container'>
                                        <input class="checkbox" type="checkbox" id="toggle1" [(ngModel)]="utilisateur.user.ssl_mail_data">
                                        <div class="checkbox-visual">
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 px-1">
                                <div class="form-group d-flex justify-content-start">
                                    <div class="name_btn_config">
                                        <strong>Configuration et test</strong>
                                    </div>
                                    <div class="status config-test" data-toggle="modal" data-target="#exampleModalCenter" (click)="updateInformationsUserTest()">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="55" height="55" viewBox="0 0 55 55">
                        <defs>
                          <filter id="Rectangle_1569" x="0" y="0" width="55" height="55" filterUnits="userSpaceOnUse">
                            <feOffset dy="3" input="SourceAlpha" />
                            <feGaussianBlur stdDeviation="3" result="blur" />
                            <feFlood flood-color="#2e2e2e" flood-opacity="0.11" />
                            <feComposite operator="in" in2="blur" />
                            <feComposite in="SourceGraphic" />
                          </filter>
                        </defs>
                        <g id="Groupe_3411" data-name="Groupe 3411" transform="translate(-614.148 -1125.704)">
                          <g id="Groupe_3259" data-name="Groupe 3259" transform="translate(623.148 1131.704)">
                            <g id="Groupe_3258" data-name="Groupe 3258" transform="translate(0)">
                              <g transform="matrix(1, 0, 0, 1, -9, -6)" filter="url(#Rectangle_1569)">
                                <rect id="Rectangle_1569-2" data-name="Rectangle 1569" width="37" height="37" rx="15"
                                  transform="translate(9 6)" fill="#2a96ff" />
                              </g>
                            </g>
                            <g id="fi-rr-settings" transform="translate(6.868 6.963)">
                              <path id="Tracé_978" data-name="Tracé 978"
                                d="M12.476,8A4.551,4.551,0,0,0,8,12.623a4.551,4.551,0,0,0,4.476,4.623,4.551,4.551,0,0,0,4.476-4.623A4.551,4.551,0,0,0,12.476,8Zm0,6.934a2.312,2.312,0,1,1,2.238-2.311A2.275,2.275,0,0,1,12.476,14.934Z"
                                transform="translate(-0.844 -1.086)" fill="#fff" />
                              <path id="Tracé_979" data-name="Tracé 979"
                                d="M22.848,13.363l-.478-.246a7.824,7.824,0,0,0,0-3.163l.478-.246a2.723,2.723,0,0,0,1.185-3.941,3.454,3.454,0,0,0-4.417-1.058l-.48.247a10.013,10.013,0,0,0-3.069-1.578V2.885A3.073,3.073,0,0,0,12.836,0,3.073,3.073,0,0,0,9.6,2.885v.493A10.009,10.009,0,0,0,6.537,4.96l-.481-.249A3.455,3.455,0,0,0,1.637,5.768,2.724,2.724,0,0,0,2.821,9.711l.478.246a7.823,7.823,0,0,0,0,3.163l-.478.246a2.723,2.723,0,0,0-1.185,3.942,3.454,3.454,0,0,0,4.417,1.058l.48-.247A10.013,10.013,0,0,0,9.6,19.7v.493a3.073,3.073,0,0,0,3.232,2.884,3.073,3.073,0,0,0,3.232-2.884V19.7a10.009,10.009,0,0,0,3.068-1.582l.481.248A3.454,3.454,0,0,0,24.032,17.3a2.723,2.723,0,0,0-1.185-3.941ZM20.1,9.733a6.075,6.075,0,0,1,0,3.605.921.921,0,0,0,.5,1.089l1.168.6a.908.908,0,0,1,.395,1.314,1.151,1.151,0,0,1-1.472.352l-1.17-.6a1.175,1.175,0,0,0-1.309.159,7.776,7.776,0,0,1-3.493,1.8.988.988,0,0,0-.809.931v1.2a1.084,1.084,0,0,1-2.154,0v-1.2a.988.988,0,0,0-.809-.931,7.774,7.774,0,0,1-3.492-1.806,1.175,1.175,0,0,0-1.309-.159l-1.17.6a1.151,1.151,0,0,1-1.472-.352A.907.907,0,0,1,3.9,15.026l1.168-.6a.921.921,0,0,0,.5-1.089,6.075,6.075,0,0,1,0-3.605.921.921,0,0,0-.5-1.085L3.9,8.043A.908.908,0,0,1,3.5,6.729a1.151,1.151,0,0,1,1.472-.352l1.17.6a1.176,1.176,0,0,0,1.308-.154,7.776,7.776,0,0,1,3.493-1.8.987.987,0,0,0,.809-.936v-1.2a1.084,1.084,0,0,1,2.154,0v1.2a.988.988,0,0,0,.809.931,7.774,7.774,0,0,1,3.493,1.806,1.175,1.175,0,0,0,1.309.159l1.168-.6a1.151,1.151,0,0,1,1.472.352.907.907,0,0,1-.394,1.313l-1.168.6a.921.921,0,0,0-.5,1.085Z"
                                transform="translate(-1.203 -0.001)" fill="#fff" />
                            </g>
                          </g>
                        </g>
                      </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
        <div class="col-4">
            <div class="card p-4">
                <div class="card-image">
                    <div class="col-12 text-center">
                        <input type="file" id="img" name="img" accept="image/*" (change)="fileChangeEvent($event)" hidden />
                        <img class="profile-img p-3" *ngIf="utilisateur.user.photo ===''" src="../../../../assets/img/user.png" alt="Aucun photo de profil">
                        <img class="profile-img p-3" *ngIf="utilisateur.user.photo !== '' && !testimage" [src]="croppedImage" alt="Photo de profil">
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1 / 1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" [roundCropper]="true" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                    </div>
                    <div class="col-12 text-center">
                        <div class="col card-upload">
                            <input type="file" accept=".jpg, .jpeg, .png"  (change)="fileChangeEvent($event)" id="actual-btn2" hidden />
                            <label class="btn-uplaod-label" for="actual-btn2">
                  Changer ma photo de profil</label>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <p class="text-center">
                        {{utilisateur.user.nom}} {{utilisateur.user.prenom}}
                    </p>
                </div>
                <hr>
                <div class="button-container mr-auto ml-auto">
                </div>
            </div>
        </div>
    </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLongTitle">Configuration webmail</h2>

                <div style="margin-left: 10px;" *ngIf="!configue">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g id="Groupe_3320" data-name="Groupe 3320" transform="translate(-913 -415)">
              <circle id="Ellipse_196" data-name="Ellipse 196" cx="13" cy="13" r="13" transform="translate(913 415)"
                fill="#e60303" />
              <g id="Groupe_3222" data-name="Groupe 3222" transform="translate(926.162 417.555) rotate(45)">
                <rect id="Rectangle_78" data-name="Rectangle 78" width="15" height="3" rx="1.5"
                  transform="translate(0 6)" fill="#f8f8fb" />
                <rect id="Rectangle_79" data-name="Rectangle 79" width="15" height="3" rx="1.5"
                  transform="translate(9) rotate(90)" fill="#f8f8fb" />
              </g>
            </g>
          </svg>
                </div>


                <div style="margin-left: 10px;" *ngIf="configue">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
            <g id="_20642745911558096434" data-name="20642745911558096434" transform="translate(-3 -3)">
              <circle id="Ellipse_197" data-name="Ellipse 197" cx="13" cy="13" r="13" transform="translate(3 3)"
                fill="#4caf50" />
              <path id="Tracé_6365" data-name="Tracé 6365"
                d="M26.219,14.6,17.8,23.019l-3.467-3.467L12.6,21.286l5.2,5.2L27.952,16.333Z"
                transform="translate(-3.657 -4.419)" fill="#fff" />
            </g>
          </svg>
                </div>
                <div class="message-erreur" style="margin-left: 10px;" *ngIf="show_loading_add == false && todo?.length == 0">Échec d'authentification au serveur Webmail</div>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <div class="col-md-12 pr-1">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="example-container">
                                <h4 class="titleS">Liste des dossiers récupérés par le serveur</h4>

                                <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="todo" [cdkDropListConnectedTo]="[InboxList,Sent,Draft,Spam,Trash]" class="example-list" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of todo" cdkDrag>{{item}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-8">

                            <div class="example-container">
                                <h4 class="titleN">Boite de reception</h4>

                                <div cdkDropList #InboxList="cdkDropList" [cdkDropListData]="inbox" [cdkDropListConnectedTo]="[todoList,Sent,Draft,Spam,Trash]" class="example-list2" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of inbox" cdkDrag>{{item}}</div>
                                </div>
                            </div>

                            <div class="example-container">
                                <h4 class="titleN">Messages Envoyés</h4>

                                <div cdkDropList #Sent="cdkDropList" [cdkDropListData]="sent" [cdkDropListConnectedTo]="[todoList,InboxList,Draft,Spam,Trash]" class="example-list2" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of sent" cdkDrag>{{item}}</div>
                                </div>
                            </div>

                            <div class="example-container">
                                <h4 class="titleN">Brouillons</h4>

                                <div cdkDropList #Draft="cdkDropList" [cdkDropListData]="draft" [cdkDropListConnectedTo]="[todoList,InboxList,Sent,Spam,Trash]" class="example-list2" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of draft" cdkDrag>{{item}}</div>
                                </div>
                            </div>

                            <div class="example-container">
                                <h4 class="titleN">Spam</h4>

                                <div cdkDropList #Spam="cdkDropList" [cdkDropListData]="spam" [cdkDropListConnectedTo]="[todoList,InboxList,Sent,Draft,Trash]" class="example-list2" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of spam" cdkDrag>{{item}}</div>
                                </div>
                            </div>
                            <div class="example-container">
                                <h4 class="titleN">Corbeille</h4>

                                <div cdkDropList #Trash="cdkDropList" [cdkDropListData]="trash" [cdkDropListConnectedTo]="[todoList,InboxList,Sent,Draft,Spam]" class="example-list2" (cdkDropListDropped)="drop($event)">
                                    <div class="example-box" *ngFor="let item of trash" cdkDrag>{{item}}</div>
                                </div>
                            </div>

                        </div>

                    </div>




                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn annuler-btn" #closeModal data-dismiss="modal">Annuler</button>

                <button class="btn send-btn" (click)="correspandance()">
          <fa-icon style="margin-right: 15px" [icon]="fa['faCheckCircle']"></fa-icon>
          Configurer
        </button>

            </div>
        </div>
    </div>
</div>
