
<div class="row containerTache" style="border-radius: 10px 10px 0px 0px;" [ngClass]="{'cover': !accees}">
    <div class="col-md-12 p-0" id="expand">
        <div class=" p-0   scrollbar "  >
        <div class="row ">
            <div class=" col-md-4 card-doc-title ">
              <fa-icon class="mr-2 fa-lg" [icon]="fa['faTasks']"></fa-icon>
              To-do list 
            </div>
            <button type="button"  (click)="expandTask()" class="add-btn-en btn-details-add ajout col-md-4" *ngIf="!isExpand" [disabled]="accees == false">
              <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
            </button>
            <label class=" col-md-4 ">
                <input type="search" class="search " (keyup)="searchTache($event)"  placeholder=" Recherche..." >
            </label>
          </div>
          <div class="row pt-2" *ngIf="allTaches.length ===0">
                <div class="col-12  d-flex justify-content-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="36" viewBox="0 0 31 36" fill="none">
                      <g clip-path="url(#clip0_1805_13925)">
                        <path d="M15.4954 0C14.2758 0 13.2905 0.996855 13.2905 2.23073V3.56916C8.2606 4.60087 4.47095 9.10415 4.47095 14.4997V15.8103C4.47095 19.0866 3.27894 22.2515 1.12917 24.7053L0.619293 25.2839C0.0405103 25.9392 -0.0972952 26.8802 0.254109 27.6819C0.605512 28.4836 1.39789 28.9994 2.26607 28.9994H28.7247C29.5929 28.9994 30.3784 28.4836 30.7367 27.6819C31.095 26.8802 30.9503 25.9392 30.3715 25.2839L29.8616 24.7053C27.7118 22.2515 26.5198 19.0936 26.5198 15.8103V14.4997C26.5198 9.10415 22.7302 4.60087 17.7003 3.56916V2.23073C17.7003 0.996855 16.715 0 15.4954 0ZM18.6167 34.388C19.4435 33.5515 19.9052 32.4152 19.9052 31.2302H15.4954H11.0856C11.0856 32.4152 11.5473 33.5515 12.3741 34.388C13.2009 35.2245 14.324 35.6916 15.4954 35.6916C16.6667 35.6916 17.7898 35.2245 18.6167 34.388Z" fill="#959494"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_1805_13925">
                          <rect width="30.8684" height="35.6916" fill="white" transform="translate(0.0625)"/>
                        </clipPath>
                      </defs>
                    </svg>
                </div>
                <div class="col-12  d-flex justify-content-center">
                  <p class="noData">Pas de données disponibles</p>
                </div>
               
        </div>
          <div class="row  ">
            <div  class="example-list w-100 mw-100 overflow-hidden d-block mr-3  "   >
                
                <div class="example-box row px-2 py-2 flex-row justify-content-between align-items-center"  *ngFor="let tache of allTaches; let i = index" [ngStyle]="{ 'background-color': tache.statut==0 ? '#fff' : '#F0F0F0'}" >
                  <div class="col-4 d-flex align-items-center">
                    <button type="button" *ngIf="tache.statut==1" class="btn-dt-add statusbtn " (click)="changerStatus(tache)" style=" background-color: #CBE2FA; " data-title="Activer la tache">
                      <fa-icon   class="btnActiver" [icon]="fa['faPlay']">
                      </fa-icon>  
                    </button>
                    <button type="button" *ngIf="tache.statut==0" class="btn-dt-add statusbtn  " (click)="changerStatus(tache)"style=" background-color: #FFC9C9; "  data-title="Terminer la tache">
                      <fa-icon   class="btnTerminer" [icon]="fa['faPowerOff']">
                      </fa-icon>  
                    </button>
                    <span class="ml-4 taskName" [ngStyle]="{ 'color': tache.statut==1 ? '#C2C2C2' : '#3B3B3B' }" >{{ expandFileName(tache.titre) }}</span>
                  </div>
                  <div class="col-7">
                    <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of tache.tags" >              
                            <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                        </span>
                    </span>
                    </div>
                    <div class="col-1">
                    <button  type="button" (click)="viewModif(tache)"   class=" float-right btn-dt-edit edit ">
                      <img src="assets/icons/edit-icon.svg">
                    </button>
                    <div *ngIf=" tache.priorite == 3">
                        <div class="notification"></div>
                      <div class="bell-icon__circle"></div>
                      </div>
                  </div>
                  
                </div>
            </div>
        </div>
          </div> 
          
</div>
<app-task-detail class="col-md-4 " *ngIf="isExpand" [tagsList]="tagsList" [taskDetail]="taskDetail" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" (taskEmit)="addTodo($event)"  (inexpandEmit)="cancelAddOrUpdate($event )"></app-task-detail>

        </div>
        <div  *ngIf="totalPages> 0" class="d-flex align-items-center justify-content-center containerTache" style="border-radius: 0px 0px 10px 10px;">
            <nav aria-label="  Page navigation example" >
                <ul class="pagination pg-blue">
                    <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Previous">
                            <fa-icon [icon]="fa['faChevronLeft'] "></fa-icon>
                        </a>
                    </li>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page-3>0" class="page-item">
                            <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                                3}}</a>
                        </li>
                    </ng-container>
                    <ng-container *ngFor="let item of [].constructor(3); let i = index">
                        <li *ngIf="i+page<totalPages" class="page-item">
                            <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                                [ngClass]="{'active-page':page==(i+page)}">
                                {{i + page}}
                            </a>
                        </li>
                    </ng-container>
                    <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
                    <li class="page-item">
                        <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                            [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
                    </li>
                    <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                        <a class="page-link-symbol-chevron" aria-label="Next">
                            <fa-icon [icon]="fa['faChevronRight'] "></fa-icon>
                        </a>
                    </li>
                    <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                        <a class="page-link-symbol">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </nav>
            </div>