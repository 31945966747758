/**
 * Documentation Geoprod
 * Description des web services de Remoteprod
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent, HttpRequest
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable, Subject } from 'rxjs';

import { ModelObject } from '../model/modelObject';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { FilterMaileva } from '../../entity/Maileva/FilterMaileva';


@Injectable()
export class ApiComminucationService {
  private _listners = new Subject<any>();
  protected basePath = environment.api_url;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  listenHistoric(): Observable<any> {
    return this._listners.asObservable();
  }

  pushcommunicationhistoric(filterBy: any) {
    this._listners.next(filterBy);
  }

  /**
   * Permet d&#x27;afficher les information sur un modele de communication suivant le type et son identifiant codé en md5
   *
   * @param id identifiant de modéle codé en md5
   * @param type Type de modele de comminucation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getModeleWithIdSansOppGet(id: ModelObject, type: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getModeleWithIdSansOppGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling getModeleWithIdSansOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      headers.append('Access-Control-Allow-Origin', 'Access-Control-Allow-Origin: *');
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_modele_with_id_sans_opp`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher les information de l'expediteur
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetExpediteurCourrier(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_expediteur_courrier`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
 * Permet de récuperer l'addresse du destinataire suivant l&#x27;identifiant de prospect codé en md5
 *
 * @param idPros Identifiant de add_prospect codé en md5
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */

  public getDestinataireCourrier(idPros?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idPros !== undefined && idPros !== null) {
      queryParameters = queryParameters.set('id_prospect', <any>idPros);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/get_destinataire_courrier?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher les information sur un modele de communication suivant le type et son identifiant codé en md5
   *
   * @param id identifiant de modéle codé en md5
   * @param type Type de modele de comminucation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetDetailsLibellePost(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_details_libelle_post?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d'afficher la list des communications posts
   *
   * @param page numero de la page
   * @param limit nombre de communications par page
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listDetailsPostGET(page_nb: number, itemsperpage: number, sSortDir_0: string, iSortCol_0: number, filtre_maileva: FilterMaileva, sFilter: string = '', sSearch: string = '', observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (page_nb === null || page_nb === undefined) {
      throw new Error('Required parameter page_nb was null or undefined when calling listDetailsPostGET.');
    }

    if (itemsperpage === null || itemsperpage === undefined) {
      throw new Error('Required parameter itemsperpage was null or undefined when calling listDetailsPostGET.');
    }
    if (sSortDir_0 === null || sSortDir_0 === undefined) {
      throw new Error('Required parameter sSortDir_0 was null or undefined when calling listDetailsPostGET.');
    }

    if (iSortCol_0 === null || iSortCol_0 === undefined) {
      throw new Error('Required parameter iSortCol_0 was null or undefined when calling listDetailsPostGET.');
    }
    if (filtre_maileva === null || filtre_maileva === undefined) {
      throw new Error('Required parameter filtre_maileva is null or undefined when calling listDetailsPostGET.');
    }
    if (sSearch === null || sSearch === undefined) {
      throw new Error('Required parameter sSearch is null or undefined when calling listDetailsPostGET.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    let request_body = {
      "filtre_maileva": filtre_maileva,
      "page_nb": page_nb,
      "itemsperpage": itemsperpage,
      "sSortDir_0": sSortDir_0,
      "iSortCol_0": iSortCol_0,
      "sSearch": sSearch,
      "sFilter": sFilter
    }
    return this.httpClient.request<any>('post', `${this.basePath}/list_details_post`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers,
        observe,
        reportProgress,
        body: request_body
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param idOpp Identifiant de collaborateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetHistoriqueCommunicationOpportunite(idOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling listCommunicationDaeObjectifGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>idOpp);
    }


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_historique_comm?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param idAffaire Identifiant de collaborateur codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetHistoriqueCommunicationAffaires(idAffaire: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idAffaire === null || idAffaire === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling listCommunicationDaeObjectifGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idAffaire !== undefined && idAffaire !== null) {
      queryParameters = queryParameters.set('id_aff', <any>idAffaire);
    }


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_historique_comm?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetListTypeAndParent(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_types_and_parent_post?`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param Model
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SaveModel(Model: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Model === null || Model === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling listCommunicationDaeObjectifGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/save_model?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Model
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SavePostDetails(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined when calling listCommunicationDaeObjectifGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/v1/save_post_details?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }

  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param formDAta
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveDataCommunicationMailSMS(formDAta: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (formDAta === null || formDAta === undefined) {
      throw new Error('Required parameter was null or undefined when calling');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/save_data_communication?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: formDAta
      }
    );
  }



  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param media
   * @param idAffaire
   * @param idModel
   * @param content
   * @param numDest
   * @param mailDest
   * @param Subject
   * @param nomDest
   * @param prenomDest
   * @param addressDest
   * @param CodePostalDest
   * @param Ville
   * @param ComplementAddress
   * @param type
   * @param couleur
   * @param addressExp
   * @param NbrePage
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public SaveDataCommunication(formdata: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (formdata === null || formdata === undefined) {
      throw new Error('Required parameter  was null or undefined when calling listCommunicationDaeObjectifGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    /*if (media !== undefined && media !== null) {
      queryParameters = queryParameters.set('media', <any>media);
    }*/

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/validate_PDF_maileva?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: formdata
      }
    );
  }


  /**
   * Afficher les informations sur les communiquée , le naf et cloudpros suivant l&#x27;identifiant de collaborateur codé en md5 et la date_objectif
   *
   * @param idCollab Identifiant de collaborateur codé en md5
   * @param dateObjectif data_objectif de communiqueé
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listCommunicationDaeObjectifGet(idCollab: ModelObject, dateObjectif?: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public listCommunicationDaeObjectifGet(idCollab: ModelObject, dateObjectif?: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public listCommunicationDaeObjectifGet(idCollab: ModelObject, dateObjectif?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public listCommunicationDaeObjectifGet(idCollab: ModelObject, dateObjectif?: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (idCollab === null || idCollab === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling listCommunicationDaeObjectifGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCollab !== undefined && idCollab !== null) {
      queryParameters = queryParameters.set('id_collab', <any>idCollab);
    }
    if (dateObjectif !== undefined && dateObjectif !== null) {
      queryParameters = queryParameters.set('date_objectif', <any>dateObjectif);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_communication_dae_objectif`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Afficher les informations (groupe, nom et id )sur les media de communication tel que sms ,mail ,post,fax
   *
   * @param media &lt;p&gt; Media de commnnication &lt;br&gt; M :Mail &lt;br&gt; F: Fax &lt;br&gt; S : Sms &lt;br&gt; C: Post&lt;/p&gt;
   * @param type &lt;p&gt; Media de commnnication &lt;br&gt; M :Mail &lt;br&gt; F: Fax &lt;br&gt; S : Sms &lt;br&gt; C: Post&lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listModelWithTypeCommGet(media: string, type_entity: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (media === null || media === undefined) {
      throw new Error('Required parameter media was null or undefined when calling listModelWithTypeCommGet.');
    }
    if (type_entity === null || type_entity === undefined) {
      throw new Error('Required parameter media was null or undefined when calling listModelWithTypeCommGet.');
    }


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (media !== undefined && media !== null) {
      queryParameters = queryParameters.set('media', <any>media);
    }


    if (type_entity !== undefined && type_entity !== null) {
      queryParameters = queryParameters.set('type_entity', <any>type_entity);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_model_with_type_comm`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectComWithListGet(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public selectComWithListGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public selectComWithListGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public selectComWithListGet(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/select_com_with_list`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
  * Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
  *
  * @param idpost id of post details.
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */

  public GetDetailsPost(idpost: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_details_post/${idpost}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }





  /**
  * permet d'avoir l'historique d'une communication Maileva
  *
  * @param idcomm id communication .
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */

  public getHistoriqueMaileva(idcomm: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/list_action_maileva/${idcomm}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }





  /**
  * permet d'avoir la liste d'expiditeur
  *
  * @param wordq nom expiditeur  .
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */

  public getListExpediteur(wordq: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/maileva_name_autocomplete?q=${wordq}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
  * Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
  *
  * @param idcomm id of  details Maileva.
  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
  * @param reportProgress flag to report request and response progress.
  */

  public ResendMail(idcomm: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/resend_communication_post/${idcomm}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
   * Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
   *
   * @param idcomm id of  details Maileva.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */

  public CancleMaileva(idcomm: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/cancel_communication_post/${idcomm}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }


  /**
 * Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
 *
 * @param idcomm id of  details Maileva.
 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
 * @param reportProgress flag to report request and response progress.
 */

  public Approuved(idcomm: String, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/update_approuved_status_post/${idcomm}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }



  /**
* Permet d&#x27;afficher les raison sociaux, x et y des modele communication suivant une liste des identifaints des modeles
* @param updatedate date planifier
* @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
* @param reportProgress flag to report request and response progress.
*/

  public updateDatePlanifier(updatedate: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/update_date_planifie_post?`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: updatedate
      }
    );
  }
  /**
    * Afficher les informations sur les modeles
    *
    * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    * @param reportProgress flag to report request and response progress.
    */
  public GetModelsPost(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/get_models_post?`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public ScheduleMeeting(idOpp: string, dateDebut: any, observe: any = 'body', reportProgress: boolean = true): Observable<any> {



    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', <any>idOpp);
    }

    if (dateDebut !== undefined && dateDebut !== null) {
      queryParameters = queryParameters.set('date_rendez_vous', <any>dateDebut);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(new HttpRequest('get', `${this.basePath}/schedule_meeting?`,
      {
        params: queryParameters,
        observe: observe,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: reportProgress
      }
    ));
  }

  public editingMachine(idModel: string, type_comm: string, id_aff: string, id_opp: string, mail_template: {}, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    queryParameters = queryParameters.set('id_aff', <any>id_aff);


    queryParameters = queryParameters.set('id_opp', <any>id_opp);


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys["idSession"]) {
      headers = headers.set('idSession', this.configuration.apiKeys["idSession"]);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('post', `${this.basePath}/editing_machine/build/${idModel}/${type_comm}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: mail_template
      }
    );
  }
}

