<ng-container *ngIf="entreprise != null">
    <!---------------------------------------------------------------------------------------------------------------->
    <ng-container>
        <div  *ngIf="entreprise.type_opportunite === 'particulier'">

            <!--Prsopect Principale-->

            <mat-accordion class="example-headers-align">
                <mat-expansion-panel class="expension-secondary-color">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h2 class="font-primary-geoprod">Adhérent/Souscripteur principal</h2>
                        </mat-panel-title>
                        <mat-panel-description>
                            <!--Description-->
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <app-prospect-form  [schemaValue]="prospectSchema" [isRequired]="true" [allowed_to_update]="allowed_to_update" [typeEntity]="typeEntity" [index]="'0'" [isMainProspect]="true" [type]="3" [prospect]="entrepriseClone?.mainProspect" (onUpdateProspect)="updateProspect($event,-1)"></app-prospect-form>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion class="example-headers-align">
                <ng-container *ngFor="let prospect  of entrepriseClone?.listProspectsLier; let index =index">
                    <mat-expansion-panel *ngIf="!prospect.deleted" class="expension-secondary-color mt-2" [expanded]="step === (index+1)" (opened)="setStep(index+1)">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <h2 class="font-primary-geoprod"> Adhérent/Souscripteur N°{{index + 1}} </h2>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Description-->
                                <div class="col-10"></div>
                                <div class="col-2 d-flex justify-content-end align-items-center">
                                    <!-- <span (click)="deleteProspect(index+1)"><img src="assets/icons/trash-icon-red.svg"></span> -->
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <app-prospect-form [schemaValue]="prospectSchema" [allowed_to_update]="allowed_to_update" [isRequired]="false" [isMainProspect]="false" [type]="0" [typeEntity]="typeEntity" [modeupdateProspect]="prospect.id" [index]="(index+1).toString()" [prospect]="prospect"
                            (onUpdateProspect)="updateProspect($event,index+1)">
                        </app-prospect-form>

                        <!-- <mat-action-row>
                <button mat-button color="warn" (click)="prevStep()">Précédent</button>
                <button mat-button color="primary" (click)="nextStep()">Suivant</button>
              </mat-action-row> -->
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>


         <div *ngIf="entreprise.type_opportunite === 'entrepise'">
            <app-prospect-pro-form [schemaValue]="EntrepriseSchema"  [allowed_to_update]="allowed_to_update" [type]="3" [isUpdate]="true" [addProspectBtn]="false" (onUpdateEntreprise)="updateEntreprise($event)" (onUpdateProspect)="updateProspect($event.prospect, $event.index)" [entreprise]="entrepriseClone"
                [prospectPro]="entrepriseClone.listProspectsLier">
            </app-prospect-pro-form>
        </div>

        <div class="text-right mt-3" [ngClass]="{'cover': allowed_to_update == false}">
            <button *ngIf="person" type="button" [disabled]="allowed_to_update == false" (click)="addProspect()" class="add-btn-em">
                <img class="fa-icon-add mr-1 " src="assets/icons/adhrentAdd.svg">
            <span *ngIf="entreprise.type_opportunite === 'particulier'" >Adhérent/Bénéficiaire</span>
            <span *ngIf="entreprise.type_opportunite === 'entrepise'" >Ajouter collaborateur</span>
          </button>
        </div>

    </ng-container>
    <!---------------------------------------------------------------------------------------------------------------->

</ng-container>
<div class="d-flex justify-content-center" *ngIf="entreprise == null">
    <ng-container *ngIf="show_loading_add">
        <div class="my-3">
            <mat-spinner [diameter]="20"></mat-spinner>
        </div>
    </ng-container>
</div>
