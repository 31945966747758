<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    <div class="row">
      <div class="col">
        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
          <fa-icon [icon]="faFilter"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="btn-dt-ajouter mb-2 w-100">
        <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp;
        Filtrer la recherche
      </button>
    </div>
    <!-- <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
      <div class="close-element">
        <fa-icon [icon]="faPlus"></fa-icon>
      </div>
    </div> -->
    <div class="ml-1">

      <div class="row">
        <div class="col-6 align-self-center">
          <div class="row">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
              <fa-icon [icon]="fa['faRedoAlt']"></fa-icon>
            </button>
             <app-list-filter-saved [entity]="'M'" (filterOpportunite)="getFilterFromCollection($event)" (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
            <!-- <div class="FILTRES__RECHERCHES" >
              <fa-icon class="fa-2x mr-2" (click)="toggleFilterCorp()" [icon]="faSearch"></fa-icon>
                <span>{{'opportunities.label.morefilters' | translate}}</span>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filtre_maileva" [entity]="'M'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save" (click)="submitFilter()">
              <fa-icon class="fa-lg" [icon]="fa['faSearch']"></fa-icon>&nbsp;
              {{'opportunities.label.startasearch' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>

      <div class="scrolling"  id="style-1">
        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                          <fa-icon class="fa-lg mr-2" [icon]="fa['faUserTag']"></fa-icon>
                          <span>Destinataire</span>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-distinataire class="w-100"
                    (onPushfilterDestinataire)="EmitFilterDestinataire($event)"
                    (returnToparent)="getResetDestinataire($event)" [Reset]="onResetDestinataire">
                  </app-filters-distinataire>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                          <fa-icon class="fa-lg mr-2" [icon]="fa['faUserEdit']"></fa-icon>

                            <span>Expéditeur</span>

                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-expiditeur class="w-100" (returnToparent)="getResetExpediteur($event)"
                    [Reset]="onResetExpediteur" (onPushfilterExpiditeur)="EmitFilterExpediteur($event)">
                  </app-filters-expiditeur>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>


        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                     <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                          <fa-icon class="fa-lg mr-2" [icon]="fa['faUserCog']"></fa-icon>

                            <span>Créateur</span>

                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-createurs class="w-100" (onPushfilterCreateur)="EmitFilterCreateur($event)"
                    (returnToparent)="getResetCreateur($event)" [Reset]="onResetCreateur">
                  </app-filters-createurs>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>



        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                          <fa-icon class="fa-lg mr-2" [icon]="fa['faPaperPlane']"></fa-icon>

                            <span>Courrier</span>

                        </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3">
                  <app-filters-courrier class="w-100" (onPushfilterCourrier)="EmitFilterCourrier($event)"
                    (returnToparent)="getResetCourrier($event)" [Reset]="onResetCourrier">
                  </app-filters-courrier>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>

        <div class="row mb-2" >
          <div class="col-12">
            <mat-accordion>
              <mat-expansion-panel hideToggle>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="row w-100 d-flex justify-content-center" >
                      <div class="row filtre-spn" >
                          <fa-icon class="fa-lg mr-2" [icon]="fa['faLightbulb']"></fa-icon>

                            <span>Statuts</span>

                        </div>
                    </div>

                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row mt-3" >
                  <app-filters-status class="w-100" (onPushfilterStatut)="EmitFilterStatus($event)"
                    (returnToparent)="getResetStatut($event)" [Reset]="onResetStatut">
                  </app-filters-status>
                </div>
              </mat-expansion-panel>

            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
