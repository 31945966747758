import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { NotificationMessage, NotificationType } from './../../../entity/NotificationMessage';

import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { GlobalConfig, ToastrService } from 'ngx-toastr';
import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as far from '@fortawesome/free-regular-svg-icons';
import { Process } from '../../../entity/Process';
import { Details } from 'src/app/entity/Opportunity/Details';

import { ApiOpportunitService } from '../../../services/ApiOpportunite/api-opportunite.service';
import { Comments } from '../../../entity/Comments';
import { ListProspect, Prospect } from '../../../entity/Prospect';
import { ListTypeRendezVous } from '../../../entity/Opportunity/ListTypeRendezVous';
import { ListCampagne } from '../../../entity/Opportunity/ListCampagne';
import { ListVille } from '../../../entity/ListVille';
import { ListDoublon } from '../../../entity/Affaires/ListDoublon';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiProspectsService } from '../../../services/ApiProspects/api-prospects.service';
import { map, tap } from 'rxjs/operators';
import { CycleVie } from '../../../entity/CycleVie';
import { ApiCampagniesService } from '../../../services/ApiCompagnies/api-compagnies.service';
import { ApiStatusService } from '../../../services/ApiStatus/api-status.service';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
import { Parrainage } from '../../../entity/Generic/Parrainage';
import { ApiAuthentificationService } from '../../../services/ApiAuthentification/api-authentification.service';
import { GetOpportuniteDetails } from '../../../entity/Opportunity/GetOpportuniteDetails';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { Store } from '@ngrx/store';
import * as actions from './store/details-opportunite.actions';
import * as selectors from './store';
import {
  DetailsOpportuniteStatus,
  DetailsOpportuniteStore,
  storeStatusEnum,
} from './store/details-opportunite.reducers';
import { Observable, Subscription } from 'rxjs';
import { addOpportuniteStateEnum } from '../add-opportunite/store/add-opportunite.reducers';
import { InformationSpecifiquesComponent } from './Elements/details/information-specifiques/information-specifiques.component';
import { NgbdmodalContent } from 'src/app/components/add-affaire-modal/add-affaire-modal.component';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ApiAdminService } from 'src/app/services/ApiAdmin/api-admin.service';
import { ApiCampagneService } from 'src/app/services/ApiCampagne/api-campagne.service';
import { Campagnes } from 'src/app/entity/Campagnes';
import { StatusAutorise, StatutChildOpp } from 'src/app/entity/Opportunity/StatusAutorise';
import { AccessList } from 'src/app/entity/accessList';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';

@Component({
  selector: 'app-opportunities-details',
  templateUrl: './opportunities-details.component.html',
  styleUrls: ['./opportunities-details.component.css'],
})
export class OpportunitiesDetailsComponent implements OnInit, OnDestroy {
  listOfProcess: Process[] = [];
  @ViewChild(InformationSpecifiquesComponent) child: InformationSpecifiquesComponent;
  Actions = 'Changer le status';
  Enregistrer = 'Mettre à jour';
  fa = fa;
  far = far; // font awesome regular icons
  show_loading_add: boolean = false;
  show_loading_status: boolean = false;
  loader_villes: boolean = false;
  cycleVie: CycleVie = new CycleVie();
  horsCamp: boolean = false;
  selected_cyclevie: string = '';
  listCycleVieStr: any;
  listCycleVie: any;
  listCampagnes: any;
  opportunity_id: string;
  successtestcomp: boolean = false;
  successtestspecif: boolean = false;
  listComments: Comments[] = [];
  listRDV: ListTypeRendezVous[] = [];
  listCampagne: ListCampagne[] = [];
  listDoublon: ListDoublon[] = [];
  opportunite_details = new GetOpportuniteDetails();
  prospect = new Prospect();
  prospectOpp = new ListProspect();
  listVille: ListVille[] = [];
  id_user: string;
  form: FormGroup;
  detail: Details = new Details();
  status_autorise: StatusAutorise = new StatusAutorise();
  formDouble: FormGroup;
  current_process: Process = new Process('', '');
  subStatusList: StatutChildOpp[] = [];
  commentChangeAction: string = '';
  lastActionId: string = '';
  show_loading: boolean = false;
  show_loading_user: boolean = true;
  idSelected: any;
  ListIdProspect = [];
  item: number = 0;
  campagne_id: string;
  parrainage: Parrainage = new Parrainage();
  id_prospect: string;
  apikey: any = '';
  typeOpp: any;
  option: GlobalConfig;
  nav_switched: string = '';
  listeOfUnsubscribeWebService: Subscription[] = [];
  @ViewChild('closebuttonParrainage') closebuttonParrainage; // permet de fermer le popup parrainage
  realopp_id: string;
  noList: boolean;
  emailcreateur: string;
  campagne: Campagnes;
  etat_id: any;
  isListe: boolean = false;
  allowed_to_update: boolean;
  formDataUploadDocument: any[] = [];
  telCountry = environment.country;
  accessList: AccessList;
  histAccess: boolean;
  histCommAccess: boolean;
  urlcomparateur: string;
  urltarification: string;
  accessComp: boolean;
  accessTarif: boolean;
  tarifAppear: boolean;
  comparateurAppear: boolean;
  communication: boolean;
  listObjetAssurer: any[] = [];
  isbien: boolean = false;
  isperson: boolean = false;
  updateobjetAssurer: boolean = false;
  errorProspect: boolean = false;
  access_categories_opp: any[] = [];
  modifiedArray: any[] =[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiOpportunitService: ApiOpportunitService,
    private formBuilder: FormBuilder,
    private apiCampagniesService: ApiCampagniesService,
    private apiProspectsService: ApiProspectsService,
    private apiStatusService: ApiStatusService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAuthentificationService: ApiAuthentificationService,
    private translate: TranslateService,
    private apiCampagneService: ApiCampagneService,
    public toastr: ToastrService,
    private modalService: NgbModal,
    private apiAdminService: ApiAdminService,
    private notificationsService: NotificationsService,
    private store$: Store<DetailsOpportuniteStore>,
    private apiDocumentsService: ApiDocumentsService,
    private _eventEmiter: EventEmiterService
  ) {
    this.option = this.toastr.toastrConfig;
    this.apiOpportunitService.messageSubject.subscribe((message) => {});
    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
      const lastItemsDict: { [key: string]: any } = {};

      this.formDataUploadDocument.forEach((item) => {
        lastItemsDict[item.documentId] = item;
      });

      this.modifiedArray = Object.values(lastItemsDict);
    });
    this._eventEmiter.getListObject().subscribe((data) => {
      this.listObjetAssurer = data;
    });
    this._eventEmiter.geterrorUpdateProspect().subscribe((data) => {
      this.errorProspect = data;
    });
  }

  detailsState: DetailsOpportuniteStatus = null;
  updateStatus$: Observable<any> | null = null;
  opportunite$: Observable<any> | null = null;
  propectobs: Observable<any> | null = null;
  updateStatus: storeStatusEnum = null;

  // Expose enum to HTML template.
  stateUpadateEnum: typeof addOpportuniteStateEnum = addOpportuniteStateEnum;
  async Access() {
    try {
      const value = await this.apiAuthentificationService.listOfAccess$.toPromise();

      this.accessList = value;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.accessTarif = this.accessList.tarificateur;
      this.accessComp = this.accessList.comparateur;
      this.communication = this.accessList.communication;
      this.urlcomparateur = this.accessList.urlcomparateur;
      this.urltarification = this.accessList.urltarification;
      this.access_categories_opp = this.accessList.access_categories_opp;
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  ngOnInit(): void {
    // this.Access();
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.histAccess = this.accessList.historique;
      this.histCommAccess = this.accessList.historique_communications;
      this.accessTarif = this.accessList.tarificateur;
      this.accessComp = this.accessList.comparateur;
      this.communication = this.accessList.communication;
      this.urlcomparateur = this.accessList.urlcomparateur;
      this.urltarification = this.accessList.urltarification;
      this.access_categories_opp = this.accessList.access_categories_opp;
    });
    this._eventEmiter.getUpdateListObject().subscribe((value: boolean) => {
      this.updateobjetAssurer = value;
    });
    /*   this.apiAuthentificationService.listLoginDatas$.subscribe((value) => {
      console.log('datalogin', value);
      this.urlcomparateur = value.urlcomparateur;
      this.urltarification = value.urltarification;

    }); */

    if (this.accessComp) {
      if (this.urlcomparateur !== '' && this.urlcomparateur !== null) {
        this.comparateurAppear = true;
      } else {
        this.comparateurAppear = false;
      }
    } else {
      this.comparateurAppear = false;
    }

    if (this.accessTarif) {
      if (this.urltarification !== '' && this.urltarification !== null) {
        this.tarifAppear = true;
      } else {
        this.tarifAppear = false;
      }
    } else {
      this.tarifAppear = false;
    }

    this.show_loading_user = true;
    if (this.router.url.includes('prospect')) this.nav_switched = 'prospect';
    else if (this.router.url.includes('contrat')) this.nav_switched = 'contrat-actuel';
    else if (this.router.url.includes('planification')) this.nav_switched = 'planification';
    else if (this.router.url.includes('historique')) this.nav_switched = 'historique';
    else if (this.router.url.includes('communication')) this.nav_switched = 'communication';

    this.store$.pipe(map((state) => state)).subscribe((state) => {
      this.detailsState = state.detailsOpportunite;
    });
    this.route.paramMap.subscribe((params) => {
      this.opportunity_id = params.get('op_id');
      this.getOpportunite();
      this.store$.dispatch(actions.GetDetails({ payload: { opportuniteId: this.opportunity_id } }));
      this.store$.dispatch(actions.GetProspect({ payload: { opportuniteId: this.opportunity_id } }));
      this.store$.dispatch(actions.GetInfoSpecifique({ payload: { opportuniteId: this.opportunity_id } }));
      this.store$.dispatch(actions.GetInfoComplementaire({ payload: { opportuniteId: this.opportunity_id } }));
      this.store$.dispatch(actions.GetConstractActuel({ payload: { opportuniteId: this.opportunity_id } }));
      this.getComments(params.get('op_id'));
      this.getprospectoppp();
      //  this.getDoublonList();
      this.getStatusAutorise();
    });

    this.updateStatus$ = this.store$.select(selectors.getStateFromDetailsOpportunite);
    this.updateStatus$.subscribe((state) => {
      this.updateStatus = state;
      if (state.state === storeStatusEnum.UPDATE_SUCCESS || state.state === storeStatusEnum.RDV_DATE_UPDATED) {
        this.hideloader();
        this.alertSuccess('Opportunité a été mise à jour avec succès');
        this.getprospectoppp();
      }
      if (state.state === storeStatusEnum.UPDATE_FAIL) {
        this.hideloader();
        this.alertError(state.errorMessage || 'Un problème servenu lors de la modification de cette opportunité');
      }
      if (state.state === storeStatusEnum.RDV_DATE_UPDATE_ERROR) {
        this.hideloader();
        this.alertErrorDate('Date de rendez-vous est erronée ou invalide!');
      }

      if (state.state === storeStatusEnum.LOADING_STATE) {
        this.showloader();
      }
      if (state.state === storeStatusEnum.LOADED_STATE) {
        this.hideloader();
      }
    });
    this.getListTypeRDV();
    this.getListCampagnes();
    this.getBpmList();
    this.form = this.formBuilder.group({
      campagne: new FormControl(''),
    });
    this.formDouble = this.formBuilder.group({
      selectProspect: new FormControl(),
    });
    const unsubscribeGet_session = this.apiAuthentificationService.get_session().subscribe((data) => {
      this.apikey = data.token;
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGet_session);

    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails');
  }

  ngOnDestroy(): void {
    if (!this.isListe) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
    }

    this.toastr.clear();
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  getprospectoppp() {
    const unsubscribeGetProspectByOpprtuniteId = this.apiProspectsService
      .getProspectByOpprtuniteId(this.opportunity_id)
      .subscribe((data: ListProspect) => {
        this.prospectOpp = data;
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetProspectByOpprtuniteId);
  }

  getOpportunite() {
    const unsubscribeGetOpportunite = this.apiOpportunitService
      .GetOpportunite(this.opportunity_id)
      .subscribe((data: GetOpportuniteDetails) => {
        this.allowed_to_update = data?.opportunite_details?.allowed_to_update;
        this.apiOpportunitService.accesOpportunite$.next(data?.opportunite_details?.allowed_to_update);
        this.realopp_id = data.real_id;
        this.opportunite_details = data;
        this.etat_id = this.opportunite_details.opportunite_details.etat_id;
        this.id_prospect = this.opportunite_details.opportunite_details.main_prospect_id;
        this.campagne_id = this.opportunite_details.opportunite_details.campagne_id;
        this.typeOpp = this.opportunite_details.opportunite_details.type_opportunite;
        if (this.opportunite_details.opportunite_details.bien === 1) {
          this.isbien = true;
        }
        if (this.opportunite_details.opportunite_details.person === 1) {
          this.isperson = true;
          this._eventEmiter.setperson(this.isperson);
        } else {
          this._eventEmiter.setperson(this.isperson);
        }
        this.getuserbyid(this.opportunite_details.opportunite_details.user_id);
        this.getcampagne(this.opportunite_details.opportunite_details.campagne_id);
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetOpportunite);
  }

  getuserbyid(user_id) {
    const unsubscribeGetUserById = this.apiAdminService.getUserById(user_id).subscribe(
      (data) => {
        this.emailcreateur = data.user.mail;
        this.hideloader();
      },
      (error) => {
        if (error) {
          this.hideloader();
        }
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetUserById);
  }

  getcampagne(campagne_id) {
    const unsubscribeGetCamapagneById = this.apiCampagneService.getCamapagneById(campagne_id).subscribe(
      (data: Campagnes) => {
        this.campagne = data;
        this.show_loading_user = false;
      },
      (error) => {
        if (error) {
          this.show_loading_user = false;
        }
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetCamapagneById);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // showError() {
  //   this.toastr.error('Attention !', 'Doublon probable', {
  //     timeOut: 0,
  //     extendedTimeOut: 0,
  //   });
  // }

  alertSuccess(data, err?) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: 'Fermer',
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.ngOnInit();
        }
      });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertErrorDate(data) {
    swal.fire({
      title: data,
      text: '!',
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertError(data, err?) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  alertErrorAction(data, err) {
    swal.fire({
      title: data,
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  getBpmList() {
    const unsubscribeGetListBpmByOppId = this.apiCampagniesService
      .getListBpmByOppId(this.opportunity_id)
      .subscribe((data: Process[]) => {
        this.listOfProcess = data;
        this.current_process = this.listOfProcess
          .filter((elem) => {
            return elem.class === 'active';
          })
          .pop();
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListBpmByOppId);
  }

  getComments(id_opp) {}

  getCurrentPath(name) {
    if (name === 'prospect') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/prospect']);
      this.nav_switched = 'prospect';
    } else if (name === 'contrat-actuel') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/details/contrat-actuel']);
      this.nav_switched = 'contrat-actuel';
    } else if (name === 'planification') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/planification']);
      this.nav_switched = 'planification';
    } else if (name === 'historique') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/historique']);
      this.nav_switched = 'historique';
    } else if (name === 'communication') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/communication']);
      this.nav_switched = 'communication';
    } else if (name === 'commentaire') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/commentaire']);
      this.nav_switched = 'commentaire';
    } else if (name === 'porteDocument') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/porteDocument']);
      this.nav_switched = 'porteDocument';
    } else if (name === 'tache') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/tache']);
      this.nav_switched = 'tache';
    } else if (name === 'ticket') {
      this.router.navigate(['/opportunities/details/' + this.opportunity_id + '/ticket'], {
        state: { example: this.id_prospect },
      });
      this.nav_switched = 'ticket';
    } else if (name === 'objet-assurer') {
      this.router.navigate(
        ['/opportunities/details/' + this.opportunity_id + '/objet-assurer']
        //  { state: { example: this.campagne_id } }
      );
      this.nav_switched = 'objet-assurer';
    }
  }

  onSubmit() {
    if (this.parrainage.nom_parrainage === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez remplir le champ nom !';
      this.notificationsService.sendMessage(notificationMessage);
    } else if (this.parrainage.prenom_parrainage === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez remplir le champ prénom !';
      this.notificationsService.sendMessage(notificationMessage);
    } else if (this.parrainage.relation_parrainage === '') {
      const notificationMessage = new NotificationMessage();
      notificationMessage.type = NotificationType.warning;
      notificationMessage.message = 'Veuillez remplir le champ relation!';
      this.notificationsService.sendMessage(notificationMessage);
    } else {
      this.show_loading_add = true;
      this.parrainage.id_prospect = this.id_prospect;
      this.parrainage.id_campagne = this.campagne_id;
      this.parrainage.type_opp = this.typeOpp;
      const unsubscribeAddParrainageGet = this.apiProspectsService.addParrainageGet(this.parrainage).subscribe(
        (response) => {
          this.closebuttonParrainage.nativeElement.click();
          if (response.statut === 'OK') {
            this.alertSuccess('Parrainage avec succées');
            this.show_loading_add = false;
          } else {
            this.show_loading_add = false;
            this.alertError('Erreur');
          }
        },
        (error) => {
          this.show_loading_add = false;
          this.alertError('Erreur', error);
        }
      );
      this.listeOfUnsubscribeWebService.push(unsubscribeAddParrainageGet);
    }
  }

  Actualise() {
    window.location.reload();
  }

  getListTypeRDV() {
    const unsubscribeGetListRDV = this.apiOpportunitService.getListRDV().subscribe((data: ListTypeRendezVous[]) => {
      this.listRDV = data;
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListRDV);
  }

  getListCampagnes() {
    const unsubscribeGetListCampagne = this.apiOpportunitService
      .getListCampagne()
      .pipe(
        tap(({ campagne, hors_campagne }) => {
          this.cycleVie.campagne = campagne;
          this.cycleVie.hors_campagne = hors_campagne;
          this.SelectListCycleVie();
        })
      )
      .subscribe();
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListCampagne);
  }

  SelectListCycleVie() {
    this.horsCamp
      ? (this.listCycleVieStr = Object.keys(this.cycleVie.hors_campagne))
      : (this.listCycleVieStr = Object.keys(this.cycleVie.campagne));
    this.horsCamp ? (this.listCycleVie = this.cycleVie.hors_campagne) : (this.listCycleVie = this.cycleVie.campagne);
    this.form.value.campagne = new FormControl('');
    this.SelectCycleVie();
  }

  SelectCycleVie() {
    this.listCampagnes = this.listCycleVie[this.selected_cyclevie];
  }

  getVilleList() {
    this.loader_villes = true;
    const unsubscribeGetListVille = this.apiProspectsService
      .getListVille(this.parrainage.cp_parrainage)
      .subscribe((data: ListVille[]) => {
        this.listVille = data;
        this.loader_villes = false;
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListVille);
  }

  // getDoublonList() {
  //   const unsubscribegetProspectDoublon = this.apiProspectsService
  //     .getProspectDoublon(this.opportunity_id)
  //     .subscribe((data: ListDoublon[]) => {
  //       this.listDoublon = data;
  //       if (this.listDoublon?.length > 0) this.showError();
  //     });
  //   this.listeOfUnsubscribeWebService.push(unsubscribegetProspectDoublon);
  // }

  goToComparateur() {
    // 'http://197.13.15.234/mutuelle_direct/comparateur_v1.php?id_opp='

    window.open(this.urlcomparateur + this.opportunity_id + '&api_key=' + this.apikey);
  }

  goToTarificateur() {
    // 'http://197.13.15.234/mutuelle_direct/index.php?id_opp='
    window.open(this.urltarification + this.opportunity_id + '&api_key=' + this.apikey);
  }

  existeDansListeSup: boolean = false;
  existeVisa: boolean = false;
  getStatusAutorise() {
    this.appearModel = true;
    this.appear = true;
    this.show_loading_status = true;
    const unsubscribegetStatutAutoriseGet = this.apiStatusService
      .getStatutAutoriseGet(this.opportunity_id)
      .pipe(
        tap(({ listactions }) => {
          this.status_autorise.listCategory = listactions;
          this.show_loading_status = false;
          this.status_autorise.listCategory.forEach((element) => {
            element.listactions.forEach((statut) => {
              /*     if (statut.visa !==null) {
                this.existeVisa = this.access_categories_opp.some(item => item === statut.visa);
                if(this.existeVisa){
                  statut.access_sup = true;
                }else{
                  statut.access_sup = false;
                }
              }else{ */
              this.existeDansListeSup = this.access_categories_opp.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }
              //   }
            });
          });
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });

    this.listeOfUnsubscribeWebService.push(unsubscribegetStatutAutoriseGet);
  }

  existeDansListeSupChild: boolean = false;

  updateSubActionsList(id: string) {
    this.show_loading = true;
    this.lastActionId = id;
    const unsubscribeGetListActionsGet = this.apiStatusService.getListActionsGet(id).subscribe((data) => {
      this.subStatusList = data;
      this.subStatusList.forEach((element) => {
        this.existeDansListeSupChild = this.access_categories_opp.some((item) => item === element.categorie);
        if (this.existeDansListeSupChild === false && element.sup === 1) {
          element.access_sup = false;
        } else {
          element.access_sup = true;
        }
      });
      this.show_loading = false;
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListActionsGet);
  }

  changeAction() {
    this.show_loading = true;
    this.appearModel = false;
    this.apiOpportunitService.updateOpportuniteAction(this.opportunity_id, this.lastActionId);
    this.show_loading = true;
    const unsubscribeupdateOpportuniteAction = this.apiOpportunitService
      .updateOpportuniteAction(this.opportunity_id, this.lastActionId)
      .subscribe(
        (data) => {
          this.show_loading = false;

          swal
            .fire({
              title: 'Opération Réussie!',
              text: 'Action mise a jour avec succès',
              icon: 'success',
              showConfirmButton: true,
              showCloseButton: true,
              confirmButtonText: 'Fermer',
              confirmButtonColor: '#68a45b',
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.Actualise();
                this.ngOnInit();
              }
            });
          // this.alertSuccess('Action mise a jour avec succès', data);
          // this.Actualise();
        },
        (error) => {
          this.show_loading = false;
          this.alertErrorAction('Erreur', error.error.message);
          // this.Actualise();
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeupdateOpportuniteAction);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  getProspectDoublon(event: any) {
    if (event.target.checked === true) {
      this.idSelected = event.target.value;
      this.ListIdProspect[this.item] = this.idSelected;
      this.item++;
    } else {
      this.idSelected = event.target.value;
      this.deleteItem(this.idSelected);
      this.item--;
    }
  }

  deleteItem(element: any) {
    const index: number = this.ListIdProspect.indexOf(element);
    if (index !== -1) {
      this.ListIdProspect.splice(index, 1);
    }
  }

  setFussionProspectDoublon() {
    const unsubscribeFussionProspectDoublon = this.apiProspectsService
      .fussionProspectDoublon(this.ListIdProspect)
      .subscribe(
        (response) => {
          this.ngOnInit();
          this.alertSuccess('Prospect Fussionner');
        },
        (error: any) => {
          this.alertError('error', error);
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeFussionProspectDoublon);
  }

  saveOpportuniteDetails() {
    this.apiOpportunitService.pushContratActual(this.detailsState.constractActuel);
    this.apiOpportunitService.pushInfoCompl(this.detailsState.informationComplementaires);
    this.apiOpportunitService.pushInfoSpecif(this.detailsState.informationSpecifiques);

    if (
      (this.updateStatus === storeStatusEnum.UPDATE_SUCCESS || this.updateStatus === storeStatusEnum.INIT_STATE) &&
      this.formDataUploadDocument.length === 0
    ) {
      swal.fire({
        title: 'Aucune modification à enregistrer!',
        confirmButtonText: `Annuler`,
        icon: 'info',
      });

      return;
    }

    this.confirmUpdate();
  }

  confirmUpdate() {
    swal
      .fire({
        title: 'Voulez-vous enregistrer les modifications?',
        showDenyButton: true,
        confirmButtonText: `Sauvegarder`,
        denyButtonText: `Annuler`,
        icon: 'warning',
      })
      .then((result) => {
        if (result.isConfirmed) {
          if (this.errorProspect === false) {
            if (this.modifiedArray.length > 0) {
              this.modifiedArray.forEach((element, index) => {
                this.uploadDocumentsOpportunite(element.Formdata);
                if (index + 1 === this.formDataUploadDocument.length)
                  this.apiDocumentsService.loadDocument$.next('refreshOpportunite');
              });
              this.ngOnInit();
              const notificationMessage = new NotificationMessage();
              notificationMessage.type = NotificationType.success;
              notificationMessage.title = 'Opération réussite';
              notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
              this.notificationsService.sendMessage(notificationMessage);
            }
            if (this.updateobjetAssurer === true) {
              if (this.listObjetAssurer.length > 0) {
                this.store$.dispatch(
                  actions.UpdateOBJETAssurer({
                    payload: {
                      id_opp: this.opportunity_id,
                      listObjetAssurer: this.listObjetAssurer,
                    },
                  })
                );
                this._eventEmiter.setUpdateListObject(false);
                this.detailsState = {
                  ...this.detailsState, // Preserve existing properties
                  listObjetAssurer: this.listObjetAssurer, // Update the desired property
                };
              } else {
                this.hideloader();
                swal.fire(
                  'Les modifications ne sont pas enregistrées',
                  '<p>vous devez ajouter au moins un objet à assurer</p>',
                  'info'
                );

                this.show_loading = false;
              }
            }

            this.store$.dispatch(actions.SaveDetails({ payload: this.detailsState }));
          } else {
            swal.fire('Veuillez remplir les champ nom, prénom et e-mail de Souscripteur/ Adhérent', '', 'info');
          }
        } else if (result.isDenied) {
          swal.fire('Les modifications ne sont pas enregistrées', '', 'info');
        }
      });
  }

  uploadDocumentsOpportunite(formData: FormData): void {
    this.apiOpportunitService.uploadDocumentOpportunite(formData).subscribe(
      (response: any) => {
        this.show_loading = false;
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading = false;
      }
    );
  }

  AddAffaire() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'myCustomModalClass',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };
    const modeldoc = this.modalService.open(NgbdmodalContent, options);
    modeldoc.componentInstance.Id_opp = this.realopp_id;
    modeldoc.componentInstance.disabledchamp = true;
    modeldoc.componentInstance.event.subscribe((data: any) => {
      this.AddAffaire();
    });
  }

  returnToList(): void {
    this.isListe = true;
    const savedFilter=localStorage.getItem('savedFilter');
    const savedCollection=localStorage.getItem('savedCollection');
    const queryParams = {
      filter: savedFilter,
      collection: savedCollection,
    };
    this.router.navigate(['opportunities'], { queryParams: queryParams });
  }

  appearModel: boolean = false;
  appear = true;
  step: boolean = false;
  statutappear() {
    this.appear = false;
  }

  resetStatus() {
    this.step = false;
    this.appear = false;
    this.appearModel = false;
  }

  retourSubmit() {
    this.appear = true;
  }

  appearS() {
    this.step = true;
  }

  showModalUpdateStatut() {
    this.appear = false;
    this.appearModel = true;
    this.show_loading_status = true;
    const unsubscribegetStatutAutoriseGet = this.apiStatusService
      .getStatutAutoriseGet(this.opportunity_id)
      .pipe(
        tap(({ listactions }) => {
          this.show_loading_status = false;
          this.status_autorise.listCategory = listactions;
          this.status_autorise.listCategory.forEach((element) => {
            element.listactions.forEach((statut) => {
              this.existeDansListeSup = this.access_categories_opp.some((item) => item === element.id);
              if (this.existeDansListeSup === false && statut.sup === 1) {
                statut.access_sup = false;
              } else {
                statut.access_sup = true;
              }
              //   }
            });
          });
        })
      )
      .subscribe((data) => {
        this.show_loading_status = false;
      });

    this.listeOfUnsubscribeWebService.push(unsubscribegetStatutAutoriseGet);
  }
}
