export class Utilisateur {
  entreprise_call: string = '';
  recheche_create_user: any[] = [];
  recheche_zone: any[] = [];
  operateur_recherche: string = '';
  recherche_groupe: any[] = [];
  service: any[] = [];
}

export class Prospect {
  prospect_nom_prenom: string = '';
  prospect_tel: string = '';
  prospect_mail: string = '';
  prospect_ville: string = '';
  RS : string ='';
  prospect_zone: any[] = [];
  tags: any[] = [];
  tags_no: any[] = [];
  mail_contient: string = '';
  name: string = '';
  surname: string = '';
}

export class Affaire {
  cmp: any[] = [];
  operateur_cmp: string = '=';
  operateur_gamme: string = '=';
  gamme: any[] = [];
  operateur_produit: string = '=';
  produit: any[] = [];
  recheche_num_contrat: string = '';
  pan_ttc_min: string = '';
  pan_ttc_max: string = '';
  pose_du_creation: string =null;
  pose_au_creation: string = null;
  pose_du_souscription: string = null;
  pose_au_souscription: string = null;
  date_effet_dossier_du: string = null;
  date_effet_dossier_au: string = null;
  operateur_contrat: string = '=';
  contrat: any[] = [];
  classe: any[] = [];
  operateur_recherche_tag: string = '=';
  tags_aff: any[] = [];
  // operateur_recheche_not_tag: string = 'ET';
  tags_aff_not: any[] = [];
  res_env: string = '';
  pose_du_date_envoi_res: string =null;
  pose_au_date_envoi_res: string =null;
  affaire_occ_res: any[] = [];
  type_date :string = "effet"
  // derog: derogation = new derogation();
}

// export class derogation {
//     derog_1: string = "";
//     min_derog_1: string = "";
//     max_derog_1: string = "";
// }

export class ContratActuel {
  deja_assure: string = '';
  an_cmp: any[] = [];
  operateur_pan_ttc: string = '';
  PAN_TTC: string = '';
  date_ech: string = null;
}

export class StatutActuel {
  status: any[] = [];
}

export class Qualite {
  compteur_qualite: string = '';
  affaire_enquete: string = '';
  qualite4: string = '';
  qualite1: string = '';
  qualite2: string = '';
  qualite3: string = '';
}

export class ActionUserAffaire {
  dernier_action: string = '';
  date_creation_action: string = null;
  date_fin_action: string = null;
  list_user: any[] = [];
  enreg_simple: string = '';
  enreg_avec_action: string = '';
  status_action: any[] = [];
  categorie: any[] = [];
}

export class Filtre_opp {
  campagne: any[] = [];
  date_debut_creation: string = null;
  date_fin_creation: string = null;
  date_debut_rdv: string = null;
  date_fin_rdv: string = null;
  cycle_de_vie: any[] = [];
  tags_opp: any[] = [];
  // type_opportuntie: any[] = [];
}

export class UtilisateurOpp {
  organismes: any[] = [];
  list_createurs: any[] = [];
  list_affectation: any[] = [];
  groupes_affecte: any[] = [];
  groupes_createurs: any[] = [];
  zones: any[] = [];
  services: any[] = [];
  operateur_recherche_group: string = '';
  operateur_recherche_group_affet: string = '';
}

export class Action_user {
  dernier_action: string = '';
  date_creation_action: string = null;
  date_fin_action: string = null;
  list_user: any[] = [];
  groupes: any[] = [];
  enreg_simple: string = '';
  status_action: any[] = [];
  categorie: any[] = [];
  operateur: string = '';
  enreg_avec_action: string = '';
}

export class Rdv {
  etat_rdv: any[] = [];
}

export class TelephonieOpp {
  argumentes_tout: string = '';
  operateur_telephonie: string = '';
  value_telephonie: string = '';
  operateur_duree: string = '';
  value_duree: string = '';
}

export class Opp {
  filtre_opp: Filtre_opp = new Filtre_opp();
  utilisateur: UtilisateurOpp = new UtilisateurOpp();
  action_user: Action_user = new Action_user();
  rdv: Rdv = new Rdv();
  telephonie: TelephonieOpp = new TelephonieOpp();
}

export class FilterAffaires {
  utilisateur: Utilisateur = new Utilisateur();
  prospect: Prospect = new Prospect();
  affaire: Affaire = new Affaire();
  contrat_actuel: ContratActuel = new ContratActuel();
  statut_actuel: StatutActuel = new StatutActuel();
  qualite: Qualite = new Qualite();

  action_user: ActionUserAffaire = new ActionUserAffaire();
}

export class global {
  aff: FilterAffaires = new FilterAffaires();
  opp: Opp = new Opp();

  constructor() {
    this.aff = new FilterAffaires(); // Initialise la propriété "aff"
    this.opp = new Opp(); // Initialise la propriété "opp"
  }
}
