import { environment } from 'src/environments/environment';
import { FilterAffaires, Opp, global } from './../../../entity/Affaires/FilterAffaires';
import { Component, OnInit, LOCALE_ID, HostListener, ViewChild, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAffairesService } from '../../../services/ApiAffaires/api-affaires.service';
import { AffairesList } from '../../../entity/Affaires/AffairesList';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { ApiVoIPService } from 'src/app/services/ApiVoIP/api-voip.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import {
  formatDateToLocal,
  UniformiserDateToLocal,
  UniformiserDateToLocalSansHeure,
  parseDate,
} from 'src/app/shared/Utils/sharedFunctions';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdmodalContent } from 'src/app/components/add-affaire-modal/add-affaire-modal.component';
import { ActionsGroupeeComponent } from 'src/app/components/actions-groupee/actions-groupee.component';
import { max } from 'moment';
import { AccessList } from 'src/app/entity/accessList';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { isEqual, differenceWith } from 'lodash';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
class BodyAffaireUpdated {
  action_statut: boolean;
  statut: any;
  list_affaires: any;
}
@Component({
  selector: 'app-affaires',
  templateUrl: './affaires.component.html',
  styleUrls: ['./affaires.component.css'],
  providers: [DatePipe],
})
export class AffairesComponent implements OnInit ,AfterViewInit {
  show_loading_add: boolean = false;
  all_filtres: global = new global();
  all_filtresInitial: global = new global();
  fa = fa;
  affairesList: AffairesList = new AffairesList();
  limit = 15;
  page = 1;
  intervale = 1;
  sSearch: string = '';
  totalPages = 0;
  pages = 1;
  // qsort_field: string = 'default';
  sort_field: string = 'affaire_date_deff';
  sort_type: string = 'DESC';
  dateForm: FormGroup;
  date_debut: any = '';
  date_fin: any = '';
  myDate = new Date();
  list_tel = {
    mobile: '',
    tel: '',
    tel2: '',
  };

  @ViewChild('Effet') Effet: MatDateRangePicker<any>;

  filtre: any = null;
  isEdit: boolean = false;
  selected = 'effet';
  date_fin_init: string;
  date_debut_init: string;
  isGlobalFilterChange: boolean = false;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  checklist: any[] = [];
  checkedList: any;
  masterSelected: boolean;
  actionButton: boolean = false;
  accessList: AccessList;
  act_grp_communication: boolean;
  act_grp_negociateur: boolean;
  act_grp_pubpostage: boolean;
  act_grp_statut: boolean;
  act_grp_tags: boolean;
  actionGroupeAccess: boolean;
  totalLength: number = 0;
  filterSsearch: boolean = false;
  readonly rangeDateEffet = RangeDateComponent;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private apiAffaireService: ApiAffairesService,
    private apiProspectsService: ApiProspectsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiVoIPService: ApiVoIPService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private apiAuthentificationService: ApiAuthentificationService,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    localStorage.removeItem('filtre');
    localStorage.removeItem('page');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('sort_type');
    localStorage.removeItem('sSearch');
  }

  collapseFromParent : boolean ;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;
      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event){
    this.collapseFromParent = event;
  }

  ngOnInit(): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.act_grp_communication = this.accessList.act_grp_communication;
      this.act_grp_pubpostage = this.accessList.act_grp_pubpostage;
      this.act_grp_statut = this.accessList.act_grp_statut;
      this.act_grp_tags = this.accessList.act_grp_tags;

      if (this.act_grp_tags && this.act_grp_statut && this.act_grp_pubpostage) {
        this.actionGroupeAccess = true;
      } else {
        this.actionGroupeAccess = false;
      }
    });
    this.selected = 'Creation';
    this.all_filtres.aff.affaire.type_date = 'Creation';
    this.date_debut = this.datePipe.transform(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()),
      'yyyy-MM-dd'
    );
    this.date_fin = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.filtre = localStorage.getItem('filtre');
    const page = localStorage.getItem('page');
    const sort_fields = localStorage.getItem('sort_field');
    const sort_types = localStorage.getItem('sort_type');
    const sSearchs = localStorage.getItem('sSearch');

    if (sort_fields !== null) {
      this.sort_field = sort_fields;
    }
    if (sort_types !== null) {
      this.sort_type = sort_types;
    }

    if (page !== null) {
      this.page = parseInt(page);
    }
    if (sSearchs) {
      this.sSearch = sSearchs;
      this.getListAffaireSearch();
    } else if (this.filtre !== null) {
      this.all_filtres = JSON.parse(this.filtre);
      if (!this.all_filtres.aff.affaire.pose_du_creation && this.all_filtres.aff.affaire.date_effet_dossier_du) {
        this.selected = 'effet';
        this.all_filtres.aff.affaire.type_date = 'effet';
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.selected = 'Creation';
        this.all_filtres.aff.affaire.type_date = 'Creation';
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      }
      this.getListAffaires();
      // const date_debutt = new Date(this.all_filtres?.aff?.affaire?.date_effet_dossier_du);
      // const date_finn = new Date(this.all_filtres?.aff?.affaire?.date_effet_dossier_au);
      // if (date_debutt.getDate()) this.date_debut = this.datePipe.transform(date_debutt, 'yyyy-MM-dd');
      // if (date_finn.getDate()) this.date_fin = this.datePipe.transform(date_finn, 'yyyy-MM-dd');
    } else {
      this.getDataValue('init');
    }

    this.sharedMenuObserverService.updateMenu('Affaires');
  }

  openDateeffet() {
    if (this.Effet) {
      this.Effet.open();
    }
  }

  clearDateeffet() {
    this.date_debut = null;
    this.date_fin = null;
  }

  ngOnDestroy() {
    if (this.isEdit === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');
      localStorage.removeItem('savedFilter');
      localStorage.removeItem('savedCollection');
    } else {
      localStorage.setItem('filtre', JSON.stringify(this.all_filtres));
      localStorage.setItem('sSearch', this.sSearch);
    }
  }

  getDataValue(value) {
    localStorage.removeItem('page');
    this.page = 1;
    this.date_debut_init = this.datePipe.transform(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()),
      'yyyy-MM-dd'
    );
    this.filterSsearch = false;
    this.all_filtresInitial.aff.affaire.pose_du_creation = null;
    this.all_filtresInitial.aff.affaire.pose_au_creation = null;
    this.date_fin_init = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.sSearch = '';
    if (value === 'init') {
      this.setDates(null, null, this.date_debut, this.date_fin);
    } else if (value === 'submit') {
      if (this.isObjectEqual()) {
        this.setDates(null, null, this.date_debut_init, this.date_fin_init);
      } else if (
        this.all_filtres.aff.affaire.pose_du_creation &&
        this.all_filtres.aff.affaire.pose_au_creation &&
        (this.all_filtres.aff.affaire.pose_du_creation !== this.date_debut_init ||
          this.all_filtres.aff.affaire.pose_au_creation !== this.date_fin_init)
      ) {
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      } else if (
        this.all_filtres.aff.affaire.date_effet_dossier_du &&
        this.all_filtres.aff.affaire.date_effet_dossier_au
      ) {
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.setDates(null, null, null, null);
      }
    } else if (value === 'affaire') {
      if (this.isObjectEqual()) {
        this.setDates(null, null, this.date_debut_init, this.date_fin_init);
      } else {
        this.setDates(
          this.all_filtres.aff.affaire.date_effet_dossier_du,
          this.all_filtres.aff.affaire.date_effet_dossier_au,
          this.all_filtres.aff.affaire.pose_du_creation,
          this.all_filtres.aff.affaire.pose_au_creation
        );
      }

      if (!this.all_filtres.aff.affaire.pose_du_creation && this.all_filtres.aff.affaire.date_effet_dossier_du) {
        this.selected = 'effet';
        this.all_filtres.aff.affaire.type_date = 'effet';
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.selected = 'Creation';
        this.all_filtres.aff.affaire.type_date = 'Creation';
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      }
    } else if (value === 'reset') {
      this.all_filtres = new global();
      this.selected = 'Creation';
      this.all_filtres.aff.affaire.type_date = 'Creation';
      this.setDates(null, null, this.date_debut_init, this.date_fin_init);
    } else {
      if (this.selected === 'Creation') {
        this.setDates(null, null, this.date_debut, this.date_fin);
        this.all_filtres.aff.affaire.type_date = 'Creation';
      } else {
        this.all_filtres.aff.affaire.type_date = 'effet';

        this.setDates(this.date_debut, this.date_fin, null, null);
      }
    }
    this.getListAffaires();
  }

  setDates(debut, fin, debutCreation, finCreation) {
    this.all_filtres.aff.affaire.pose_du_creation = this.datePipe.transform(debutCreation, 'yyyy-MM-dd');
    this.all_filtres.aff.affaire.pose_au_creation = this.datePipe.transform(finCreation, 'yyyy-MM-dd');

    this.all_filtres.aff.affaire.date_effet_dossier_du = this.datePipe.transform(debut, 'yyyy-MM-dd');
    this.all_filtres.aff.affaire.date_effet_dossier_au = this.datePipe.transform(fin, 'yyyy-MM-dd');
  }

  isObjectEqual() {
    const differences = differenceWith([this.all_filtres, this.all_filtresInitial]);
    return isEqual(this.all_filtresInitial, this.all_filtres);
  }

  getListAffaires() {
    this.showloader();
    // if (this.selected==="Creation"){
    // this.date_debut =this.all_filtres.aff.affaire.pose_du_creation
    // this.date_fin =this.all_filtres.aff.affaire.pose_au_creation

    // }
    this.apiAffaireService
      .listAffairesGet(this.page, this.limit, this.sort_field, this.sort_type, this.sSearch, this.all_filtres)
      .subscribe((data: AffairesList) => {
        this.affairesList = data;
        if (this.selected === 'effet') {
          this.date_debut = parseDate(data.min_date_deff);
          this.date_fin = parseDate(data.max_date_deff);
        } else {
          this.date_debut = parseDate(data.min_date_creation);
          this.date_fin = parseDate(data.max_date_creation);
        }

        this.affairesList.aaData.forEach((element) => {
          const datecreation = UniformiserDateToLocal(element.affaire_date_creation);
          element.affaire_date_creation = datecreation;
          const datedeff = UniformiserDateToLocalSansHeure(element.affaire_date_deff);
          element.affaire_date_deff = datedeff;
        });
        this.totalLength = data.iTotalDisplayRecords;
        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  getDataValuee(event) {
    if (event.length >= 3) {
      this.all_filtresInitial.aff.affaire.pose_du_creation = null;
      this.all_filtresInitial.aff.affaire.pose_au_creation = null;
      this.page = 1;
      this.filterSsearch = true;
      this.sSearch = event;
      this.getListAffaireSearch();
    } else if (event.length === 0) {
      this.all_filtresInitial.aff.affaire.pose_du_creation = this.date_debut_init;
      this.all_filtresInitial.aff.affaire.pose_au_creation = this.date_fin_init;
      this.page = 1;
      this.filterSsearch = true;
      this.sSearch = event;
      this.getListAffaireSearch();
    }
  }

  getListAffaireSearch() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    this.showloader();
    this.apiAffaireService
      .listAffairesGet(this.page, this.limit, this.sort_field, this.sort_type, this.sSearch, this.all_filtresInitial)
      .subscribe((data: AffairesList) => {
        this.affairesList = data;
        this.date_debut = parseDate(data.min_date_creation);
        this.date_fin = parseDate(data.max_date_creation);
        this.affairesList.aaData.forEach((element) => {
          const datecreation = UniformiserDateToLocal(element.affaire_date_creation);
          element.affaire_date_creation = datecreation;
          const datedeff = UniformiserDateToLocalSansHeure(element.affaire_date_deff);
          element.affaire_date_deff = datedeff;
        });
        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  quickFilterChange(): void {
    this.isGlobalFilterChange = false;
    this.all_filtres = new global();
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getDate() {
    this.date_debut = '';
    this.date_fin = '';
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  setSortField(sort_field: string) {
    if (this.sort_field === sort_field) {
      if (this.sort_type === 'desc') {
        this.sort_type = 'asc';
      } else {
        this.sort_type = 'desc';
      }
    } else {
      this.sort_type = 'asc';
    }
    this.sort_field = sort_field;
    localStorage.setItem('sort_field', this.sort_field);
    localStorage.setItem('sort_type', this.sort_type);
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  updateListAffaires() {
    this.page = 1;
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        if (this.filterSsearch === true) {
          this.getListAffaireSearch();
        } else {
          this.getListAffaires();
        }
      }
    }
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', number.toString());
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  EmitFilterAffaire($event: global) {
    this.all_filtres = $event;
  }

  getPhoneList(id_prospect: string) {
    this.show_loading_add = true;
    this.apiProspectsService.getProspectTel(id_prospect).subscribe((data) => {
      this.list_tel.mobile = data.list_tel.mobile;
      this.list_tel.tel = data.list_tel.tel;
      this.list_tel.tel2 = data.list_tel.tel2;
      this.show_loading_add = false;
    });
  }

  callVoip(number) {
    this.showloader();
    this.apiVoIPService.callVoipGet(number).subscribe((data) => {
      this.hideloader();
      if (data.etat_ws === 'ERROR Merci de vérifier vos coordonnées d’accès ') {
        this.alertErrorVoip();
      }
    });
  }

  add_suivi_affaire(opportunite_id: string) {
    /*     this.apiOpportunitService.addSuiviOppGet(opportunite_id)
      .subscribe((response) => {
        this.alertSuccess(response.message)
      }) */
  }

  alertErrorVoip() {
    swal
      .fire({
        title: 'Vérifiez votre configuration VoIP!',
        text: 'Vérifiez votre numéro interne VoIP',
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: 'Fermer',
        confirmButtonText: 'Configurer',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/profile']).then(() => {
            window.location.reload();
          });
        }
      });
  }

  AddAffaire() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'myCustomModalClass',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };
    const modeldoc = this.modalService.open(NgbdmodalContent, options);
    modeldoc.componentInstance.disabledchamp = false;
    modeldoc.componentInstance.event.subscribe((data: any) => {
      modeldoc.close();
      this.AddAffaire();
    });
  }

  updateAffaire() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'my-class custom-modal', 
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };

    const modeldoc = this.modalService.open(ActionsGroupeeComponent, options);
    modeldoc.componentInstance.type = 'affaire';
    modeldoc.componentInstance.listeAffaire = this.checkedList;
    modeldoc.result.then(
      (res) => {},
      (dismiss) => {
        if (dismiss !== '' && dismiss !== undefined) {
          if (dismiss !== 'error') {
            this.alertSuccesss(dismiss);
          } else {
            this.alertError('Permission refusée');
          }
        }
        /*      if (dismiss !== '' && dismiss !== undefined) {
          this.alertSuccesss(dismiss);
        } */
        /*      if (dismiss !== 'error') {
          this.alertSuccesss(dismiss);
        } else {
          this.alertError('Permission refusée');
        } */
        /*   if(dismiss !== '' && dismiss !== undefined){
      const bodyAffaireUpdated = new BodyAffaireUpdated() ;
      bodyAffaireUpdated.action_statut = true;
      bodyAffaireUpdated.statut = dismiss
      bodyAffaireUpdated.list_affaires =  this.checkedList
    this.apiAffaireService.multipleActionAffaire(bodyAffaireUpdated).subscribe((data)=>{
   // const message =data.nb_modified + ' Affaires modifiées \n & ' +data.nb_not_modified+  ' Affaires non modifiées '
    this.alertSuccesss(data.nb_modified_status,data.nb_not_modified_status);

 })
} */
      }
    );
  }

  alertError(data) {
    swal.fire({
      title: "Quelque chose s'est mal passé!",
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }

  checkUncheckAll() {
    for (let i = 0; i < this.affairesList.aaData.length; i++) {
      this.affairesList.aaData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.affairesList.aaData.every(function (item: any) {
      return item.isSelected === true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.affairesList.aaData.length; i++) {
      if (this.affairesList.aaData[i].isSelected) this.checkedList.push(this.affairesList.aaData[i].affaire_id);
    }
    if (this.checkedList.length > 0) {
      this.actionButton = true;
    } else {
      this.actionButton = false;
    }
  }

  alertSuccesss(data) {
    const total =
      data.nb_modified_status +
      data.nb_modified_add_tags +
      data.nb_modified_delete_tags +
      data.nb_modified_tag_prospect;
    swal
      .fire({
        html:
          " <div class='row justify-content-center'>  <div class='card-info-1'><p class='p-info-2' style=' padding-top: 13px;font-size: 14px; text-align: center; '>" +
          data.nb_total +
          ' Eléments séléctionnnés. <br> ' +
          total +
          " Modifications effectuées: </p></div></div> <div class='row justify-content-center '><div class='col-4'>  <div class='card-info-3' style='  width: 100%; height: 90px;border-radius: 10px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_13T09_16_51_979Z.png' width='20' height='20'> <br>" +
          data.nb_modified_status +
          " Status modifiées</p><p class='p-info-1' style='font-size: 15px;text-align: center;  font-weight: bold;'>" +
          "</p></div></div><div class='col-4'><div class='card-info-3'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '><img src='assets/icons/image_2023_01_12T14_48_35_211Z.png' width='20' height='20'> <br>" +
          data.nb_modified_add_tags +
          " Mots clés ajoutés</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'> " +
          "</p></div></div><div class='col-4'><div class='card-info-3' style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_12T14_47_34_190Z.png' width='20' height='20'><br>" +
          data.nb_modified_delete_tags +
          " Mots clés supprimés</p><p class='p-info-1' style='font-size: 15px;text-align: center ; font-weight: bold;'>" +
          "</p></div> </div></div> <div class='row row justify-content-center' style='margin-top:10px;'> <div class='col-4' > <div class='card-info-3'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style='font-size: 14px;text-align: center; padding-top:13px; '><img src='assets/icons/image_2023_01_12T14_54_07_809Z.png' width='20' height='20'> <br> " +
          data.nb_modified_tag_prospect +
          " Mots clés prospect ajoutés</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'> " +
          '</p></div>  </div> </div>',
        icon: 'info',
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Fermer',
      })
      .then((result) => {
        this.hideloader();
        if (this.filterSsearch === true) {
          this.getListAffaireSearch();
        } else {
          this.getListAffaires();
        }
        this.masterSelected = false;
        for (let i = 0; i < this.affairesList.aaData.length; i++) {
          this.affairesList.aaData[i].isSelected = false;
          this.actionButton = false;
        }
      });
  }

  goToEdit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('filter') !== null && queryParams.get('collection') !== null) {
        localStorage.setItem('savedFilter', queryParams.get('filter'));
        localStorage.setItem('savedCollection', queryParams.get('collection'));
      }
    });
    this.isEdit = true;
  }
}
