<div class="card justify-content-center w-100 border-0" [ngStyle]="{'background-color': color}">
    <div class="row  center-form">
        <div class="col-md-6 col-sm-6">
            <mat-form-field class="full-width custom-form-field" appearance="fill" style="width: 100%">
                <mat-label>Nom convention</mat-label>
                <input type="text" matInput [(ngModel)]="conventionData.nom" name="nom" required (change)="emitForm()" class="custom-input"
                    [readonly]="!readOnlyAscConvention || disabledName ==false ||conventionData.status == ConventionStatusActive ||conventionData.status == ConventionStatusFerme" />
            </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-spinner class="mt-4" *ngIf="!loaderOrganisme" [diameter]="20"></mat-spinner>
            <mat-form-field *ngIf="loaderOrganisme" class="full-width custom-form-field" appearance="fill">
                <mat-label> Distributeur</mat-label>
                <input type="text" required matInput  [formControl]="organismeFromCtrl"
                 [matAutocomplete]="auto"
                    [readonly]="!readOnlyAscConvention || conventionData.status == ConventionStatusActive ||conventionData.status == ConventionStatusFerme"
                    (input)="onInputChanged()">
                <mat-autocomplete (optionSelected)="selectedOrganisme($event)" autoActiveFirstOption
                    [displayWith]="displayOrgFn" #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOrganisme | async" [value]="option">
                        {{option.nom}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-form-field appearance="fill" class="w-100 custom-form-field">
                <mat-label>Date début</mat-label>
                <input matInput [matDatepicker]="debut" [(ngModel)]="conventionData.start_date" placeholder="Date début"
                    required #date="ngModel" readonly  name="date"
                     (dateChange)="emitForm()" >
                <mat-datepicker-toggle matSuffix [for]="debut" [disabled]="!readOnlyAscConvention || conventionData.status == ConventionStatusActive ||conventionData.status == ConventionStatusFerme"></mat-datepicker-toggle>
                <mat-datepicker #debut>
                </mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-6 col-sm-6">
            <mat-form-field appearance="fill" class="w-100 custom-form-field">
                <mat-label>Date fin</mat-label>
                <input matInput [matDatepicker]="fin" (dateChange)="emitForm()" [(ngModel)]="conventionData.valid_unitl"
                    placeholder="Date fin" required #date="ngModel" readonly  name="date">
                <mat-datepicker-toggle matSuffix [for]="fin"  [disabled]="!readOnlyAscConvention ||conventionData.status ==ConventionStatusActive ||conventionData.status == ConventionStatusFerme"></mat-datepicker-toggle>
                <mat-datepicker #fin>
                </mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-6">
            <mat-form-field appearance="fill" class="w-100 custom-form-field">
                <mat-label>Gammes</mat-label>
                <mat-chip-list #Gammes>
                    <mat-chip *ngFor="let gammes of ListGammes" [selectable]="selectableGamme"
                        [removable]="removableGamme" (removed)="removeGammes(gammes)"
                        [disabled]="!readOnlyAscConvention || conventionData.status == ConventionStatusActive ||conventionData.status == ConventionStatusFerme">
                        {{gammes}}
                        <i matChipRemove *ngIf="removableGamme" class="fa fa-times" aria-hidden="true"></i>
                    </mat-chip>
                    <input placeholder="Selectionner Gammes..." #gammeInput
                        [readonly]="!readOnlyAscConvention || conventionData.status == ConventionStatusActive ||conventionData.status == ConventionStatusFerme" [formControl]="gammeFromCtrl"
                        [matAutocomplete]="autoGamme" [matChipInputFor]="Gammes"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" required>
                </mat-chip-list>
                <mat-autocomplete #autoGamme="matAutocomplete" (optionSelected)="selectedGammes($event)">
                    <mat-option *ngFor="let gammes of filteredGammes | async" [value]="gammes">
                        {{gammes.nom}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>


        <div class="col-6  mt-5 justify-content-center" *ngIf="mode !== 'ajout'">
            <button type="button" (click)="OnOpen()" [disabled]=" !readOnlyAscConvention || conventionData.status == ConventionStatusFerme || conventionData.id ==''"
                Style=" background-color: #ff808b; color: white;" class=" btn-status " 
                [ngStyle]="{'opacity': !readOnlyAscConvention || conventionData.status == ConventionStatusFerme || conventionData.id =='' ?'0.5':'1'}">
                <img class="icon-btn-action" src="assets/icons/action-icon.svg">
                Changer le statut
            </button>
        </div>
    </div>
</div>