<form  #myForm="ngForm" (ngSubmit)="EnregistrerBanque(myForm.form.valid, myForm._directives)" #myForm="ngForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9 mt-1" >
                <p>
                    Banque :
                    <label class="label-card-info">
            {{Banque.nom}}
          </label>
                </p>
                <p>
                    Code Swift :
                    <label class="label-card-info">
            {{Banque.code_swift}}
          </label>
                </p>
            </div>
           
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button class="btn-dt-retour" (click)="retourToList()" type="button">
        <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        Retour à la liste
      </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    <div class="col mt-1" *ngIf="ModeAjout" >
                        <button type="button" class="btn-load" (click)="clearForm()">
              <fa-icon [icon]="fa['faRedo']"></fa-icon>
            </button>
                    </div>
                </div>
                <button *ngIf="ModeAjout" class="btn-dt-save" type="submit">
          <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Enregistrer
        </button>
            </div>
        </div>
    </div>
    <div class="card p-4 m-3" [ngStyle]="!ModeAjout ? {'pointer-events': 'none'} : {}">
        <div class="row">
            <div class="col-12">
                <span class="text-danger">( * ) champs obligatoire!</span>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput [(ngModel)]="Banque.nom"  name="nom"  required #nom="ngModel" [readonly]="!ModeAjout">
                        <mat-error><span> Nom est obligatoire
                            </span></mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Code Swift</mat-label>
                        <input matInput [(ngModel)]="Banque.code_swift"  name="code_swift" required #code_swift="ngModel" [readonly]="!ModeAjout">
                        <mat-error><span> Le code swift est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                    
                </div>
                
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Code Banque</mat-label>
                        <input matInput [(ngModel)]="Banque.code_banque"  name="code_banque" required #code_banque="ngModel" [readonly]="!ModeAjout">
                        <mat-error   ><span> Le code banque est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
        </div>
    

       
        <div class="row">

            <div class="col-md-4 col-sm-12">
                <div class="form-group">

                    <app-auto-complete-adresses  [isReadOnlyAdresse]="ModeAjout"  (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                    </app-auto-complete-adresses>
                </div>
            </div>
            
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Pays</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.pays" required #pays="ngModel" name="pays" [readonly]="!ModeAjout">
                        <mat-error><span> La pays est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Code Postal</mat-label>
                        <input  matInput [(ngModel)]="Banque.adresse.code_postal" required #cp="ngModel" name="cp" [readonly]="!ModeAjout">
                        <mat-error><span> Le Code Postal est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
           

           
        </div>

        <div class="row">
                
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Ville</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.ville" required #ville="ngModel" name="ville" [readonly]="!ModeAjout">
                        <mat-error><span> La Ville est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>Rue</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.streetName" required #nom_de_rue="ngModel" name="nom_de_rue" [readonly]="!ModeAjout">
                        <mat-error><span> La rue est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <mat-form-field appearance="standard" class="full-width">
                        <mat-label>N° de Rue</mat-label>
                        <input matInput [(ngModel)]="Banque.adresse.streetNumber" required #numero_de_rue="ngModel" name="numero_de_rue" [readonly]="!ModeAjout">
                        <mat-error><span> Le numero de rue est obligatoire!
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            
            </div>
        
        
    </div>
    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content full-width">
                <div class="modal-header">
                    <h2 class="mt-2" >Logo du Compagnie</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row d-flex justify-content-center">
                        <img class="img" >
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</form>
