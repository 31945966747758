<div class="row" style="margin-top: 15px;">
  <div class="col-6">
    <div class="form-group" style="margin-top: -30px;padding: 0;">

      <ng-container>
        <mat-form-field class="w-100">

          <mat-label>Ligne de Produit</mat-label>
          <ng-container class="p-3" *ngIf="allProducts?.length === 0">
            <div class="row">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let product of ListProducts" [selectable]="selectable" [removable]="removable"
              (removed)="removeProducts(product)" (removed)="EmitFilterOpportunite()">
              {{product}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input placeholder="Selectionner produit..." #ProductsInput [formControl]="ProductsFromCtrl"
              [matAutocomplete]="auto" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedProducts($event)">
            <mat-option *ngFor="let product of filteredProducts | async" [value]="product.id">
              {{product.type}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>


  <div class="col-6">
    <div class="form-group" style="margin-top: -30px;padding: 0;">

      <ng-container>
        <mat-form-field class="w-100">
          <mat-label>Campagne</mat-label>
          <ng-container class="p-3" *ngIf="allCampagne?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #Campagne>
            <mat-chip *ngFor="let Campagne of ListCampagne" [selectable]="selectable" [removable]="removable"
              (removed)="removeCampagne(Campagne)" (removed)="EmitFilterOpportunite()">
              {{Campagne}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Campagne..." #CampagneInput
              [formControl]="CampagnesFromCtrl" [matAutocomplete]="autoCampagne" [matChipInputFor]="Campagne"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCampagne="matAutocomplete" (optionSelected)="selectedCampagne($event)">
            <mat-option *ngFor="let Campagne of filteredCampagne | async" [value]="Campagne.id">
              {{Campagne.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>



<div class="row">
  <div class="col-6">
    <div class="form-group">

      <ng-container>
        <mat-form-field class="w-100">
          <mat-label>Cycle de vie</mat-label>
          <ng-container class="p-3" *ngIf="allCycleDevie?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #CycleDevie>
            <mat-chip *ngFor="let CycleDevie of ListCycleDevie" [selectable]="selectable" [removable]="removable"
              (removed)="removeCycleDevie(CycleDevie)" (removed)="EmitFilterOpportunite()">
              {{CycleDevie}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Cycle de vie..." #CycleDevieInput
              [formControl]="cycleDevieFromCtrl" [matAutocomplete]="autoCycleDevie" [matChipInputFor]="CycleDevie"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoCycleDevie="matAutocomplete" (optionSelected)="selectedCycleDevie($event)">
            <mat-option *ngFor="let CycleDevie of filteredcycleDevie | async" [value]="CycleDevie.id">
              {{CycleDevie.libele}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">

      <ng-container>
        <mat-form-field class="w-100">
          <mat-label>Classe</mat-label>
          <ng-container class="p-3" *ngIf="allClasse?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #Classe>
            <mat-chip *ngFor="let Classe of ListClasse" [selectable]="selectable" [removable]="removable"
              (removed)="removeClasse(Classe)" (removed)="EmitFilterOpportunite()">
              {{Classe}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Classe..." #classeInput
              [formControl]="classeFromCtrl" [matAutocomplete]="autoClasse" [matChipInputFor]="Classe"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoClasse="matAutocomplete" (optionSelected)="selectedClasse($event)">
            <mat-option *ngFor="let Classe of filteredClasse | async" [value]="Classe.id">
              {{Classe.libele}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<div class="row operateurContainer">
  <div class="col-2 d-flex justify-content-center align-items-center">
    <button (click)="selectOptionTag('=')" class="operateur mr-3"
      [ngClass]="{ 'active': filterOpportunite.desicion_contient === '=' }">
      <fa-icon [icon]="fa['faEquals']"></fa-icon>
    </button>
    <button (click)="selectOptionTag('!=')" class="operateur"
      [ngClass]="{ 'active': filterOpportunite.desicion_contient === '!=' }">
      <fa-icon [icon]="fa['faNotEqual']"></fa-icon>
    </button>

  </div>
  <div class="col-10 mt-2">
    <div class="ml-3">

      <ng-container>
        <mat-form-field class="w-100">
          <mat-label>Mot-clé</mat-label>
          <ng-container class="p-3" *ngIf="allTags?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #Tags>
            <mat-chip *ngFor="let Tags of ListTags" [selectable]="selectable" [removable]="removable"
              (removed)="removeTags(Tags)" (removed)="EmitFilterOpportunite()">
              {{Tags}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input placeholder="Selectionner Tags..." #tagsInput [formControl]="tagsFromCtrl"
              [matAutocomplete]="autoTags" [matChipInputFor]="Tags"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoTags="matAutocomplete" (optionSelected)="selectedTags($event)">
            <mat-option *ngFor="let Tags of filteredTags | async" [value]="Tags.id">
              {{Tags.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>
<!-- <div class="row">
    <div class="col-2">
        <div class="form-group">
            <label></label>
            <mat-form-field appearance="fill" class="operateur">
                <mat-select (selectionChange)="EmitFilterOpportunite()" [(ngModel)]="filterOpportunite.desicion_ne_contient_pas" placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-10">
        <div class="ml-5">

            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>{{'opportunities.label.tagsnotcontain' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allTags?.length === 0">
                        <div class="row ">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #TagsNeContientPas>
                        <mat-chip *ngFor="let Tags of ListTagsNeContientPas" [selectable]="selectable" [removable]="removable" (removed)="removeTagsNeContientPas(Tags)" (removed)="EmitFilterOpportunite()">
                            {{Tags}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Tags..." #tagsNeContientPasInput [formControl]="tagsNeContientPasFromCtrl" [matAutocomplete]="autoTagsNeContientPas" [matChipInputFor]="TagsNeContientPas" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTagsNeContientPas="matAutocomplete" (optionSelected)="selectedTagsNeContientPas($event)">
                        <mat-option *ngFor="let Tags of filteredTagsNeContientPas | async" [value]="Tags.id">
                            {{Tags.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div> -->
<div class="row">
  <div class="col-6">
    <div class="form-group">

      <ng-container>
        <mat-form-field class="w-100">
          <mat-label>{{'opportunities.label.opportunitytype' | translate}}</mat-label>
          <ng-container class="p-3" *ngIf="allTypeOpp?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #TypeOpp>
            <mat-chip *ngFor="let TypeOpp of ListTypeOpp" [selectable]="selectable" [removable]="removable"
              (removed)="removeTypeOpp(TypeOpp)" (removed)="EmitFilterOpportunite()">
              {{TypeOpp}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Type Opportunité..." #typeOppInput
              [formControl]="typeOppFromCtrl" [matAutocomplete]="autotypeOpp" [matChipInputFor]="TypeOpp"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autotypeOpp="matAutocomplete" (optionSelected)="selectedTypeOpp($event)">
            <mat-option *ngFor="let TypeOpp of filteredTypeOpp | async" [value]="TypeOpp.id">
              {{TypeOpp.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">

      <ng-container *ngIf="allTypeRelation?.length > 0">
        <mat-form-field class="w-100">
          <mat-label>{{'opportunities.label.relationtype' | translate}}</mat-label>
          <ng-container class="p-3" *ngIf="allTypeRelation?.length === 0">
            <div class="row ">
              <div class="text-center m-auto">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </ng-container>
          <mat-chip-list #TypeRelation>
            <mat-chip *ngFor="let TypeRelation of ListTypeRelation" [selectable]="selectable" [removable]="removable"
              (removed)="removeTypeRelation(TypeRelation)" (removed)="EmitFilterOpportunite()">
              {{TypeRelation}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterOpportunite()" placeholder="Selectionner Type Relation..." #typeRelationInput
              [formControl]="typeRelationFromCtrl" [matAutocomplete]="autoTypeRelation" [matChipInputFor]="TypeRelation"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoTypeRelation="matAutocomplete" (optionSelected)="selectedTypeRelation($event)">
            <mat-option *ngFor="let TypeRelation of filteredTypeRelation | async" [value]="TypeRelation.id">
              {{TypeRelation.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</div>

<div class="row" style="margin-top: -16px;">
  <div class="col-6">
    <div class="form-group">
      <label class="">Date de Création</label>
    </div>
  </div>

</div>
<div class="row align-items-center">
  <div class="col-6">
    <div class="form-group">
      <mat-form-field appearance="fill" class="w-100">
        <mat-date-range-input [rangePicker]="picker"  >
          <input matStartDate [(ngModel)]="date_creation" readonly placeholder="Du"  (click)="openDatePicker()" />
          <input matEndDate [(ngModel)]="date_fin" readonly placeholder="à" (click)="openDatePicker()"/>
          
        </mat-date-range-input>
        
        <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
          <img src="assets/icons/calendar.svg" > 
        </mat-icon></mat-datepicker-toggle>
        <img  class="remouveDate" matSuffix (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
        <mat-date-range-picker (closed)="EmitFilterOpportunite()" [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group d-flex ">
      <input [(ngModel)]="filterOpportunite.heure_creation" class="style-timePicker" aria-label="24hr format"
        [ngxTimepicker]="Debutcreation" [format]="24"  placeholder="Heure début">
      <span> - </span>
      <input [(ngModel)]="filterOpportunite.heure_fin" class="style-timePicker" aria-label="24hr format"
        placeholder="Heure fin " [ngxTimepicker]="fincreation" [format]="24" >
      <ngx-material-timepicker (closed)="EmitFilterOpportunite()" #Debutcreation></ngx-material-timepicker>
      <ngx-material-timepicker (closed)="EmitFilterOpportunite()" #fincreation></ngx-material-timepicker>
    </div>
  </div>
</div>



<!-- <div class="col-6">
        <div class="form-group">
            <input [(ngModel)]="filterOpportunite.heure_creation" class="style-timePicker" (closed)="EmitFilterOpportunite()" aria-label="24hr format" [ngxTimepicker]="Debutcreation" [format]="24" readonly placeholder="{{'opportunities.label.starttimecreation' | translate}}">
            <ngx-material-timepicker #Debutcreation></ngx-material-timepicker>
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <input [(ngModel)]="filterOpportunite.heure_fin" class="style-timePicker" aria-label="24hr format" [ngxTimepicker]="fincreation" [format]="24" readonly ">
            <ngx-material-timepicker (closed)="EmitFilterOpportunite()" #fincreation></ngx-material-timepicker>

        </div>
    </div> -->


<div class="row" style="margin-top: -4px;">

  <div class="col-6">
    <div class="form-group">
      <label>{{'opportunities.label.targetdate' | translate}}</label>
    </div>
  </div>

</div>
<div class="row align-items-center">
  <div class="col-6 w-100 ">
    <div class="form-group">
      <mat-form-field appearance="fill" class="w-100">
        <mat-datepicker-toggle  class="m-0 p-0"  matPrefix [for]="Objectif">    <mat-icon matDatepickerToggleIcon>
      <img src="assets/icons/calendar.svg" > 
        </mat-icon></mat-datepicker-toggle>
        <mat-date-range-input [rangePicker]="Objectif">
          <input matStartDate [(ngModel)]="date_debut_objectif" (click)="openDateObjectif()" readonly placeholder="Du" />
          <input matEndDate [(ngModel)]="date_fin_objectif" (click)="openDateObjectif()" readonly placeholder="à" />
        </mat-date-range-input>
        <img  class="remouveDate" matSuffix   (click)="clearDateRange()" src="assets/icons/delete-left.svg">
        <mat-date-range-picker (closed)="EmitFilterOpportunite()" [calendarHeaderComponent]="rangeDateObject" #Objectif></mat-date-range-picker>
      </mat-form-field>
    </div>

  </div>

  <div class="col-6">
    <div class="form-group d-flex ">
      <input [(ngModel)]="filterOpportunite.heure_debut_objectif" class="style-timePicker" aria-label="24hr format"
        [ngxTimepicker]="debutObjectif" [format]="24" readonly placeholder="Heure début">
      <span> - </span>
      <input [(ngModel)]="filterOpportunite.heure_fin_objectif" class="style-timePicker" aria-label="24hr format"
        placeholder="Heure fin " [ngxTimepicker]="finObjectif" [format]="24" readonly>
      <ngx-material-timepicker (closed)="EmitFilterOpportunite()" #debutObjectif></ngx-material-timepicker>
      <ngx-material-timepicker (closed)="EmitFilterOpportunite()" #finObjectif></ngx-material-timepicker>
    </div>
  </div>
</div>
