<div class="flex-column bg-white rounded-card">
    <div class="p-3 mb-3">
        <table id="ticketsTable" class="table">
            <ng-container *ngIf="show_loading_add">
                <div class="spinner-style d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </ng-container>
            <thead class="head-table">
                <tr>
                    <th> # </th>
                    <th>Categorie </th>
                    <th>Date Création</th>
                    <th>Date de traitement</th>
                    <th>Affaire</th>
                    <th>Opportunité</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody class="body-table">
                <tr *ngFor="let item of listTickets; let index = index">
                    <td>{{index+1}}</td>
                    <td> {{item.categorie}}</td>
                    <td> {{item.date_creation ? item.date_creation: '-'}}</td>
                    <td> {{item.date_traitement? item.date_traitement : '-'}}</td>
                    <td>

                        <a *ngIf="item.id_affaire" target="_blank" href="/affaires/details/{{item.id_affaire}}">
                            <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                            Affaire
                        </a>
                        <div *ngIf="!item.id_affaire"> -</div>
                    </td>
                    <td>
                        <a *ngIf="item.id_opp" target="_blank" href="/opportunities/details/{{item.id_opp}}">
                            <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                            Opportunité
                        </a>
                        <div *ngIf="!item.id_opp"> -</div>
                    </td>
                    <td> 
                        <div class="btn-status d-flex justify-content-center" [style.background]="item.statut_background" [style.color]="item.statut_color">{{item.statut}} </div>
                    </td>
                    <td>
                        <a type="button" class="btn-dt-edit" target="_blank" 
                        href="ticketsandsupport/details/{{item.id_ticket}}">
                            <img src="assets/icons/edit-icon.svg">
                        </a>
                    </td>
                </tr>

            </tbody>
          
        </table>
    </div>
</div>