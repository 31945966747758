import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef, SimpleChanges, Input, OnChanges, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import * as fa from '@fortawesome/free-solid-svg-icons';

import { Utilisateur } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { UserList } from 'src/app/entity/User/UserList';
@Component({
  selector: 'app-filter-utilisateurs-ticket',
  templateUrl: './filter-utilisateurs-ticket.component.html',
  styleUrls: ['./filter-utilisateurs-ticket.component.css']
})
export class FilterUtilisateursTicketComponent implements OnInit, OnChanges, OnDestroy {
  fa = fa;
  @Output() onPushFilterUtilisateur: EventEmitter<Utilisateur> = new EventEmitter();

  allListeCreateur: any[] = [];
  allListeAffecte: any[] = [];

  filteredCreateur: Observable<UserList[]>;
  ListCreateur: string[] = [];
  allCreateur: UserList[] = [];

  filteredAffecte: Observable<UserList[]>;
  ListAffecte: string[] = [];
  allAffecte: UserList[] = [];


  CrearteurFromCtrl: FormControl = new FormControl('', [Validators.required]);
  CreateurSelectionees = [];

  AffecteFromCtrl: FormControl = new FormControl('', [Validators.required]);
  AffecteSelectionees = [];

  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  createur: string = "";
  affectte: string = "";
  unsubscribeGetAllUsers: Subscription;
filtre : any

  @ViewChild('CreateurInput') CreateurInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoCreateur') matAutocompletetypeOpp: MatAutocomplete;


  @ViewChild('AffecteInput') AffecteInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoAffecte') matAutocompleteAffecte: MatAutocomplete;

  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  constructor(
    private apiUsersService: ApiUsersService,
    private chRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.unsubscribeGetAllUsers.unsubscribe();
  }

  // Createur -----------------------------------------------------------------------------------------------
  selectedCreateur(event: MatAutocompleteSelectedEvent): void {
    if (!this.CreateurSelectionees.includes(event.option.value)) {
    this.ListCreateur.push(event.option.viewValue);
    this.CreateurSelectionees.push(event.option.value);}
    this.EmitFilterUtilisateur()

    this.CreateurInput.nativeElement.value = '';
    this.CrearteurFromCtrl.setValue(null);
  }

  selectedAffecte(event: MatAutocompleteSelectedEvent): void {
    if (!this.AffecteSelectionees.includes(event.option.value)) {
    this.ListAffecte.push(event.option.viewValue);
    this.AffecteSelectionees.push(event.option.value);
    }
    this.AffecteInput.nativeElement.value = '';
    this.AffecteFromCtrl.setValue(null);
    this.EmitFilterUtilisateur()
  }

  removeCreateur(Createur: string): void {
    const index = this.ListCreateur.indexOf(Createur);
    if (index >= 0) {
      this.ListCreateur.splice(index, 1);
      this.CreateurSelectionees.splice(index, 1);
    }
  }


  removeAffecte(Affecte: string): void {
    const index = this.ListAffecte.indexOf(Affecte);
    if (index >= 0) {
      this.ListAffecte.splice(index, 1);
      this.AffecteSelectionees.splice(index, 1);
    }
  }

  private _filterCreateur(): UserList[] {
    let filterValue = this.CrearteurFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allCreateur.filter(option => (option.nom + ' ' + option.prenom).toLowerCase().includes(filterValue));
  }

  private _filterAffecte(): UserList[] {
    let filterValue = this.AffecteFromCtrl.value.toString().toLowerCase();
    if (filterValue == null) filterValue = '';
    return this.allAffecte.filter(option => (option.nom + ' ' + option.prenom).toLowerCase().includes(filterValue) );
  }


  ngOnInit(): void {
   
    this.unsubscribeGetAllUsers = this.apiUsersService.getAllUsers()
      .subscribe((data: UserList[]) => {
        this.allCreateur = this.allAffecte = data.filter(item => item.nom !== null);
        if (this.filtre) {
          if (this.Utilisateur?.recheche_create_user.length > 0) {
            this.ListCreateur = this.allCreateur
              .filter(createur => this.Utilisateur?.recheche_create_user.includes(createur.id))
              .map(createurAff => {
                this.CreateurSelectionees.push(createurAff.id);
                return createurAff.nom + " "+ createurAff.prenom;
              });
          }
          if (this.Utilisateur?.recheche_collab.length > 0) {
            this.ListAffecte = this.allAffecte
              .filter(affecte => this.Utilisateur?.recheche_collab.includes(affecte.id))
              .map(affecteAff => {
                this.AffecteSelectionees.push(affecteAff.id);
                return affecteAff.nom + " "+ affecteAff.prenom;
              });
          }}
          
      });
    // createur
    this.filteredCreateur = this.CrearteurFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Createur: string | null) => Createur ? this._filterCreateur() : this.allCreateur.slice()));
    // Affecte
    this.filteredAffecte = this.AffecteFromCtrl.valueChanges.pipe(
      startWith(null),
      map((Affecte: string | null) => Affecte ? this._filterAffecte() : this.allAffecte.slice()));
      this.filtre = localStorage.getItem('filtre');
      this.filtre =JSON.parse(this.filtre)
      if (this.filtre) {
        this.Utilisateur=this.filtre.utilisateur
        const allApi : Observable<any>[] = [
          this.apiUsersService.getAllUsers()
        ]
        forkJoin(allApi)
            .pipe(
              catchError(error => {
                console.error('Error:', error);
                return [];
              })
            )
            .subscribe(responses => {
              this.EmitFilterUtilisateur()
            });
      }
     
      
  }

  // Emit filter utilisayeur
  Utilisateur: Utilisateur = new Utilisateur();

  EmitFilterUtilisateur() {
    this.Utilisateur.recheche_create_user = this.CreateurSelectionees;
    this.Utilisateur.recheche_collab = this.AffecteSelectionees;
    this.onPushFilterUtilisateur.emit(this.Utilisateur);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetUtilisateurForm(changes.Reset.currentValue);
  }

  resetUtilisateurForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListAffecte = [];
      this.Utilisateur.recheche_collab = this.AffecteSelectionees = [];
      this.ListCreateur = [];
      this.Utilisateur.recheche_create_user = this.CreateurSelectionees = [];
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterUtilisateur.emit(this.Utilisateur);
    }
  }


}
