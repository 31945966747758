<div class="dropdown">
  <button class=" ml-2 btn-dt-saved-filter" type="button" id="dropdownMenuButton" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false" (click)="showhidePopfirst()">
    <!-- <fa-icon class="fa-lg" [icon]="fa['faFilter']"></fa-icon>&nbsp; -->
    <img id="filterImage" src="assets/icons/FavorieList.svg" class="filter-image">&nbsp;
    Recherches favories
  </button>
  <div class="dropdown-menu popup" aria-labelledby="dropdownMenuButton" [ngClass]="{'hidden': !isDropdownVisible}">

    <div class="popup-content" (click)="stopClickPropagation($event)">


      <div *ngIf="step=='1'">
        <div class="row mb-2">
          <span class="title"> Recherches favories</span>
          <button type="button" (click)="showhidePop()" class="close-button">&times;</button>
        </div>

        <div class="row pt-3  scrollable-container-filter  " id="style-1">
          <mat-spinner [diameter]="20" *ngIf="showLaoder"></mat-spinner>
          <ng-container *ngFor="let cardItem of listCollection?.collections; let i = index">
            <div class="col-md-6  mb-2">
              <div (click)="checkcollection(i)" (mouseenter)="setHoveredIndex(i)" (click)="oneditClick(cardItem)"
                (mouseleave)="setHoveredIndex(null)" class="card justify-content-start"
                [ngClass]="{ 'show-actions': i === currentHoveredIndex}">
                <div class="row card-row">
                  <div class="card-content col-12">
                    <div class="card-title pt-1">{{ expandcollectionName(cardItem.libelle) }}</div>
                    <div class="card-subtitle">{{ cardItem.nb_filtres }} Filtre(s)</div>
                    <div class="card-sub-subtitle pb-1">
                      <span *ngIf="cardItem.last_update.years!=0">actualisé il y a {{ cardItem.last_update.years }} année(s)</span>
                      <span *ngIf="cardItem.last_update.months!=0 && cardItem.last_update.years==0">actualisé il y a{{
                        cardItem.last_update.months }} mois</span>
                      <span
                        *ngIf="cardItem.last_update.days!=0 && cardItem.last_update.months==0 && cardItem.last_update.years==0">actualisé il y a{{
                        cardItem.last_update.days }} jour(s)</span>
                      <span
                        *ngIf="cardItem.last_update.hours!=0 && cardItem.last_update.days==0 && cardItem.last_update.months==0 && cardItem.last_update.years==0">actualisé il y a{{
                        cardItem.last_update.hours }} heure(s)</span>
                      <span
                        *ngIf="cardItem.last_update.minutes!=0 && cardItem.last_update.hours==0 && cardItem.last_update.days==0 && cardItem.last_update.months==0 && cardItem.last_update.years==0">actualisé il y a{{
                        cardItem.last_update.minutes }} minute(s)</span>

                        <span
                        *ngIf="cardItem.last_update.minutes==0 && cardItem.last_update.hours==0 && cardItem.last_update.days==0 && cardItem.last_update.months==0 && cardItem.last_update.years==0">A l'instant</span>
                    </div>
                  </div>

                </div>

              </div>

            </div>

          </ng-container>
        </div>
      </div>
      <div *ngIf="step=='2'">
        <div class="row mb-2">
          <fa-icon class=" fa-md" (click)="retourCollection()" [icon]="fa['faArrowLeft']"></fa-icon>
          <button type="button" (click)="showhidePop()" class="close-button">&times;</button>
        </div>
        <div class="row d-flex mb-2">
          <div class=" col-md-6">
            <label [ngStyle]="{ 'display': isEditable ? 'none' : 'inline-block' }" class="title"
              (dblclick)="makeEditable(checkedCollectionLibelle)">{{checkedCollectionLibelle}}</label>

            <input matInput [ngStyle]="{ 'display': !isEditable ? 'none' : 'inline-block' }"
              [(ngModel)]="collectionName" (blur)="makeNonEditable(checkedcollectionId)">

          </div>
          <div class=" col-md-6  d-flex justify-content-end">
            <button type="button" (click)="DeleteCollection(checkedcollectionId)" class="btn-dt-delete">
              <img src="assets/icons/trash-icon-complet.svg">
            </button>
          </div>
        </div>


        <div class="row col-md-12  p-3 scrollable-container-filter2  " id="style-1">
          <mat-spinner [diameter]="20" *ngIf="showLaoder1"></mat-spinner>
          <div *ngFor="let filterItem of ListFilterCollection; let i = index"
            class="row w-100 filter justify-content-start mb-1">
            <div (click)="filterlance(filterItem)"
              class="col-md-5 titreFiltre d-flex justify-content-center align-items-center">{{filterItem.libelle}}</div>
            <div (click)="filterlance(filterItem)"
              class="col-md-5 timefilter d-flex justify-content-center align-items-center">
               <span *ngIf="filterItem.date_creation.years!=0">ajouté il y a &nbsp; {{ filterItem.date_creation.years }} année(s)</span>
              <span *ngIf="filterItem.date_creation.months!=0 && filterItem.date_creation.years==0">ajouté il y a &nbsp; {{
                filterItem.date_creation.months }} mois</span>
              <span
                *ngIf="filterItem.date_creation.days!=0 && filterItem.date_creation.months==0 && filterItem.date_creation.years==0">ajouté il y a &nbsp; {{
                filterItem.date_creation.days }} jour(s)</span>
              <span
                *ngIf="filterItem.date_creation.hours!=0 && filterItem.date_creation.days==0 && filterItem.date_creation.months==0 && filterItem.date_creation.years==0">ajouté il y a &nbsp; {{
                filterItem.date_creation.hours }} heure(s)</span>
              <span
                *ngIf="filterItem.date_creation.minutes!=0 && filterItem.date_creation.hours==0 && filterItem.date_creation.days==0 && filterItem.date_creation.months==0 && filterItem.date_creation.years==0">ajouté il y a &nbsp; {{
                filterItem.date_creation.minutes }} minute(s)</span>
                <span
                *ngIf="filterItem.date_creation.minutes==0 && filterItem.date_creation.hours==0 && filterItem.date_creation.days==0 && filterItem.date_creation.months==0 && filterItem.date_creation.years==0">À l'instant
               </span>
              </div>
            <div (click)=" DeleteFilter(filterItem.libelle,checkedCollectionid)"
              class="col-md-2 actionfilter d-flex justify-content-center align-items-center"> <img
                src="assets/icons/delete-filter-icon.svg" /></div>
          </div>
        </div>
      </div>
    </div>


  </div>


</div>
