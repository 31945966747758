import { Component, OnInit } from '@angular/core'
import { AccessList } from './entity/accessList';
import { ApiAuthentificationService } from './services/ApiAuthentification/api-authentification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'geoprod-Front';
  accessListe : AccessList;

  constructor(    private apiAuthentificationService: ApiAuthentificationService,
    ){

  }

  ngOnInit(): void {
    const accessListObservable = this.apiAuthentificationService.GetAccessList();

    if (accessListObservable) {
      accessListObservable.subscribe((data) => {
        if (data) {
          this.accessListe = data;
          this.apiAuthentificationService.setAccessListe(this.accessListe);
        }
      });
    }
  }
}
