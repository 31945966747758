import { OpportunitiesDetailsComponent } from './../../opportunities-details.component';
import { Component, OnInit, OnChanges, AfterViewInit, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { SharedMenuObserverService } from '../../../../../services/SharedMenuObserver/shared-menu-observer.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Entreprise } from '../../../../../entity/Opportunity/Entreprise';
import { Store } from '@ngrx/store';
import * as selectors from '../../store';
import { Observable } from 'rxjs';
import { DetailsOpportuniteStore } from '../../store/details-opportunite.reducers';
import * as actions from '../../store/details-opportunite.actions';
import { DatePipe } from '@angular/common';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { EventEmiterService } from 'src/app/services/event.emmiter.service';

@Component({
  selector: 'app-prospect',
  templateUrl: './prospect.component.html',
  styleUrls: ['./prospect.component.css'],
})
export class ProspectComponent implements OnInit, AfterViewInit {
  fa = fa;
  entreprise$: Observable<any> | null = null;
  id_prospect: string = '';
  idOpportunite: string = '';
  allowed_to_update: boolean;
  isperson: boolean;
  constructor(
    private datePipe: DatePipe,
    private sharedMenuObserverService: SharedMenuObserverService,
    private store$: Store<DetailsOpportuniteStore>,
    private opportunitiesDetails: OpportunitiesDetailsComponent,
    private apiOpportunitService: ApiOpportunitService,
    private _eventEmiter: EventEmiterService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this._eventEmiter.getperson().subscribe((data) => {
      this.isperson = data;
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportunitService.onChangeAccesOpportunite().subscribe((value: boolean) => {
      this.allowed_to_update = value;
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.idOpportunite = this.opportunitiesDetails.opportunity_id;
    this.entreprise$ = this.store$.select(selectors.getEntrepriseFromDetailsOpportunite);
    this.sharedMenuObserverService.updateMenu('Opportunités ➜ Détails ➜ Prospect');
    this.entreprise$.subscribe((data) => {
      if (data?.mainProspect != null) this.id_prospect = data.mainProspect.id;
    });
  }

  NaviagateToFicheProspect(idOpp , idProspect) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/fiche-prospect/' + idOpp + '/'+idProspect + '/opportunite']));
     window.open(url, '_blank');
  }

  onUpdateEntreprise($event: Entreprise) {
    if (
      $event.mainProspect.name === '' ||
      $event.mainProspect.surname === '' ||
      $event.mainProspect.adresse_mail === ''
    ) {
      this._eventEmiter.seterrorUpdateProspect(true);
    } else {
      this._eventEmiter.seterrorUpdateProspect(false);
    }

    if ($event?.mainProspect?.id) {
      $event.mainProspect.DN = this.datePipe.transform($event.mainProspect.DN, 'yyyy-MM-dd');
      this.store$.dispatch(actions.UpdateProspectAction({ payload: $event }));
    } else {
      $event?.listProspectsLier.forEach((element) => {
        element.DN = this.datePipe.transform(element.DN, 'yyyy-MM-dd');
      });
      this.store$.dispatch(actions.UpdateProspectAction({ payload: $event }));
    }
  }

  resetEntreprise() {
    this.store$.dispatch(actions.ResetProspect());
  }
}
