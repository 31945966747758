import { DatePipe } from '@angular/common';
import { FilterMaileva } from './../../../entity/Maileva/FilterMaileva';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiComminucationService } from '../../../services/ApiComminucation/api-comminucation.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { MailevaElement } from '../../../entity/Maileva/MailevaElement';
import { MailEvaList } from '../../../entity/Maileva/MailEvaList';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import swal from 'sweetalert2';
import { isEqual, differenceWith } from 'lodash';

import { startWith, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { parseDate } from 'src/app/shared/Utils/sharedFunctions';
import * as moment from 'moment';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
@Component({
  selector: 'app-maileva',
  templateUrl: './maileva.component.html',
  styleUrls: ['./maileva.component.css'],
})
export class MailevaComponent implements OnInit, OnDestroy , AfterViewInit{
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  filtre_maileva: FilterMaileva = new FilterMaileva();
  filtre_mailevaInit: FilterMaileva = new FilterMaileva();
  @ViewChild('picker') picker: MatDateRangePicker<any>;
  readonly rangeDateCreation = RangeDateComponent;

  fa = fa;
  pages = 1;
  totalPages = 0;
  ActifPage: number = 1;
  listeOfUnsubscribeWebService: Subscription[] = [];
  maileva: MailEvaList = new MailEvaList();
  sort_field: number = 1;
  sort_type: string = 'desc';
  limit: number = 15;
  Page: number = 1;
  intervale = 1;
  status_filter: string = '';
  sSearch: string = '';
  search: string = '';
  selectedMaileva: MailevaElement = new MailevaElement();
  myDate = new Date();
  date_fin: any;
  date_debut: any;
  loading: boolean = false;
  statutList: any = {};
  unsubscribeGetDetailsLibellePost: Subscription;
  totalLength: number = 0;
  isEdit: boolean = false;
  filtre: any;
  date_finInit: Date;
  date_debutInit: Date;
  filterSsearch: boolean = false;
  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiComminucationService: ApiComminucationService,
    private apiAffairesService: ApiAffairesService,
    private router: Router,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private renderer: Renderer2

  ) {
    this.date_debut = new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate());
    this.date_fin = this.myDate;
    this.date_debutInit = new Date(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate())
    );
    this.date_finInit = this.myDate;
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    localStorage.removeItem('filtre');
    localStorage.removeItem('page');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('sort_type');
    localStorage.removeItem('sSearch');
  }
  collapseFromParent : boolean ;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;
      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event){
    this.collapseFromParent = event;
  }
  ngOnDestroy(): void {
    if (this.isEdit === false) {
      localStorage.removeItem('filtre');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');
      localStorage.removeItem('savedFilter');
      localStorage.removeItem('savedCollection');
    } else {
      localStorage.setItem('filtre', JSON.stringify(this.filtre_maileva));
      localStorage.setItem('sort_field', this.sort_field.toString());
      localStorage.setItem('sSearch', this.sSearch);
      localStorage.setItem('page', this.Page.toString());
    }

    this.listeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.showloader();
    this.sharedMenuObserverService.updateMenu('Maileva');
    const page = localStorage.getItem('page');
    const sort_fields = localStorage.getItem('sort_field');
    const sort_types = localStorage.getItem('sort_type');
    const search = localStorage.getItem('sSearch');
    this.date_debutInit = new Date(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate())
    );
    this.date_finInit = this.myDate;

    if (sort_fields !== null) {
      this.sort_field = parseInt(sort_fields);
    }
    if (sort_types !== null) {
      this.sort_type = sort_types;
    }

    if (page !== null) {
      this.Page = parseInt(page);
    }
    this.filtre = localStorage.getItem('filtre');
    this.filtre = JSON.parse(this.filtre);
    if (search) {
      this.sSearch = search;

      this.getListMaileva('search');
    } else if (this.filtre) {
      this.filtre_maileva = this.filtre;

      this.getListMaileva('');
    } else {
      this.getListMaileva('init');
    }

    this.getInformationDetailsLibelle();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) => option.toLowerCase().indexOf(filterValue) === 0);
  }

  getInformationDetailsLibelle() {
    this.unsubscribeGetDetailsLibellePost = this.apiComminucationService.GetDetailsLibellePost().subscribe((data) => {
      this.statutList = data;
    });
  }

  addValue(value) {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );

    if (value.length === 0) {
      this.options = [];
      if (this.sSearch.length > 0) {
        this.sSearch = '';
        this.first();
        this.getListMaileva('init');
      }
    } else {
      const unsubscribeGetListExpediteur = this.apiComminucationService
        .getListExpediteur(value)
        .pipe(
          tap(({ names }) => {
            this.options = names;
          })
        )
        .subscribe();
      this.listeOfUnsubscribeWebService.push(unsubscribeGetListExpediteur);
    }
  }

  clearDateCreation() {
    this.date_debut = null;
    this.date_fin = null;
  }

  openDatePicker() {
    if (this.picker) {
      this.picker.open();
    }
  }

  searchh(event) {
    if (event.target.value.length >= 3) {
      this.sSearch = event.target.value;
      this.filterSsearch = true;
      this.Page = 1;
      this.getListMaileva('search');
    } else if (event.target.value.length === 0) {
      this.Page = 1;
      this.sSearch = event.target.value;
      this.getListMaileva('init');
    }
  }

  isObjectEqual() {
    const differences = differenceWith([this.filtre_maileva, this.filtre_mailevaInit]);
    return isEqual(this.filtre_maileva, this.filtre_mailevaInit);
  }

  expandFileName(name: string): string {
    if (name?.length < 20) {
      return name;
    } else {
      return name?.substring(0, 20) + '...';
    }
  }

  getListMaileva(event) {
    this.showloader();
    this.loading = true;
    if (event === 'init') {
      this.Page = 1;
      this.filtre_maileva.courrier.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.filtre_maileva.courrier.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else if (event === 'submit') {
      this.sSearch = '';
      this.Page = 1;
      if (this.isObjectEqual()) {
        this.filtre_maileva.courrier.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.filtre_maileva.courrier.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else if (
        this.filtre_maileva.courrier.date_debut_creation &&
        this.filtre_maileva.courrier.date_fin_creation &&
        (this.filtre_maileva.courrier.date_debut_creation !==
          this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd') ||
          this.filtre_maileva.courrier.date_fin_creation !== this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd'))
      ) {
        this.date_debut = this.datePipe.transform(this.filtre_maileva.courrier.date_debut_creation, 'yyyy-MM-dd');
        this.date_fin = this.datePipe.transform(this.filtre_maileva.courrier.date_fin_creation, 'yyyy-MM-dd');
      } else {
        this.filtre_maileva.courrier.date_debut_creation = null;
        this.filtre_maileva.courrier.date_fin_creation = null;
      }
    } else if (event === 'maileva') {
      this.sSearch = '';
      this.Page = 1;

      if (this.isObjectEqual()) {
        this.filtre_maileva.courrier.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
        this.filtre_maileva.courrier.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
      } else {
        this.date_debut = this.datePipe.transform(this.filtre_maileva.courrier.date_debut_creation, 'yyyy-MM-dd');
        this.date_fin = this.datePipe.transform(this.filtre_maileva.courrier.date_fin_creation, 'yyyy-MM-dd');
      }
    } else if (event === 'reset') {
      this.Page = 1;
      this.sSearch = '';
      this.filtre_maileva = new FilterMaileva();
      this.filtre_maileva.courrier.date_debut_creation = this.datePipe.transform(this.date_debutInit, 'yyyy-MM-dd');
      this.filtre_maileva.courrier.date_fin_creation = this.datePipe.transform(this.date_finInit, 'yyyy-MM-dd');
    } else if (event === 'filtrer') {
      this.Page = 1;
      this.filtre_maileva.courrier.date_debut_creation = this.datePipe.transform(this.date_debut, 'yyyy-MM-dd');
      this.filtre_maileva.courrier.date_fin_creation = this.datePipe.transform(this.date_fin, 'yyyy-MM-dd');
    } else if (event === 'search') {
      this.router.navigate([], {
        queryParams: {
          filter: null,
          collection: null,
        },
        queryParamsHandling: 'merge',
      });
      this.filtre_maileva = new FilterMaileva();
      this.filtre_maileva.courrier.date_debut_creation = null;
      this.filtre_maileva.courrier.date_fin_creation = null;
    } else if (event === 'filterStatus') {
      this.sSearch = '';
      this.Page = 1;
      this.filtre_maileva.courrier.date_debut_creation = null;
      this.filtre_maileva.courrier.date_fin_creation = null;
    }
    // this.date_debut=this.datePipe.transform(this.filtre_maileva.courrier.date_debut_creation, 'yyyy-MM-dd');
    // this.date_fin =this.datePipe.transform(  this.filtre_maileva.courrier.date_fin_creation, 'yyyy-MM-dd');
    const unsubscribeListDetailsPostGET = this.apiComminucationService
      .listDetailsPostGET(
        this.Page,
        this.limit,
        this.sort_type,
        this.sort_field,
        this.filtre_maileva,
        this.status_filter,
        this.sSearch
      )
      .subscribe(
        (data: MailEvaList) => {
          this.date_debut = parseDate(data.min_date_depot);
          this.date_fin = parseDate(data.max_date_depot);
          this.filterSsearch = false;
          this.maileva = data;
          this.maileva.aaData.forEach((element) => {
            const datemaielva = parseDate(element.date);
            element.date = datemaielva.toString();
          });
          this.totalLength = data.iTotalDisplayRecords;
          this.calculate_nb_pages(data.iTotalDisplayRecords);
          this.loading = false;
          this.hideloader();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.hideloader();
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeListDetailsPostGET);
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.Page = this.intervale;
      if (this.Page <= this.totalPages) {
        this.getListMaileva('');
      }
    }
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  clickAffaire(id) {
    // href="/affaires/details/{{mail.id_affaire}}"
    this.getAffaireDetails(id);
    // this.router.navigate(["/affaires/details/"+id])
  }

  getAffaireDetails(affaire_id) {
    const unsubscribeGetAffaireGet = this.apiAffairesService
      .getAffaireGet(affaire_id)
      .pipe(
        tap(({ affaire, prospect }) => {
          const id_opportunite = affaire.id_opportunite;
          const prospect_id = prospect.id;
          const contrat_id = affaire.contrat.contrat_id;
        })
      )
      .subscribe(
        (data) => {
          this.hideloader();
          //  this.router.navigate(['/affaires/details/' + affaire_id]);
        },
        (error) => {
          console.log(error);
          this.alertWarning('Aucun affaire ne correspand à cet id');
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetAffaireGet);
  }

  clickOpportunite(id) {
    this.getOpportunite(id);
  }

  getOpportunite(dataid) {
    const unsubscribeGetOpportunite = this.apiOpportunitService.GetOpportunite(dataid).subscribe(
      (data) => {
        // this.router.navigate(['/opportunities/details/' + dataid]);
      },
      (error) => {
        console.log(error);
        this.alertWarning('Aucune opportunité ne correspand à cet id');
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetOpportunite);
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: '',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  setSortField(sort_field: number) {
    this.showloader();
    if (this.sort_field === sort_field) {
      this.toggleSortType();
    } else {
      this.sort_field = sort_field;
      this.sort_type = 'desc';
    }
    this.getListMaileva('');
  }

  setStatusFilter(status_filter: string) {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    this.showloader();
    this.sSearch = '';
    if (status_filter === 'total') {
      this.filtre_maileva.statut.etat_courrier = [];
    } else {
      this.statutList.forEach((element) => {
        if (element.libelle === status_filter) {
          this.filtre_maileva.statut.etat_courrier = [];
          this.filterSsearch = true;
          this.filtre_maileva.statut.etat_courrier.push(element.id);
        }
      });
    }

    this.getListMaileva('filterStatus');
  }

  onChangeLimit() {
    this.Page = 1;
    this.showloader();
    this.getListMaileva('statut');
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.Page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.Page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.Page = val;
    this.getListMaileva('');
  }

  isFirst(): boolean {
    return this.Page === 1;
  }

  isLast(): boolean {
    return this.Page === this.totalPages;
  }

  ChangePage(index: number) {
    this.Page = index;
    this.getListMaileva('');
  }

  checkSort(number: number) {
    if (this.sort_field === number && this.sort_type === 'asc') {
      return true;
    } else {
      return false;
    }
  }

  private toggleSortType() {
    if (this.sort_type === 'asc') {
      this.sort_type = 'desc';
    } else {
      this.sort_type = 'asc';
    }
  }

  select_to_preview(mail: MailevaElement) {
    this.selectedMaileva = mail;
  }

  format_date(date: string) {
    if (date) {
      const date_str = date.split(' ')[0].split('/');
      const datedeux = new Date(+date_str[2], +date_str[1] - 1, +date_str[0]);
      return datedeux;
    } else {
      return null;
    }
  }

  updateListMaileva() {
    this.Page = 1;
    this.getListMaileva('');
  }

  EmitFilters($event: FilterMaileva) {
    this.filtre_maileva = $event;
  }

  goToEdit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('filter') !== null && queryParams.get('collection') !== null) {
        localStorage.setItem('savedFilter', queryParams.get('filter'));
        localStorage.setItem('savedCollection', queryParams.get('collection'));
      }
    });
    this.isEdit = true;
  }
}
