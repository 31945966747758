import { ChangeDetectorRef, Component, Output, OnInit, ViewChild, EventEmitter, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { AffaireDetailsComponent } from '../affaire-details.component';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { HistoriquesAffaires } from '../../../../entity/Affaires/HistoriquesAffaires';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UniformiserDateToLocal } from 'src/app/shared/Utils/sharedFunctions';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-historique',
  templateUrl: './historique.component.html',
  styleUrls: ['./historique.component.css'],
})
export class HistoriqueComponent implements OnInit, OnDestroy, OnChanges {
  dataTable: any;
  fa = fa;
  // json_object: any;
  historiques: HistoriquesAffaires[] = [];
  public editorOptions: JsonEditorOptions;
  unsubscribeGetHistoriqueAffaires: Subscription;
  displayedColumns: string[] = ['date', 'user', 'commentaire', 'export'];
  dataSource: MatTableDataSource<HistoriquesAffaires>;
  @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() allowed_to_update_affaire: boolean;
  constructor(
    private affaireDetailsComponent: AffaireDetailsComponent,
    private apiAffairesService: ApiAffairesService,
    private chRef: ChangeDetectorRef
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['view', 'code', 'text']; // set all allowed modes
    this.editorOptions.mode = 'view'; //
    this.apiAffairesService.listendochistorique().subscribe((m: any) => {
      this.getHistorique();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getHistorique();
  }

  ngOnDestroy(): void {
    this.unsubscribeGetHistoriqueAffaires.unsubscribe();
  }

  ngOnInit(): void {
    this.getHistorique();
  }

  // getJson(json_object) {
  //   this.json_object = json_object;
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getHistorique() {
    this.unsubscribeGetHistoriqueAffaires = this.apiAffairesService
      .GetHistoriqueAffaires(this.affaireDetailsComponent.affaire_id)
      .subscribe((data: HistoriquesAffaires[]) => {
        this.historiques = data.reverse();
        this.historiques.forEach((element, index) => {
          this.historiques[index].date_action = UniformiserDateToLocal(element.date_action);
        });
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.chRef.detectChanges();
      });
  }

  changeDate(date: string) {
    return date.substr(0, 10) + ' ' + date.substr(11, 8);
  }
}
