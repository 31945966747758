<form (ngSubmit)="EnregistrerCompanie()" [formGroup]="myForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9" style="margin-top: 8px;">
                <p>
                    Compagnie n° :
                    <label class="label-card-info">
            {{initialCompagnie.siren}}
          </label>
                </p>
                <p>
                    Raison Sociale :
                    <label class="label-card-info">
            {{initialCompagnie.denomination}}
          </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p> Immatriculation :
                    <label class="label-card-info">
            {{initialCompagnie.matricule}}
          </label>
                </p>
                <p> Type :
                    <label class="label-card-info">
            {{initialCompagnie.types}}
          </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button class="btn-dt-retour" (click)="retourToList()" type="button">
        <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        Retour à la liste
      </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    <div class="col" style="margin-right: 10px;">
                        <button type="button" class="btn-load" (click)="clearForm()">
              <fa-icon [icon]="fa['faRedo']"></fa-icon>
            </button>
                    </div>
                </div>
                <button class="btn-dt-save" type="submit">
          <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
          Enregistrer
        </button>
            </div>
        </div>
    </div>
    <div class="card">

      <div class="row">
          <div class="col-12">
              <span style="color: red;">( * ) champs obligatoire!</span>
          </div>
      </div>
      <hr>
      <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
        <mat-tab label="Informations Compagnie">

            <div class="row mt-4">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group" *ngIf="ModeAjout">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Identifiant</mat-label>
                            <input type="text" matInput [(ngModel)]="Compagnie.siren" (keypress)="numberlength($event)" name="siren" placeholder="123 456 789" formControlName="siren" required>
                            <mat-error *ngIf="(myForm.get('siren').dirty || myForm.get('siren').touched)">
                              <div *ngIf="myForm.get('siren').hasError('required')">Identifiant est obligatoire!</div>
                              <div *ngIf="myForm.get('siren').hasError('pattern')">Identifiant invalide, doit contenir 9 chiffres</div>
                            </mat-error>
                          </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label>logo</label>
                    <div class="row">
                        <div class="col-2 pt-1 px-3 border-right-0 border border-primary">
                            <fa-icon class="fa fa-2x pt-3 pl-0" [icon]="fa['faImage']"></fa-icon>
                        </div>
                        <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                            <input type="file" id="idlogo" accept="image/*" (change)="getLogo($event)" hidden />
                            <label for="idlogo" class="select-label py-3 pl-4">
                  Sélectionner logo
                </label>
                        </div>
                        <div class="col-2 pt-3 pl-3 border-left-0 border border-primary">
                            <img class="img" data-toggle="modal" data-target="#Actions" src="{{show_image}}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Immatriculation</mat-label>
                            <input matInput [(ngModel)]="Compagnie.matricule" type="number" name="numo" formControlName="numo">
                            <mat-error *ngIf="(myForm.get('numo').dirty || myForm.get('numo').touched)">
                              <div *ngIf="myForm.get('numo').hasError('required')">Immatriculation est obligatoire!</div>
                            </mat-error>
                          </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Raison sociale</mat-label>
                            <input type="text" matInput [(ngModel)]="Compagnie.denomination" name="raison" formControlName="raison" required>
                            <mat-error *ngIf="(myForm.get('raison').dirty || myForm.get('raison').touched) ">
                                <div *ngIf="myForm.get('raison').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    La Raison sociale est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Type</mat-label>
                            <input matInput [(ngModel)]="Compagnie.types" formControlName="type" name="type" required>
                            <mat-error *ngIf="(myForm.get('type').dirty || myForm.get('type').touched) ">
                                <div *ngIf="myForm.get('type').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Type est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">

                        <app-auto-complete-adresses [reset]="reset"  [isReadOnlyAdresse]="true" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                        </app-auto-complete-adresses>
                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Code Postal</mat-label>
                            <input type="number" matInput [(ngModel)]="Compagnie.cp" formControlName="cp" name="cp">
                            <mat-error *ngIf="(myForm.get('cp').dirty || myForm.get('cp').touched) ">
                                <div *ngIf="myForm.get('cp').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Code Postal est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Ville</mat-label>
                            <input matInput [(ngModel)]="Compagnie.ville" formControlName="ville" name="ville">
                            <mat-error *ngIf="(myForm.get('ville').dirty || myForm.get('ville').touched) ">
                                <div *ngIf="myForm.get('ville').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    La Ville est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Rue</mat-label>
                            <input matInput [(ngModel)]="Compagnie.streetName" formControlName="streetName" name="streetName">
                            <mat-error *ngIf="(myForm.get('streetName').dirty || myForm.get('streetName').touched) ">
                                <div *ngIf="myForm.get('streetName').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    La rue est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>N° de rue</mat-label>
                            <input matInput [(ngModel)]="Compagnie.streetNumber" formControlName="streetNumber" name="streetNumber">
                            <mat-error *ngIf="(myForm.get('streetNumber').dirty || myForm.get('streetNumber').touched) ">
                                <div *ngIf="myForm.get('streetNumber').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le numero rue est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Lien</mat-label>
                            <input matInput [(ngModel)]="Compagnie.url" formControlName="url"  name="url">
                            <mat-error *ngIf="(myForm.get('url').dirty || myForm.get('url').touched) ">
                                <div *ngIf="myForm.get('url').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Lien est obligatoire!
                                </div>
                                <div *ngIf="myForm.get('url').hasError('pattern')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format invalide ! (exemple : https://...)
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div class="row">


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Email de production</mat-label>
                            <input type="email" placeholder="exemple@email.com" matInput [(ngModel)]="Compagnie.mail_production" formControlName="mail_production"  name="mail_production">
                            <mat-error *ngIf="(myForm.get('mail_production').dirty || myForm.get('mail_production').touched) ">
                                <div *ngIf="myForm.get('mail_production').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    L'Email de production est obligatoire!
                                </div>
                                <div *ngIf="myForm.get('mail_production').hasError('email')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format Email invalide!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>Email commercial</mat-label>
                            <input type="email" matInput placeholder="exemple@email.com" [(ngModel)]="Compagnie.mail_commercial"  name="mail_commercial" formControlName="mail_commercial" >
                            <mat-error *ngIf="(myForm.get('mail_commercial').dirty || myForm.get('mail_commercial').touched) ">
                                <div *ngIf="myForm.get('mail_commercial').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    L'Email commercial est obligatoire!
                                </div>
                                <div *ngIf="myForm.get('mail_commercial').hasError('email')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format Email invalide!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width" style="margin-top: -6px;">
                            <mat-label>Email SAV</mat-label>
                            <input type="email" matInput placeholder="exemple@email.com"  [(ngModel)]="Compagnie.mail_sav" name="mail_sav" formControlName="mail_sav" >
                            <mat-error *ngIf="(myForm.get('mail_sav').dirty || myForm.get('mail_sav').touched) ">
                                <div *ngIf="myForm.get('mail_sav').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    L'Email SAV est obligatoire!
                                </div>
                                <div *ngIf="myForm.get('mail_sav').hasError('email')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Format Email invalide!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div class="row">



                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label>Téléphone de production </label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_production" name="tel_production" formControlName="tel_production"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telProd')">
                            <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                            <mat-error *ngIf="(myForm.get('tel_production').dirty || myForm.get('tel_production').touched) ">
                                <div *ngIf="myForm.get('tel_production').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Téléphone de Production est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label>Téléphone SAV</label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_sav" name="tel_sav" formControlName="tel_sav"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telsav')">
                            <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                            <mat-error *ngIf="(myForm.get('tel_sav').dirty || myForm.get('tel_sav').touched) ">
                                <div *ngIf="myForm.get('tel_sav').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Téléphone SAV est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label>Téléphone commercial</label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_commercial" name="tel_commercial" formControlName="tel_commercial"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telcomm')">
                            <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                            <mat-error *ngIf="(myForm.get('tel_commercial').dirty || myForm.get('tel_commercial').touched) ">
                                <div *ngIf="myForm.get('tel_commercial').hasError('required')">
                                    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                    Le Téléphone Commercial est obligatoire!
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>

        </mat-tab>
        <mat-tab label="Formulaire">

            <div class="mt-3">
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
              </div>
        </mat-tab>
      </mat-tab-group>
    </div>


    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content full-width">
                <div class="modal-header">
                    <h2 style="margin-left: 15px">Logo du Compagnie</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center;">
                        <img class="img" style="width: 200px; height: 200px;" src="{{show_image}}">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</form>
