<div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
      <button type="button" class="btn-dt-add my-4 ml-4"  data-title="Ajouter une garantie" (click)=" goTofiche()">
      <fa-icon class="icon-btn-add" [icon]="fa['faPlus']"></fa-icon> 
      </button>
    </div>
    <div class="p-3 mb-3" >
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
           
            <th> Icône</th>
            <th> Libelle</th>
            <th style="white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 200px;
            padding: 20px;
            margin: 0;
            resize: horizontal;" > Description</th>
          
          <th> Action</th>

          </tr>
        </thead>
        <tbody class="body-table">
   
          <tr  *ngFor="let item of garantie; let index = index">
        
            <td >
              <fa-icon *ngIf="item.icon !== null && item.icon !== '' && item.icon !== 'fa'&& item.icon !== 'None'" [icon]="fa[item.icon]"></fa-icon>
              <p *ngIf="item.icon =='fa'">-</p>
             </td>
            <td>{{item.libelle}}</td>
            <td > {{item.description ? expandFileName(item.description) :"-" }}</td>
            
            <td>
              <button type="button" class="btn-dt-list"(click)="goToDetails(item) ">
              <img src="/assets/icons/edit-icon.svg" >
            </button>
          </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
