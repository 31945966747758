<div class="modal-content">
    <div class="modal-header">

        <h3 class="text-center title justify-content-center m-0 w-100">Ajouter une affaire</h3>
        <ng-container *ngIf="show_loading_add">
            <div>
                <div class="col-12 text-center">
                    <div style="width: min-content; margin: 6px; margin-bottom: 10px;">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
            </div>
        </ng-container> 
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md-6 mt-3">
                <div class="form-group">
                    <mat-form-field class="example-full-width w-100">
                        <mat-label> Id Opportunités</mat-label>

                        <input matInput placeholder="Id Opportunités" [(ngModel)]="Id_opp" name="nom" required>
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-6 d-flex justify-content-center mt-1">
                <mat-spinner *ngIf="listAffect.length==0" [diameter]="20"></mat-spinner>
                <mat-form-field *ngIf="listAffect.length!=0" class="full-width" appearance="standard">
                    <mat-label> Commercial</mat-label>

                    <input type="text" required matInput [formControl]="affecterAFormControl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption [displayWith]="displayContactFn" #auto="matAutocomplete">
                        <mat-option *ngFor="let option of listCommerciauxFilter | async" [value]="option">
                            {{option.nom + ' ' + option.prenom}}
                        </mat-option>
                    </mat-autocomplete>

                    <mat-error
                        *ngIf="affecterAFormControl.invalid && (affecterAFormControl.dirty || affecterAFormControl.touched) "
                        class="message-erreur">
                        <div *ngIf="affecterAFormControl.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Commercial obligatoire!
                        </div>
                    </mat-error>

                    <mat-error class="message-erreur"
                        *ngIf="affecterAFormControl.hasError('invalidAutocompleteObject') &&  !affecterAFormControl.hasError('required')">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le Commercial
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-spinner *ngIf="listLigneProd.length==0" [diameter]="20"></mat-spinner>
                <mat-form-field *ngIf="listLigneProd.length!=0" class="full-width" appearance="standard">
                    <mat-label>Ligne de produit</mat-label>
                    <input type="text" required matInput [formControl]="affecterLigneAFormControl" name="type"
                        [matAutocomplete]="autoLigne">
                    <mat-autocomplete (optionSelected)="selectContrat($event.option.value)" autoActiveFirstOption
                        [displayWith]="displayLingeFn" #autoLigne="matAutocomplete">
                        <mat-option *ngFor="let option of listLignedePorduitFilter | async" [value]="option">
                            {{option.type}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error
                        *ngIf="affecterLigneAFormControl.invalid && (affecterLigneAFormControl.dirty || affecterLigneAFormControl.touched) "
                        class="message-erreur">
                        <div *ngIf="affecterLigneAFormControl.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Ligne de produit obligatoire!
                        </div>
                    </mat-error>

                    <mat-error class="message-erreur"
                        *ngIf="affecterLigneAFormControl.hasError('invalidAutocompleteObject') &&  !affecterLigneAFormControl.hasError('required')">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le ligne de produit
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <div class="form-group">

                    <mat-spinner *ngIf="Compagnie.length==0" [diameter]="20"></mat-spinner>
                    <mat-form-field *ngIf="Compagnie.length!=0" class="full-width" appearance="standard">
                        <mat-label>Compagnie</mat-label>
                        <input type="text" required matInput [formControl]="affecterACompagnie" name="compagnie"
                            [matAutocomplete]="autoCompagnie">
                        <mat-autocomplete (optionSelected)="selectCompagnie($event.option.value)"
                            [displayWith]="displayCompagnieFn" autoActiveFirstOption #autoCompagnie="matAutocomplete">
                            <mat-option *ngFor="let option of listCompagnieFilter | async" [value]="option">
                                {{option.denomination}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error
                            *ngIf="affecterACompagnie.invalid && (affecterACompagnie.dirty || affecterACompagnie.touched) "
                            class="message-erreur">
                            <div *ngIf="affecterACompagnie.hasError('required')">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Compagnie est obligatoire!
                            </div>
                        </mat-error>

                        <mat-error class="message-erreur"
                            *ngIf="affecterACompagnie.hasError('invalidAutocompleteObject') &&  !affecterACompagnie.hasError('required')">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le Compagnie
                        </mat-error>
                    </mat-form-field>

                </div>
            </div>
            <div class="col-md-6">

                <ng-container *ngIf="show_loading_gamme">
                    <div>
                        <div class="col-12 text-center">
                            <div style="width: min-content; margin: 6px; margin-bottom: 10px;">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="!show_loading_gamme" class="form-group">


                    <mat-form-field class="full-width" appearance="standard">

                        <mat-label>Gamme</mat-label>
                        <input type="text" [readonly]="!disabledlist" required matInput [formControl]="affecterAGamme"
                            name="gamme" [matAutocomplete]="autoGamme">

                        <mat-autocomplete (optionSelected)="selectGamme($event.option.value)"
                            [displayWith]="displayGammeFn" autoActiveFirstOption #autoGamme="matAutocomplete">
                            <mat-option *ngFor="let option of listGammeFilter | async" [value]="option">
                                {{option.libelle}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error *ngIf="affecterAGamme.invalid && (affecterAGamme.dirty || affecterAGamme.touched)"
                            class="message-erreur">
                            <div *ngIf="affecterAGamme.hasError('required')&& gammeAppear">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Gamme est obligatoire!
                            </div>
                            <div *ngIf="!gammeAppear">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Pas de gamme correspond a cette compagnie !
                            </div>
                        </mat-error>

                        <mat-error class="message-erreur"
                            *ngIf="affecterAGamme.hasError('invalidAutocompleteObject') &&  !affecterAGamme.hasError('required') && gammeAppear">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier la gamme
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>



            <div class="col-md-6">
                <div *ngIf="!show_loading_produit" class="form-group">

                    <mat-form-field class="full-width" appearance="standard">

                        <mat-label>Produit</mat-label>
                        <input type="text" [readonly]="!disabledlistproduit" required matInput
                            [formControl]="affecterAproduit" name="produit" [matAutocomplete]="autoProduit">

                        <mat-autocomplete [displayWith]="displayProduitFn" autoActiveFirstOption
                            #autoProduit="matAutocomplete">
                            <mat-option *ngFor="let option of listProduitFilter | async" [value]="option">
                                {{option.libelle}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-error
                            *ngIf="affecterAproduit.invalid && (affecterAproduit.dirty || affecterAproduit.touched)"
                            class="message-erreur">
                            <div *ngIf="affecterAproduit.hasError('required') && produitAppear">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Produit est obligatoire!
                            </div>
                            <div *ngIf="!produitAppear">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Pas de produit correspond a cette gamme!
                            </div>

                        </mat-error>

                        <mat-error class="message-erreur"
                            *ngIf="affecterAproduit.hasError('invalidAutocompleteObject') &&  !affecterAproduit.hasError('required') && produitAppear">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon> Merci de vérfier le produit
                        </mat-error>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="show_loading_produit">
                    <div>
                        <div class="col-12 text-center">
                            <div style="width: min-content; margin: 6px; margin-bottom: 10px;">
                                <mat-spinner [diameter]="20"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <!--   <div class="col-md-6">
                                <div  class="form-group" >

                                    <mat-form-field  class="full-width" appearance="standard">

                                      <mat-label>Statut</mat-label>
                                      <input type="text"
                                      required matInput
                                      [formControl]="affecterStatut"
                                       name="Statut" [matAutocomplete]="autoStatut" >
                                      <mat-autocomplete  [displayWith]="displayStatutFn"  autoActiveFirstOption
                                         #autoStatut="matAutocomplete">
                                          <mat-option *ngFor="let option of listStatutFilter | async" [value]="option">
                                              {{option.etat}}
                                          </mat-option>
                                      </mat-autocomplete>

                                      <mat-error
                                      *ngIf="affecterStatut.invalid && (affecterStatut.dirty || affecterStatut.touched)"
                                      class="message-erreur">
                                       <div *ngIf="affecterStatut.hasError('required')&& StatutDepart">
                                          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                          Statut est obligatoire!
                                        </div>
                                        <div *ngIf="!StatutDepart">
                                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                            Ce ligne de produit n'a pas de statut !
                                          </div>
                                    </mat-error>

                                    </mat-form-field>
                                  </div>
                                  <ng-container *ngIf="show_loading_statut">
                                    <div>
                                        <div class="col-12 text-center">
                                            <div style="width: min-content; margin: 6px; margin-bottom: 10px;">
                                                <mat-spinner [diameter]="20"></mat-spinner>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div> -->
            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput [matDatepicker]="effet" [(ngModel)]="datedeffet" placeholder="Date d'éffet" required
                        #date="ngModel" readonly (click)="effet.open()" name="date">
                    <mat-datepicker-toggle matSuffix [for]="effet"></mat-datepicker-toggle>
                    <mat-datepicker #effet>
                    </mat-datepicker>
                </mat-form-field>
                <div *ngIf="date.invalid && (date.dirty || date.touched)" class="message-erreur">
                    <div *ngIf="date.errors.required">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                        Date d'effet est obligatoire!
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="fill" class="w-100">
                    <input matInput [matDatepicker]="souscription" [(ngModel)]="datedsous"
                        placeholder="Date de souscription" required #datesous="ngModel" readonly
                        (click)="souscription.open()" name="datesous">
                    <mat-datepicker-toggle matSuffix [for]="souscription"></mat-datepicker-toggle>
                    <mat-datepicker #souscription></mat-datepicker>
                </mat-form-field>
                <div *ngIf="date.invalid && (datesous.dirty || datesous.touched)" class="message-erreur">
                    <div *ngIf="datesous.errors.required">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                        Date de souscription est obligatoire!
                    </div>
                </div>
            </div>




            <div class="col-12">
                <h3 class="mt-2" *ngIf="selectedStatuss">Statut sélectionné: <span
                        style="font-size: 20px; margin-left: 15px">
                        <b>{{selectedStatuss.etat}}</b></span>
                </h3>

                <p *ngIf="tree.length >0">Selectionner le statut de départ:</p>

                <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
                    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                        <li class="mat-tree-node">
                            <!-- <button mat-icon-button></button>
                            <mat-checkbox color="primary" class="checklist-leaf-node" [checked]="selectedStatuss.id==node.id" (change)="todoLeafItemSelectionToggle(node)">
                                {{node.item}}</mat-checkbox> -->

                            <mat-radio-button name="libelle_cat_parent" [checked]="node?.id == selectedStatuss?.id"
                                (change)="todoLeafItemSelectionToggle(node)">{{node?.item}}</mat-radio-button>
                        </li>
                    </mat-tree-node>
                    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                        <li>
                            <div class="mat-tree-node">
                                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button> {{node.item}}
                            </div>
                            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                                <ng-container matTreeNodeOutlet></ng-container>
                            </ul>
                        </li>
                    </mat-nested-tree-node>
                </mat-tree>


            </div>
        </div>
        <div class="row  d-flex justify-content-center aligh-items-center mt-2">
            <div class="col-3">
                <button type="button" class="btn-dt-cancel-popup float-right" #closebuttonAffaire aria-label="Close"
                    (click)="activeModal.dismiss('Cross click')">
                    <fa-icon class="fa-icon-style" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                    Annuler
                </button>
            </div>
            <div class="col-3">
                <button type="submit" class="btn-dt-save-popup" (click)="SaveAffaire()">
                    <fa-icon class="fa-icon-style" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                    Enregistrer
                </button>
            </div>
        </div>

    </div>
</div>
