<div class="row w-100" *ngIf="modeAjout">
    <div  class="col-2  d-flex flex-column align-items-end ml-auto" >
        <div class="d-flex ">
            <mat-spinner class="mr-4 mt-1" [diameter]="25" *ngIf="loaderInfo" ></mat-spinner>
           <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
               aria-expanded="false" (click)="showhidePopfirst()" mat-button type="button"
               class="add-btn-searchOpp" style="border-radius: 15px;">
               <fa-icon class="fa fa-lg fa-flip-horizontal" [icon]="fa['faSearch']"></fa-icon>
               &nbsp;&nbsp;
               Rechercher par&nbsp;&nbsp;

           </button>
     
        <div class="dropdown-menu popup dropdown-menu-right" aria-labelledby="dropdownMenuButton"
            [ngClass]="{'hidden': !isDropdownVisible}"  >
            <div class="popup-content" (click)="stopClickPropagation($event)">
                <div class="row mb-2">
                    <button type="button" (click)="showhidePop()"
                        class="close-button">&times;</button>
                </div>
                <div class="row mt-1"
                    *ngFor="let form of formInfoHolder ;let index = index; let isFirst = first;">
                    <div class="input-group  col-10">
                        <select class="custom-select"  name="key{{index}}" id="key{{index}}" [(ngModel)]="formInfoHolder[index].key"   (ngModelChange)="setValues(index , 'key', $event)" >
                            <option [disabled]="item.disabled" value="{{item.name}}" *ngFor="let item of listkeys">{{item.title}}</option>
                        </select>
                            <select class="custom-select" name="op{{index}}" id="op{{index}}" [(ngModel)]="formInfoHolder[index].operateur" (ngModelChange)="setValues(index , 'operateur ', $event)" >
                                <option value="=">Egale</option>
                                <!-- <option value="!=">Different</option> -->
                            </select>
                        <input type="text" aria-label="Last name"
                            placeholder="Entrer une valeur" class="form-control "  name="val{{index}}" id="val{{index}}"
                            (change)="setValues(index , 'value', $event)"  />
                    </div>
                    <div class="col-2  d-flex align-items-center justify-content-start " *ngIf="isFirst">
                        <button type="button" class="btn-dt-add "
                            (click)="onAdd()">
                            <fa-icon 
                                [icon]="fa['faPlus']" ></fa-icon>
                        </button>
                    </div>
                    <div class="col-2  d-flex align-items-center justify-content-start" *ngIf="!isFirst">
                        <button type="button" class=" cancelBtn float-left"
                            (click)="deletePrspect(index)">
                            <img src="assets/icons/closeIcon.svg" class="iconClose">
                        </button>
                    </div>
                </div>
                <!--          <mat-form-field class="w-100 mb-3">
                        <input matInput id="path{{index}}" name="path{{index}}"
                            [ngModel]="formInfoHolder[index]"
                            (change)="inputChange($event.target.value, index)" >
                       
                    </mat-form-field> -->

                <div class="row w-100 d-flex justify-content-center mt-4">
                    <div class=" mx-4  text-right ">
                        <button (click)=" OnOpen()" class=" btn-dt-save-popup" data-toggle="modal" data-target="#listProspect" type="button">
                            <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>
                            Chercher
                        </button>
                        
                    </div>
                    <div class=" mx-4 text-right ">
                        <button class="btn-dt-cancel-popup" type="button" (click)="clearModal()">
                            <fa-icon class="fa-lg"
                                [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                            Annuler
                        </button>
                    </div>
            
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<div class="row" >
    
    <div class="col-12" [ngClass]="{'cover': !allowed_to_update}">

        <div class="row">

            <!--Identité-->

                <div class="prospect-card-field" [ngClass]="!isfilled && loaderinfo==false ?  'col-xl-12 col-lg-12 ': 'col-xl-6 col-lg-12 '">
                    <form [formGroup]="prospectForm">
                        <div class="form-group-container">
                            <p class="form-group-container-title"> Identité </p>

                            <div class="row">
                                <!--Civilité-->
                                <div class="col-xl-3 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Civilité</mat-label>
                                        <mat-select (selectionChange)="updateProspect()" formControlName="civilite">
                                            <mat-option value="Mr">Mr</mat-option>
                                            <mat-option value="Mme">Mme</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="prospectForm.controls['civilite'].invalid && (prospectForm.controls['civilite'].dirty || prospectForm.controls['civilite'].touched)">
                                        <div *ngIf="prospectForm.controls['civilite'].errors?.required">
                                            Civilité obligatoire !
                                        </div>
                                    </div>
                                </div>
                                <!--Nom-->
                                <div class="col-xl-5 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Nom </mat-label>
                                        <input matInput type="text" required formControlName="name" (change)="updateProspect()" autocomplete="off">
                                        <fa-icon matSuffix [icon]="fa['faUser']"></fa-icon>
                                        <mat-error *ngIf="prospectForm.controls['name'].invalid && (prospectForm.controls['name'].dirty || prospectForm.controls['name'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['name'].errors?.required">
                                                Nom obligatoire !
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Prénom-->
                                <div class="col-xl-4 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Prénom</mat-label>
                                        <input matInput type="text" required formControlName="surname" (change)="updateProspect()" autocomplete="off">
                                        <fa-icon matSuffix [icon]="fa['faUser']"></fa-icon>
                                        <mat-error *ngIf="prospectForm.controls['surname'].invalid && (prospectForm.controls['surname'].dirty || prospectForm.controls['surname'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['surname'].errors?.required">
                                                Prénom obligatoire !
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Relation (particulier)-->
                                <div *ngIf="type==0 && !isMainProspect" class="col-12">
                                    <div class="form-group">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Relation</mat-label>
                                            <mat-select (selectionChange)="updateProspect()" formControlName="relation" required>
                                                <mat-option value="Conjoint">Conjoint</mat-option>
                                                <mat-option value="Ascendant">Ascendant</mat-option>
                                                <mat-option value="Descendant">Descendant</mat-option>
                                                <mat-option value="Parrainage">Parrainage</mat-option>
                                                <mat-option value="Amitié">Amitié</mat-option>
                                                <mat-option value="Frère-Soeur">Frère-Soeur</mat-option>
                                                <mat-option value="Famille-autre">Famille-autre</mat-option>
                                                <mat-option value="Collègues">Collègues</mat-option>
                                                <mat-option value="Doublon">Doublon</mat-option>
                                                <mat-option value="Colocataires">Colocataires</mat-option>
                                                <mat-option value="Famille-Autre">Famille-Autre</mat-option>
                                                <mat-option value="Voisinage">Voisinage</mat-option>
                                                <mat-option value="coupe" *ngIf="modeupdateProspect">Coupé</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="prospectForm.controls['relation'].invalid && (prospectForm.controls['relation'].dirty || prospectForm.controls['relation'].touched)">
                                                <mat-error *ngIf="prospectForm.controls['relation'].errors?.required">
                                                    Relation obligatoire !
                                                </mat-error>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <!--Poste/Fonction (pro)-->
                                <div *ngIf="type==1" class="col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Poste/Fonction</mat-label>
                                        <input matInput type="text" formControlName="post" (change)="updateProspect()" autocomplete="off">
                                        <mat-error *ngIf="prospectForm.controls['post'].invalid && (prospectForm.controls['post'].dirty || prospectForm.controls['post'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['post'].errors?.required">
                                                Fonction obligatoire !
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Date de Naissance-->

                                    <div class="col-xl-6 col-lg-12">
                                        <mat-form-field class="full-width" appearance="standard">

                                            <mat-label>Date de Naissance</mat-label>
                                            <input placeholder="jj/mm/aaaa" (click)="picker.open()" (dateChange)="updateProspect()" matInput [matDatepicker]="picker" [max]="dateNow" formControlName="DN">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>

                                    </div>
                                    <!--     <div class="col-xl-6 col-lg-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>{{countryLanguage.PROSPECT.SOCIAL_SECURITY.LABEL}}</mat-label>
                                        <input matInput type="text" formControlName="securite_sociale" name="securite_sociale" (change)="updateProspect()" autocomplete="off">
                                        <mat-error *ngIf="prospectForm.controls['securite_sociale'].invalid && (prospectForm.controls['securite_sociale'].dirty || prospectForm.controls['securite_sociale'].touched)">
                                            <mat-error *ngIf="prospectForm.controls['securite_sociale'].errors?.pattern">
                                                {{countryLanguage.PROSPECT.SOCIAL_SECURITY.LABEL}} invalide doit contenir {{countryLanguage.PROSPECT.SOCIAL_SECURITY.PLACEHOLDER.length}} chiffres
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div> -->
                                    <!--Situation Familiale-->
                                    <div class="col-xl-6 col-lg-12">
                                        <mat-form-field class="full-width" appearance="standard">
                                            <mat-label>Situation Familiale</mat-label>
                                            <mat-select (selectionChange)="updateProspect()" formControlName="situation">
                                                <mat-option value="N.R">N.R</mat-option>
                                                <mat-option value="Marié(e)">Marié(e)</mat-option>
                                                <mat-option value="Divorcé(e)">Divorcé(e)</mat-option>
                                                <!-- <mat-option value="Pacsé(e)">Pacsé(e)</mat-option> -->
                                                <mat-option value="Célibataire">Célibataire</mat-option>
                                                <mat-option value="Veuf/Veuve">Veuf/Veuve</mat-option>
                                                <mat-option value="Concubinage">Concubinage</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                    </div>

                                <!--Nombre d'Enfants-->
                                <div class="col-12">
                                    <mat-form-field class="full-width" appearance="standard">
                                        <mat-label>Nombre d'Enfants</mat-label>
                                        <input (keypress)="numberOnly($event)" (change)="updateProspect()" min="0" type="number" max="11" matInput placeholder="0" formControlName="nb_enfants">
                                        <fa-icon matSuffix [icon]="fa['faUsers']" style="margin-left: 10px;"></fa-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </form>
                 </div>

            <!--info complementaires-->
            <div  *ngIf="loaderinfo" class="col-xl-6 col-lg-12 prospect-card-field" style="display: flex; justify-content: center; align-items: center;">
                <mat-spinner *ngIf="loaderinfo" [diameter]="25"></mat-spinner>
                </div>
            <div  *ngIf="isfilled && loaderinfo==false" class="col-xl-6 col-lg-12 prospect-card-field">
                <div class="form-group-container">
                    <p class="form-group-container-title"> Informations complémentaires </p>
                    <div class="d-flex justify-content-center">
                        <mat-spinner *ngIf="loadingFormBuilder" [diameter]="25"></mat-spinner>
                    </div>
                    <formly-form *ngIf="!loadingFormBuilder" [model]="model" [fields]="fields" [options]="options" [form]="form" ></formly-form>
                </div>
            </div>
            <!--Contact-->
            <div class="col-xl-6 col-lg-12 prospect-card-field">
                <form [formGroup]="prospectForm">
                    <div class="form-group-container">
                        <p class="form-group-container-title"> Contact </p>
                        <div class="row">
                            <!--Email-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Email</mat-label>
                                    <input matInput type="email" placeholder="email@email.com" (change)="updateProspect()" formControlName="adresse_mail" name="adresse_mail" [required]="isRequired" autocomplete="nofill">
                                    <mat-error *ngIf="prospectForm.controls['adresse_mail'].invalid && (prospectForm.controls['adresse_mail'].dirty || prospectForm.controls['adresse_mail'].touched)">
                                        <mat-error *ngIf="prospectForm.controls['adresse_mail'].errors?.required">
                                            Email obligatoire !
                                        </mat-error>
                                        <mat-error *ngIf="prospectForm.controls['adresse_mail'].errors?.pattern">
                                            Adresse e-mail invalide! exemple: email@email.com
                                        </mat-error>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--Mobile-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">Mobile</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect()" name="mobile" class="full_width" maxlength="14" formControlName="mobile" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'mobile')">
                                    <fa-icon matSuffix [icon]="fa['faMobile']"></fa-icon>
                                </mat-form-field>

                            </div>
                            <!--Téléphone-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">Téléphone</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect()" name="tel" class="full_width" maxlength="14" formControlName="tel" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'tel')">
                                    <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                                </mat-form-field>
                            </div>
                            <!--2ème Téléphone-->
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label class="ng2tel-field-label">2ème Téléphone</mat-label>
                                    <input (keypress)="numberOnly($event)" ng2TelInput matInput (intlTelInputObject)="telInputObject($event)" (change)="updateProspect()" name="tel2" class="full_width" maxlength="14" formControlName="tel2" [ng2TelInputOptions]="{initialCountry: telCountry}"
                                        (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event,'tel2')">
                                    <fa-icon matSuffix [icon]="fa['faPhone']"></fa-icon>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
            <!--Adresse-->
            <div class="col-xl-6 col-lg-12 prospect-card-field">
                <form [formGroup]="prospectForm">
                    <div class="form-group-container">
                        <p class="form-group-container-title"> Adresse </p>
                        <div class="row">
                            <!--Adress Auto Google API-->
                            <div class="col-12">
                                <app-auto-complete-adresses [isReadOnlyAdresse]="allowed_to_update" iconColor="#9b9b9b" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                                </app-auto-complete-adresses>
                            </div>
                            <!--Country-->
                            <div class="col-6">
                                <mat-select-country appearance="standard" label="Pays" [disabled]="!allowed_to_update" class="full-width" [value]="defaultCountry" [itemsLoadSize]="5" (onCountrySelected)="onCountrySelected($event)">
                                </mat-select-country>
                            </div>
                            <!--Ville-->
                            <div class="col-6">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Ville</mat-label>
                                    <input matInput type="text" formControlName="ville" (change)="updateProspect()">
                                </mat-form-field>

                                <mat-form-field [hidden]="true" class="full-width" appearance="standard">
                                    <mat-label>longitude</mat-label>
                                    <input matInput type="text" formControlName="longitude" (change)="updateProspect()">
                                </mat-form-field>

                                <mat-form-field [hidden]="true" class="full-width" appearance="standard">
                                    <mat-label>latitude</mat-label>
                                    <input matInput type="text" formControlName="latitude" (change)="updateProspect()">
                                </mat-form-field>
                            </div>
                            <!--Rue-->
                            <div class="col-4">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Rue</mat-label>
                                    <input matInput type="text" formControlName="streetName" (change)="updateProspect()">
                                </mat-form-field>
                            </div>
                            <!--N° rue-->
                            <div class="col-4">

                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>N° de Rue</mat-label>
                                    <input type="number" matInput min="0" (keypress)="numberOnly($event)" (change)="updateProspect()" placeholder="0" size="8" formControlName="streetNumber">
                                </mat-form-field>
                                <div *ngIf="prospectForm.controls['streetNumber'].invalid && (prospectForm.controls['streetNumber'].dirty || prospectForm.controls['streetNumber'].touched)">
                                    <div *ngIf="prospectForm.controls['streetNumber'].errors?.required">
                                        N° de Rue obligatoire !
                                    </div>
                                </div>
                            </div>
                            <!--Code Postal-->
                            <div class="col-4">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Code Postal</mat-label>
                                    <input type="number" min="0" (keypress)="numberOnly($event)" (change)="updateProspect()" matInput placeholder="0" formControlName="CP">
                                </mat-form-field>
                            </div>
                            <!--Complément d'Addresse-->
                            <div class="col-12">

                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Complément d'Addresse</mat-label>
                                    <input matInput type="text" formControlName="complement_adr" (change)="updateProspect()">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <!--Informations complémentaires-->
            <div *ngIf="prospect?.id != undefined || prospect?.id != null" class="col-xl-12 col-lg-12 prospect-card-field">
                <div class="form-group-container pt-0">
                    <div class="row d-block">
                        <!-- TAGS prospect-->
                        <shared-tags *ngIf="allowed_to_update != null && allowed_to_update != undefined" [isReadOnly]="allowed_to_update" [prospect_id]="prospect.id"></shared-tags>

                        <!--Commentaire-->
                        <div class="col-12">

                            <form [formGroup]="prospectForm">
                                <mat-form-field class="full-width" appearance="standard">
                                    <mat-label>Commentaire sur le prospect</mat-label>
                                    <textarea cols="90" formControlName="description" matInput (change)="updateProspect()"></textarea>
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!--Informations bancaires-->
            <!-- <div class="col-xl-12 col-lg-12">
                <shared-information-bank [typeEntity]="typeEntity" [bankInformation]="prospect.information_bancaire" (onChangeBankInformation)="getBankInformation($event)"></shared-information-bank>
            </div> -->
        </div>
    </div>
</div>
