import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { OpportunitiesDetailsComponent } from '../../opportunities-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentsOpportunite, Files } from 'src/app/entity/Opportunity/DocumentsOpportunite';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { Subscription } from 'rxjs';
import { element } from 'protractor';

export enum FileType {
  PDF,
  AUDIO,
  FILE,
}

@Component({
  selector: 'app-enregistrement-documents',
  templateUrl: './enregistrement-documents.component.html',
  styleUrls: ['./enregistrement-documents.component.css'],
})
export class EnregistrementDocumentsComponent implements OnInit, OnDestroy, AfterViewInit {
  id_prospect: string = '';
  consulterFile: boolean = false;
  idDoc: string;
  opportunity_id: string = '';
  listDocuments: DocumentsOpportunite[] = [];
  listDocFiltered: DocumentsOpportunite[] = [];
  filterFile: any[] = [];
  show_loading: boolean = false;
  listFileToSend: any[] = [];
  fa = fa;
  file: File = null;
  files: any[] = [];
  ListFiles: File[] = [];
  index = 0;
  uploadFile: {};
  uploadForm: FormGroup;
  formGroup: FormGroup;
  showFile: string;
  audio: any[] = [];
  audioType: FileType = FileType.AUDIO;
  fileType: FileType = FileType.FILE;
  list_directory: DocumentsOpportunite[] = [];
  addDocuments: DocumentsOpportunite = new DocumentsOpportunite();
  listFiles: Files[] = [];
  list_load_Files: Files[] = [];
  list_load_audio: DocumentsOpportunite[] = [];
  id_document: string = '';
  name_document: string;
  ListeOfUnsubscribeWebService: Subscription[] = [];
  allowed_to_update: any;

  constructor(
    private apiOpportuinteServices: ApiOpportunitService,
    private notificationsService: NotificationsService,
    private opportunitiesDetailsComponent: OpportunitiesDetailsComponent,
    private formBuilder: FormBuilder,
    private apiDocumentsService: ApiDocumentsService
  ) {
    apiDocumentsService.loadDocuments().subscribe((load) => {
      if (load === 'refreshOpportunite') {
        this.loadListDocuments();
      }
    });
  }

  ngAfterViewInit(): void {
    this.apiOpportuinteServices.onChangeAccesOpportunite().subscribe((value: boolean) => {
      if (value) this.allowed_to_update = value;
    });
  }

  ngOnDestroy(): void {
    this.ListeOfUnsubscribeWebService.forEach((element) => {
      element.unsubscribe();
    });
  }

  getSharedDocument($event): void {
    this.show_loading = $event;
    this.loadListDocuments();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event, fileType: FileType) {
    this.prepareFilesList($event, fileType);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files, fileType: FileType) {
    this.prepareFilesList(files, fileType);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  deleteAudio(index: number) {
    this.audio.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadAudioSimulator(index: number) {
    setTimeout(() => {
      if (index !== this.audio.length) {
        const progressInterval = setInterval(() => {
          if (this.audio[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadAudioSimulator(index + 1);
          } else {
            this.audio[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
  }

  uploadDocuments() {
    this.show_loading = true;
    this.addDocuments.id_opp = this.opportunity_id;
    this.id_prospect = this.opportunitiesDetailsComponent.id_prospect;
    const formData: FormData = new FormData();
    formData.append('id_pros', this.id_prospect);
    formData.append('id_document', this.id_document);
    formData.append('id_opp', this.opportunity_id);
    formData.append('name_doc', this.name_document);
    this.files.forEach((file) => formData.append('file[]', file));
    const unsubscribeUploadDocumentOpportunite = this.apiOpportuinteServices
      .uploadDocumentOpportunite(formData)
      .subscribe(
        (response) => {
          this.show_loading = false;
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = response.message;
          this.notificationsService.sendMessage(notificationMessage);
          this.loadListDocuments();
          this.files = [];
          this.audio = [];
        },
        (error) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = error.message;
          this.notificationsService.sendMessage(notificationMessage);
        }
      );
    this.ListeOfUnsubscribeWebService.push(unsubscribeUploadDocumentOpportunite);

    // }
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>, fileType: FileType) {
    for (const item of files) {
      item.progress = 0;
      if (fileType === FileType.FILE) {
        this.files.push(item);
        // this.uploadFilesSimulator(0);
      } else if (fileType === FileType.AUDIO) {
        this.audio.push(item);
        this.uploadAudioSimulator(0);
      }
    }
    this.uploadDocuments();
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  ngOnInit(): void {
    this.allowed_to_update = this.opportunitiesDetailsComponent.allowed_to_update;
    this.opportunity_id = this.opportunitiesDetailsComponent.opportunity_id;
    this.id_prospect = this.opportunitiesDetailsComponent.id_prospect;
    this.uploadForm = this.formBuilder.group({
      upload: [''],
    });
    this.loadListDocuments();
  }

  consulterFiles(idDoc: string) {
    if (!this.consulterFile) {
      this.idDoc = idDoc;
      this.consulterFile = true;
    } else this.consulterFile = false;
  }

  loadListDocuments() {
    this.show_loading = true;
    this.files = [];
    this.audio = [];
    const unsubscribeListDocumentOpportuniteGet = this.apiOpportuinteServices
      .listDocumentOpportuniteGet(this.opportunity_id)
      .subscribe((data) => {
        if (data) {
          this.listDocuments = data?.folder;
          this.files = data.files;
          this.listDocFiltered = this.listDocuments.filter((doc: any) => doc.existe);
          this.show_loading = false;
          this.listDocFiltered.forEach((element: any, index) => {
            this.filterFile[index] = element.files;
          });
          for (let index = 0; index < this.filterFile.length; index++) {
            this.filterFile[index].forEach((element) => {
              this.listFileToSend[this.listFileToSend.length] = element.url;
            });
          }
        } else {
          this.show_loading = false;
        }
      });
    this.ListeOfUnsubscribeWebService.push(unsubscribeListDocumentOpportuniteGet);
  }

  addDocument() {
    if (this.addDocuments.name !== '' && this.addDocuments.name !== null) {
      this.show_loading = true;
      this.addDocuments.id_opp = this.opportunity_id;
      const unsubscribeAddDocumentOpportuniteGet = this.apiOpportuinteServices
        .addDocumentOpportuniteGet(this.addDocuments)
        .subscribe(
          (response) => {
            this.show_loading = false;
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.success;
            notificationMessage.title = 'Opération réussite';
            notificationMessage.message = 'Dossier Ajouté';
            this.notificationsService.sendMessage(notificationMessage);
            this.loadListDocuments();
            this.addDocuments.name = '';
            /*           this.formGroup.reset(); */
          },
          (error) => {
            const notificationMessage = new NotificationMessage();
            notificationMessage.type = NotificationType.error;
            notificationMessage.title = 'Opération echoué';
            notificationMessage.message = error.message;
            this.notificationsService.sendMessage(notificationMessage);
          }
        );
      this.ListeOfUnsubscribeWebService.push(unsubscribeAddDocumentOpportuniteGet);
    }
  }
}
